.DesktopCreateProject {
    width: 100%;
    height: 980px;
    position: relative;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #F9F9FA;
}

.DesktopCreateProjectOther {
    width: 100%;
    height: 1232px;
    position: relative;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #F9F9FA;
}

.PageHeader {
    height: 126px;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    top: 56px;
    /* position: absolute; */
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
    padding: 0px 32px;
}

.Breadcrumb {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.BreadcrumbBase {
    border-radius: 6px;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: inline-flex
}

.BreadcrumbItem {
    justify-content: center;
    align-items: center;
    gap: 5px;
    display: flex
}

.Text {
    color: #2E2E38;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word
}


.Text2 {
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex
}

.NavArrowRight {
    width: 16px;
    height: 16px;
    position: relative
}

.Vector {
    width: 5px;
    height: 9px;
    left: 5.50px;
    top: 3.50px;
    position: absolute;
    background: #9897A6
}


.Vector3 {
    width: 30px;
    height: 10.95px;
    background: #FFE600
}

.Vector4 {
    width: 12.14px;
    height: 15.18px;
    background: white
}

.Vector5 {
    width: 15.15px;
    height: 15.18px;
    background: white
}

.Text1 {
    color: #9897A6;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word
}

.Content {
    width: 100%;
    height: 44px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex
}

.Content1 {
    flex: 1 1 0;
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
}

.Content2 {
    flex: 1 1 0;
    height: 24px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
}

.Content3 {
    align-self: stretch;
    height: 42px;
    padding-top: 2px;
    padding-left: 4px;
    padding-right: 4px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.Content4 {
    width: 272px;
    align-self: stretch;
    padding-top: 6px;
    padding-bottom: 6px;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.Title {
    flex: 1 1 0;
    height: 36px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: flex
}

.Text3 {
    color: #2E2E38;
    font-size: 28px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 36px;
    word-wrap: break-word
}

.TextAndSupportingText {
    width: 100%;
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    display: inline-flex;
  }

.Actions {
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
}

.Button {
    border-radius: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

/*
.ButtonBase {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
    overflow: hidden;
    border: 1px #C3C3CB solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex
}

.ButtonBase1 {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #2E2E38;
    border-radius: 4px;
    overflow: hidden;
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex
}

.Text4 {
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}


.Text5 {
    color: white;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}


.Text7 {
    flex: 1 1 0;
    color: #656579;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 24px;
    word-wrap: break-word
}

 .Text8 {
    flex: 1 1 0;
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
} */


.Text9 {
    align-self: stretch;
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}
/* 
.Text10 {
    align-self: stretch;
    color: red;
    font-size: 13px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
    margin-top: -5px;
} */

.WrapText {
    word-wrap: normal;
}

.ArrowRight {
    width: 20px;
    height: 20px;
    position: relative
}

.InputWithLabel {
    align-self: stretch;
    height: 70px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.Label {
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}

.Input {
    align-self: stretch !important;
    /* padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important; 
    padding-bottom: 0x !important; */
    background: white !important;
    /* border-radius: 4px !important;
    border: 1px #C3C3CB solid !important; */
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 8px !important;
    display: inline-flex !important;
    width: 310px !important;
}

.Input button {
    padding-left: 8px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.InputCategory {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.ProjectInput {
    align-self: stretch !important;
    /* padding-left: 14px !important; */
    padding-right: 14px !important;
    /* padding-top: 10px !important; */
    padding-bottom: 10px !important;
    /* background: white !important; */
    /* border-radius: 4px !important;
    border: 1px #C3C3CB solid !important; */
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 8px !important;
    display: inline-flex !important;
    width: 485px !important;
}

.CancelButton {
    background-color: white !important;
    color: black;
}

.Input1 {
    padding-top: 2px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex
}

.Input2 {
    align-self: stretch;
    flex: 1 1 0;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: white;
    border-radius: 4px;
    border: 1px #C3C3CB solid
}

.InputMotif {
    --input-textarea--border-width: 200px;
}

.PlaceholderWrapper {
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    display: flex
}

.NavArrowDown {
    width: 20px;
    height: 20px;
    position: relative
}

.DefaultInputFieldBase {
    align-self: stretch;
    height: 70px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.Frame5383 {
    align-self: stretch;
    justify-content: space-between;
    align-items: flex-start;
    display: inline-flex
}

.Frame5357 {
    height: 20px;
    justify-content: space-between;
    align-items: center;
    display: flex
}

.Checkbox {
    height: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.Checkbox1 {
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    display: flex
}

.CheckboxBase {
    width: 16px;
    height: 16px;
    position: relative;
    background: white;
    border-radius: 1px;
    border: 1px #7F7F91 solid
}

.IconWithTooltip {
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    display: flex
}

.HelpCircle {
    width: 16px;
    height: 16px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.LabelGroup {
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    display: inline-flex
}

.LabelOutside {
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}

.ProgressIndicator {
    width: 100%;
    top: 182px;
    /* position: absolute; */
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
    padding: 0px 32px;
}

.ProgressIndicatorStep {
    flex: 1 1 0;
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex
}

.Line {
    align-self: stretch;
    height: 4px;
    background: #EAD406;
    border: 1px rgba(0, 0, 0, 0) solid
}

.Line1 {
    align-self: stretch;
    height: 4px;
    background: #E6E6E9;
    border: 1px rgba(0, 0, 0, 0) solid
}

.Line2 {
    align-self: stretch;
    height: 4px;
    background: #070707;
    border: 1px rgba(0, 0, 0, 0) solid
}

.SupportingText {
    align-self: stretch
}

.HeaderNav {
    width: 1350px;
    height: 56px;
    padding-left: 10px;
    padding-right: 16px;
    left: 0px;
    top: 0px;
    position: absolute;
    background: white;
    border-bottom: 1px #E6E6E9 solid;
    justify-content: space-between;
    align-items: flex-start;
    display: inline-flex
}

.LogoAndMenu {
    /* width: 160px; */
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
}

.LogoEngGap {
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: flex
}

.LogoProduct {
    /* width: 160px; */
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: flex
}

.Logo {
    border-radius: 4px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex
}

.notificationImage {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0.00));
    background: var(--dev-only-transparent, rgba(0, 0, 0, 0.00));
}

.ContainerLogo {
    width: 40px;
    height: 40px;
    padding-top: 4.02px;
    padding-bottom: 5.30px;
    padding-left: 5px;
    padding-right: 5px;
    background: #2E2E38;
    border-radius: 2.50px;
    justify-content: center;
    align-items: center;
    display: inline-flex
}

.ScaledLogo {
    width: 30px;
    height: 30.68px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.ProductName {
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}

.SearchGroup {
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

/* .IconGroup {} */

.NavDropdown {
    padding: 2px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.Dropdown {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    margin-top: 8px;

}

.Avatar {
    border-radius: 200px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex
}

.AvatarImage {
    width: 25px;
    height: 25px;
    position: relative;
    border-radius: 48px
}

.usermenucontainer {
    width: 275;
    height: 240;
    flex-Direction: column;
    justify-Content: flex-start;
    align-Items: flex-end;
    display: inline-flex;
    z-Index: 1;
    position: absolute;
    right: 0;
    margin-Right: 25px;
}

.userMenupopupcontainer {
    height: 200;
    padding-Top: 8;
    padding-Bottom: 8;
    border-Radius: 4;
    justify-Content: flex-start;
    align-Items: flex-start;
    gap: 10;
    display: inline-flex;
}

.usermenupopupstyle {
    width: 275;
    background: white;
    box-Shadow: 0px 8px 16px rgba(35, 35, 47, 0.08);
    border-Radius: 4;
    border: 1px #E6E6E9 solid;
    flex-Direction: column;
    justify-Content: flex-start;
    align-Items: flex-start;
    display: inline-flex;
}

.usermenuwidthscratch {
    align-Self: stretch;
    border-Radius: 4;
    overflow: hidden;
    justify-Content: flex-start;
    align-Items: flex-start;
    display: inline-flex;
}

.usermenuwidth {
    flex: 1 1 0;
    flex-Direction: column;
    justify-Content: flex-start;
    align-Items: flex-start;
    display: inline-flex
}

.usermenubox {
    align-Self: stretch;
    padding-Left: 14;
    padding-Right: 14;
    padding-Top: 10;
    padding-Bottom: 10;
    background: rgba(0, 0, 0, 0);
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-Content: flex-start;
    align-Items: center;
    gap: 8;
    display: inline-flex;
}

.usermenuchane {
    flex: 1 1 0;
    height: 24;
    justify-Content: flex-start;
    align-Items: center;
    gap: 8;
    display: flex;
}

.usermenushow {
    justify-Content: flex-start;
    align-Items: center;
    gap: 10;
    display: flex;
}


.displaymenu {
    color: #2E2E38;
    font-Size: 16;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-Weight: 300;
    line-Height: 1;
    word-Wrap: break-word;
}

.cancelbuttondiv {
    display: flex;
    justify-content: space-between,
}

.dropdownmenudiv {
    width: 275;
    height: 240;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    display: inline-flex;
    z-index: 1;
    position: absolute;
    right: 0;
    margin-right: 25px;
}

.userMenupopupcontainerdiv {
    width: 275;
    background: white;
    box-shadow: 0px 8px 16px rgba(35, 35, 47, 0.08);
    border-radius: 4;
    border: 1px #E6E6E9 solid;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
}

.usermenuwidthdiv {
    align-self: stretch;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: rgba(0, 0, 0, 0);
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex
}
.displaymenudiv{
    display: flex;
    align-items: center
}
.avatarImagedesc{
    width: 30px;
    height: 30px;
    position: relative;
    border-radius: 48px;
}
.userNameSpace{
    margin-left: 8px;
}
.emailSpace{
    margin-left: 1px;
}
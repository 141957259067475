#CreatePopUppopupmodal {
    height: 90% !important;
    .motif-modal-footer {
        .iconBack {
            margin-right: 8px;
        }
    }
}
.createProjectPopupModalHeight{
    max-height: 90%;
}
.CreatePopUpModalTitle {
    flex: 1 1 0;
    color: #2E2E38;
    font-size: 18px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 28px;
    word-wrap: break-word;
}

.CreatePopUpText {
    color: #656579;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}

.CreatePopUpProjDisclaimer {
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word;
    padding-top: 20px;
}

.CreatePopUpProjDetail {
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 24px;
    word-wrap: break-word;
    padding-top: 20px;
}


.CreatePopUpProgress {
    justify-content: center;
    align-items: center;
    gap: 12px;
    display: flex
}

.CreatePopUpProgressStepIcon {
    width: 24px;
    height: 24px;
    background: #F3F3F5;
    border-radius: 12px;
    overflow: hidden;
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-content: center;
    align-items: center;
    display: flex
}

.CreatePopUpContent {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    display: inline-flex
}

.CreatePopUpContentBlock {
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: flex;
    padding-top: 16px;
}

.createProjectPopupLinkText {
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    text-decoration-line: none;
    color: #0f69ae;
}
.selectEngagement{
    width: 60%;
    margin-top: 8px;
}
#create-select-location .motif-select-wrapper-options {
    transform: none !important;
    inset: 0px auto auto 0px !important;
    font-family: EYInterstate;
}
#createengagementlocation .motif-select-input {
    min-height: 2.5rem !important;
    margin-top: 3px;
  }
#createengagementlocation .motif-select-input-arrow{
    top: 0.5rem !important;
}
.selectEngagementTitle {
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 24px;
    word-wrap: break-word;
    padding-top: 15px;
}
#create-select-location .motif-select-clean-value {
    visibility: hidden;
  }
  .searchtool{
    margin-right: 10px;
  }
/* .utilmargin{
    background: #F9F9FA;
    margin: 0;
    padding: 0 20px;
} */
.parent-top-heading {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.top-heading {
    color: #2E2E38;
    font-size: 26px;
    font-family: 'EYInterstate', 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 32px;
    word-wrap: break-word
}

.scope-section {
    border: 1px solid #D7D7DC;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 24px;
    margin-bottom: 86px;
}

.section-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F3F3F5;
    padding: 2px 20px;
}

.scope-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #D7D7DC;
    padding: 16px 20px;background: #ffffff;
}

.footer-btns-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.field {
    margin-top: 5px;
    margin-bottom: .25rem;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: .1428571429rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.scopes-parent-div {
    max-height: 650px;
    overflow-x: hidden;
    overflow-y: auto;
}

.icon-btn:hover {
    background-color: transparent;
}

.back-section {
    display: flex;
    margin-top: 24px;
}

.btn-add-scope {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
}
.btn-add-scope img{margin-right: 8px;}

.back-btn {
    display: flex;
    align-items: center;
    gap: 5px;font-size: 14px;
    font-family: EYInterstate;
}

.project-scope-field-txt {
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;padding-right: 30px;
}

.inputProjectScope {
    border: none !important;
    outline: none;
    flex: 1;

    &:focus {
        outline: none !important;
    }
}

.motif-input-invalid {
    border-color: #ea011d !important;
    border-width: 1px !important;
    outline-color: #ea011d !important;
}

.motif-input-blue {
    border-color: #0f69ae !important;
    border-width: 1px !important;
    outline-color: #0f69ae !important;
}

.scope-name-input {
    margin-top: 5px;
    margin-bottom: .25rem;
}

.confirm-btn {
    margin-left: 10px;
}

.footer {
    border: none;
}

.errorText {
    color: #c70117;
    display: table-cell;
    line-height: 18px;

    .motif-error-icon {
        display: none;
    }
}

.pageToast {
    width: calc(100vw - 110px) !important;
    bottom: 46px !important;
    left: inherit !important;
}
/* @media screen and (max-width: 1800px){
    .pageToast{
        width: calc(100vw - 104px) !important;
    }
} */

.errorIcon {
    padding-right: .7142857143rem;
}
.manageProjectScopeComponent .no_scopes{margin-top: 24px;display: flex;align-items: center;justify-content: center;border: 1px solid #D7D7DC;padding: 60px 30px;font-size: 24px;font-weight: 500;border-radius: 8px;background: #f2f2f2;}
.manageProjectScopeComponent .scopeOfProject{width: 260px;max-width: 260px;font-weight: 500;font-size: 14px;display: flex;align-self: center;word-break: break-all;}
.manageProjectScopeComponent .rename{flex-grow: 1;display: flex;align-self: center;}
.manageProjectScopeComponent .rename img{padding-left: 8px;cursor: pointer;}
.manageProjectScopeComponent .EYD, .manageProjectScopeComponent .instantInsights{display: flex;align-items: center;justify-content: center; width: 152px;max-width: 152px;font-size: 14px;line-height: 40px;}
.manageProjectScopeComponent .motif-toast-fixed-top, .manageProjectScopeComponent .motif-toast-fixed-bottom {width: calc(100vw - 100px);position: fixed;right: 20px;left: inherit;bottom: 32px;}
.manageProjectScopeComponent .motif-toast-content{box-shadow: 0px 4px 8px 0px rgba(35, 35, 47, 0.06), 0px 16px 32px 0px rgba(35, 35, 47, 0.08);font-size: 14px;line-height: 20px;}
.manageProjectScopeComponent .motif-toast-text-container{font-weight: 400;padding: 16px 0;border-color: #2DB757;}
#addScopeModal .motif-modal{padding: 24px 10px 0;}
#addScopeModal.motif-modal{padding: 24px;}
#addScopeModal .motif-modal-header .motif-modal-headline{padding: 0;padding-bottom: 36px;position: relative;top: 7px;}
#addScopeModal .motif-modal-body{padding: 28px 0px 36px;}
#addScopeModal .footer{padding: 0;height: inherit;}
#addScopeModal .project-scope-field-txt{color: #656579;}
.container {
    display: flex;
    flex-direction: column;
}

.containerOtherThanCoptright {
    position: relative;
    padding-bottom: 80px;
    overflow: hidden;
}

.header {
    display: flex;
    height: 56px;
    padding: 0px 16px 0px 10px;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-bottom: 1px solid var(--Neutrals-Light-theme-100, #E6E6E9);
    background: var(--Neutrals-Light-theme-00-White, #FFF);
    width: 100%;

    .logomenu {
        gap: 8px;
        display: flex;
        align-self: stretch;
        align-items: center;

        .logoDiv {
            display: flex;
            height: 44px;
            align-items: center;
            gap: 12px;

            .logo {
                display: flex;
                width: 40px;
                height: 40px;
                padding: 4.023px 5px 5.301px 5.001px;
                justify-content: center;
                align-items: center;
                border-radius: 2.5px;
                background: #2E2E38;
            }


        }

        .projectTitle {
            color: var(--Neutrals-Light-theme-900, #2E2E38);

            /* Body 1/Regular */
            font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 150% */
        }
    }

    .profileImageContainer {
        display: flex;
        padding: 6px 0px;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .notificationImage {
            display: flex;
            padding: 12px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0.00));
            background: var(--dev-only-transparent, rgba(0, 0, 0, 0.00));
        }


        .NavDropdown {
            padding: 2px;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            display: flex
        }

        .Dropdown {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            display: inline-flex;
            margin-top: 5px;
        }

        .Avatar {
            border-radius: 200px;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            display: inline-flex
        }

        .AvatarImage {
            width: 25px;
            height: 25px;
            position: relative;
            border-radius: 48px
        }
        .userAvatar {
            width: 30px;
            height: 30px;
            position: relative;
            border-radius: 48px;
          }
    }
}

.dashboard {
    width: 100%;
    display: flex;
    padding: 0px 32px;
    align-items: flex-start;
    gap: 24px;
    justify-content: space-between;
    align-self: stretch;
    flex-direction: column;
    background: #F9F9FA
}

.bannerDiv {
    width: 100%;
    height: 240px;
    position: relative;
    background: linear-gradient(90deg, #101828 0%, #475467 100%);
    box-shadow: 0px 8px 24px -4px rgba(16, 24, 40, 0.04);
    border-radius: 8px;
    overflow: hidden;

    .bannerImage {
        width: 671px;
        height: 717.97px;
        // left: 570px;
        // top: -260.96px;
        right: 75px;
        pointer-events: none;
        position: absolute;

        .bannerImageCircleBg {
            width: 466.30px;
            height: 466.30px;
            left: 95.20px;
            top: 191.32px;
            position: absolute;
            background: linear-gradient(234deg, #FFE600 0%, #D9C618 100%);
            border-radius: 9999px;
        }
    }

    .HeadingAndSubheading {
        height: 128px;
        left: 32px;
        top: 56px;
        position: absolute;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;
        display: inline-flex;

        .Subheading {
            align-self: stretch;
            color: #FFE600;
            font-size: 10px;
            font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 12px;
            letter-spacing: 0.40px;
            word-wrap: break-word;
        }

        .HeadingAndText {
            align-self: stretch;
            height: 104px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            display: flex;

            .Heading {
                align-self: stretch;
                color: white;
                font-size: 28px;
                font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
                font-weight: 400;
                line-height: 36px;
                word-wrap: break-word;
                width: 468px;
            }

            .SupportingText {
                align-self: stretch;
                color: #FAFAFC;
                font-size: 16px;
                font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
                font-weight: 300;
                line-height: 24px;
                word-wrap: break-word;
            }
        }
    }
}


.pageHeader {
    width: 100%;
    height: 92px;
    padding-top: 24px;
    padding-bottom: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;

    .headerContent {
        align-self: stretch;
        justify-content: flex-start;
        align-items: end;
        gap: 16px;
        display: inline-flex;

        .headerTitle {
            flex: 1 1 0;
            height: 36px;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            display: flex;

            .headerText {
                flex: 1 1 0;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 4px;
                display: inline-flex;

                .headerTextDiv {
                    align-self: stretch;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                    display: inline-flex;

                    .headerValue {
                        color: #2E2E38;
                        font-size: 28px;
                        font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
                        font-weight: 400;
                        line-height: 36px;
                        word-wrap: break-word;
                    }
                }
            }
        }

        .headerAction {
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            display: flex;

            .actionButtons {
                border-radius: 4px;
                justify-content: flex-start;
                align-items: flex-start;
                display: flex;

                .actionButtonBase {
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    background: #2E2E38;
                    border-radius: 4px;
                    overflow: hidden;
                    border: 1px rgba(0, 0, 0, 0) solid;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    display: flex;

                    .buttonPlus {
                        width: 20px;
                        height: 20px;
                        position: relative;

                        .buttonVector {
                            width: 11.25px;
                            height: 11.25px;
                            left: 4.38px;
                            top: 4.38px;
                            position: absolute;
                            background: white;
                        }

                    }

                }
            }
        }
    }
}

.createProject {
    color: #FFF;
    margin-left: 16px;
    margin-top: 25px;
    font-size: 16px;
    height: 44px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
    gap: 8px,
}

.switchToProjectDashboard {
    color: #2E2E38;
    background-color: transparent;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 10px 16px;
    width: 310px;
    height: 44px;
    border-radius: 4px;
    border: 1px solid #C3C3CB;
}

.txtSwitchProjDash {
    padding-left: 5px;
}

.switchToProjectDashboard:hover {
    background-color: #E6E6E9;
}

.createProjectBtn {
    display: flex;
    align-self: stretch;
    justify-content: center;
}

.exploreButton {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0.00));
    background: var(--Neutrals-Light-theme-900, #2E2E38);
}
.projectTile{
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 560px);
    gap:26px;   
}

.mainContent {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
    margin-top: 24px;
    margin-bottom: 50px;

    .boxItem {
        width: 31%;
        height: 208px;
        padding-top: 24px;
        padding-bottom: 32px;
        padding-left: 24px;
        padding-right: 24px;
        background: white;
        box-shadow: 0px 2px 5px -4px rgba(16, 24, 40, 0.03);
        border-radius: 4px;
        border: 1px #D8D8DC solid;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 32px;
        display: inline-flex;
        margin-right: 10px;
        margin-bottom: 5px;


        .boxContent {
            align-self: stretch;
            height: 152px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 16px;
            display: flex;

            .boxHeaderSubHeader {

                align-self: stretch;
                height: 100px;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 16px;
                display: flex;

                .boxHeader {
                    align-self: stretch;
                    justify-content: space-between;
                    align-items: flex-start;
                    display: inline-flex;

                    .featureIcon {
                        display: flex;
                        width: 32px;
                        height: 32px;
                        padding: 8px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 16px;
                        background: var(--Gray-100, #F2F2F6);
                    }

                    .dropdownMenu>div>div:nth-child(2) {
                        right: -2px;
                        top: 21px !important;
                        width: 275px;
                        left: auto;
                    }

                    .closeProjectDrpDwn {
                        position: relative;
                        left: 7px;
                        font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 24px;
                    }
                }

                .boxSubHeader {
                    align-self: stretch;
                    height: 52px;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    display: flex;

                    .boxHeaderName {
                        align-self: stretch;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 16px;
                        display: inline-flex;

                        .boxHeaderTitle {
                            flex: 1 1 0;
                            color: #1A1A24;
                            font-size: 18px;
                            font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
                            font-weight: 400;
                            line-height: 28px;
                            word-wrap: break-word;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }

                    .boxDescription {
                        align-self: stretch;
                        color: #747480;
                        font-size: 16px;
                        font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
                        font-weight: 300;
                        line-height: 24px;
                        word-wrap: break-word;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }

            .boxFooter {
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;

                .boxFotterTail {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                }
            }

        }


    }
}

.searchDiv {
    width: 100%;
    height: 44px;
    justify-content: space-between;
    align-items: center;

    display: inline-flex;

    .recentLabel {
        color: var(--Neutrals-Light-theme-900, #2E2E38);
        /* H5/Regular */
        font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        /* 133.333% */
    }
    .searchCreate{
        display: flex;
        flex-direction: row;
    }
    .searchTxtBox {
        width: 400px;
        height: 44px;
    }

    .searchIcon {
        width: 20px;
        height: 20px;
        z-index: 999;
        position: relative;
        top: 39px;
        left: 14px;
    }

    .searchTxtBox input {
        padding-left: 40px !important;
    }

    .searchTxtBox input::placeholder {
        color: #656579;
        font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
    }
}

.lineBreak {
    width: 100%;
    height: 1px;
    background: #E6E6E9;
}

.headernav {
    top: 0px;
    left: 0px;
    width: 1440px;
    height: 56px;
    display: flex;
    padding: 0 16px 0 10px;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(230, 230, 233, 1);
    border-style: solid;
    border-width: 0 0 1px;
    justify-content: space-between;
    border-bottom: 1px solid var(--Neutrals-Light-theme-100, #E6E6E9);
    background: var(--Neutrals-Light-theme-00-White, #FFF);
}

.noProject {
    align-self: stretch;
    height: 224px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: flex;
}

.noProjectDiv {
    width: 352px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: inline-flex;
}

.noProjectImage {
    width: 172px;
    height: 128px;
    position: relative;
}

.noProjectMsgCont {
    align-self: stretch;
    height: 76px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex;
}

.noProjectMsgHeader {
    align-self: stretch;
    text-align: center;
    color: #2E2E38;
    font-size: 18px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 28px;
    word-wrap: break-word;
}

.noProjectMsg {
    align-self: stretch;
    text-align: center;
    color: #656579;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 20px;
    word-wrap: break-word;
}

.copyrightContainer {
    z-index: 999;
    width: 100%;
    height: 45px;
    position: fixed;
    bottom: 0;
    /* required */
    margin-top: 24px;

    .copyrightSection {
        height: 45px;
        left: 0px;
        top: 0px;
        // position: sticky;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        width: 100%;
        border-top: 1px solid #e6e6e9;

        .rectangleSection {
            width: 100%;
            height: 45px;
            background: #FAFAFC;

            .copyrightMessage {
                color: #2E2E38;
                font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 20px;
                word-wrap: break-word;
                position: sticky;
                padding-left: 12%;
                padding-top: 1%;
                float: right;
                margin-right: 2%;

                .privacyNotice {
                    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;
                    text-decoration-line: none;
                    color: #0F69AE;
                }

                .privacyNoticeSeparator {
                    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
                    font-size: 14px;
                    color: #2E2E38;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;
                    margin-left: 12px;
                    margin-right: 12px;
                }

                .engagement {
                    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;
                    text-decoration-line: none;
                    color: #0F69AE;
                }
            }
        }

    }

    .rectangleSectionOuter {
        width: 100%;
        height: 1px;
        left: 0px;
        top: 0px;
        position: absolute;
        opacity: 0;
        background: #E7E7EA;
    }

    .usermenucontainer {
        width: 275;
        height: 240;
        flex-Direction: column;
        justify-Content: flex-start;
        align-Items: flex-end;
        display: inline-flex;
        z-Index: 1;
        position: absolute;
        right: 0;
        margin-Right: 25px;
    }

    .userMenupopupcontainer {
        height: 200;
        padding-Top: 8;
        padding-Bottom: 8;
        border-Radius: 4;
        justify-Content: flex-start;
        align-Items: flex-start;
        gap: 10;
        display: inline-flex;
    }

    .usermenupopupstyle {
        width: 275;
        background: white;
        box-Shadow: 0px 8px 16px rgba(35, 35, 47, 0.08);
        border-Radius: 4;
        border: 1px #E6E6E9 solid;
        flex-Direction: column;
        justify-Content: flex-start;
        align-Items: flex-start;
        display: inline-flex;
    }

    .usermenuwidthscratch {
        align-Self: stretch;
        border-Radius: 4;
        overflow: hidden;
        justify-Content: flex-start;
        align-Items: flex-start;
        display: inline-flex;
    }

    .usermenuwidth {
        flex: 1 1 0;
        flex-Direction: column;
        justify-Content: flex-start;
        align-Items: flex-start;
        display: inline-flex
    }

    .usermenubox {
        align-Self: stretch;
        padding-Left: 14;
        padding-Right: 14;
        padding-Top: 10;
        padding-Bottom: 10;
        background: rgba(0, 0, 0, 0);
        border: 1px rgba(0, 0, 0, 0) solid;
        justify-Content: flex-start;
        align-Items: center;
        gap: 8;
        display: inline-flex;
    }

    .usermenuchane {
        flex: 1 1 0;
        height: 24;
        justify-Content: flex-start;
        align-Items: center;
        gap: 8;
        display: flex;
    }

    .usermenushow {
        justify-Content: flex-start;
        align-Items: center;
        gap: 10;
        display: flex;
    }


    .displaymenu {
        color: #2E2E38;
        font-Size: 16;
        font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
        font-Weight: 300;
        line-Height: 1;
        word-Wrap: break-word;
    }

}

.platformAdminBanner {
    cursor: "auto"
}

@media screen and (max-width: 1250px) {
    .mainContent {
        .boxItem {
            width: 48%;
        }
    }
}

@media screen and (max-width: 660px) {
    .mainContent {
        .boxItem {
            width: 100%;
        }
    }
}

.admindashboard {
    width: 100%;
    display: flex;
    padding: 0px 16px;
    align-items: flex-start;
    gap: 24px;
    justify-content: space-between;
    align-self: stretch;
    flex-direction: column;
}


.adminpageHeader {
    width: 100%;
    height: 70px;
    padding-top: 24px;
    padding-bottom: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;

    .headerContent {
        align-self: stretch;
        justify-content: flex-start;
        align-items: end;
        gap: 16px;
        display: inline-flex;

        .headerTitle {
            flex: 1 1 0;
            height: 36px;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            display: flex;

            .headerText {
                flex: 1 1 0;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 4px;
                display: inline-flex;

                .headerTextDiv {
                    align-self: stretch;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                    display: inline-flex;

                    .headerValue {
                        color: #2E2E38;
                        font-size: 28px;
                        font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
                        font-weight: 400;
                        line-height: 36px;
                        word-wrap: break-word;
                    }
                }
            }
        }

        .headerAction {
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            display: flex;

            .actionButtons {
                border-radius: 4px;
                justify-content: flex-start;
                align-items: flex-start;
                display: flex;

                .actionButtonBase {
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    background: #2E2E38;
                    border-radius: 4px;
                    overflow: hidden;
                    border: 1px rgba(0, 0, 0, 0) solid;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    display: flex;

                    .buttonPlus {
                        width: 20px;
                        height: 20px;
                        position: relative;

                        .buttonVector {
                            width: 11.25px;
                            height: 11.25px;
                            left: 4.38px;
                            top: 4.38px;
                            position: absolute;
                            background: white;
                        }

                    }

                }
            }
        }
    }
}

.loaderDiv {
    height: calc(100vh - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    .refreshListText {
      font-family: EYInterstate;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
    }
  
    .refreshIcon {
      animation: rotation 2s linear infinite;
    }
  }

.document-table-container {
  height: inherit;
  .motif-table {
    border-style: none;
  }

  .ag-header-cell{
    padding: 0 24px;
  }
  
  .ag-cell {
    padding: 0 24px;
  }
}
.passworddiv {
  display: flex;
  align-items: center;
}
.password-submit-button {
  height: 45px;
}
.document-file-name {
  width: 100%;
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Body 2/Regular */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding-bottom: unset;
}
.document-file-size-label {
  color: var(--Neutrals-Light-theme-700, #656579);

  /* Body 2/Light */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
}
.password-file-container {
  display: flex;
  flex-direction: column;
}
.password-file-message {
  color: var(--Neutrals-Light-theme-700, #656579);

  /* Body 2/Light */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
}

.doc-password-input-container {
  display: flex;
  width: 100%;

  .motif-form-field {
    margin-bottom: unset;
    .motif-input::-ms-reveal {
      display: none;
    }
  }
  .password-input-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    left: -20px;
    margin-right: -20px;
  }
}

.doc-password-input {
  padding-left: 35px !important;
  padding-right: 60px !important;
}


.doc-password-input-key-icon {
  z-index: 999;
  position: relative;
  top: 12px;
  left: 10px;
  width: 20px;
  height: 20px;
}

.doc-password-input-eye-icon {
  z-index: 999;
  position: relative;
  top: 13px;
  left: -35px;
  width: 20px;
  height: 20px;
}

.doc-password-input-eye-icon-after {
  left: -55px !important;
}

.incorrect-pswd-message {
  color: var(--input-support-text-error-color, #C70117);
/* Caption/Light */
font-family: EYInterstate;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: 18px; /* 150% */
}
.document-toast-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  .document-toast-message-header {
    color: var(--Neutrals-Light-theme-900, #2e2e38);
    font-weight: 400;
  }
  .document-toast-message-content {
    color: var(--Neutrals-Light-theme-700, #656579);
    font-weight: 300;
  }
}
.all-documents-removed {
  margin-bottom: 10px;
  box-shadow: 0px 4px 8px 0px rgba(35, 35, 47, 0.06),
    0px 16px 32px 0px rgba(35, 35, 47, 0.08);
}
.all-documents-removed-uploader{
  position: absolute;
  margin-bottom: 10px;
  box-shadow: 0px 4px 8px 0px rgba(35, 35, 47, 0.06),
    0px 16px 32px 0px rgba(35, 35, 47, 0.08);
}
.custom-no-rows-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  position: absolute;
  width: 100%;
  gap: 16px;
}
.documents-removed-message {
  color: var(--Neutrals-Light-theme-700, #656579);
  text-align: center;
  /* Subtitle/Regular */
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}
.document-removed-rotate-icon {
  animation: spin 1s infinite linear;
  width: 56px;
  height: 56px;
}
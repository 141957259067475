.Frame1 {
  height: 648px;
  width: 94%;
  left: 32px;
  top: 260px;
  position: absolute;
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
}

.Frame123 {
  height: auto;
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
  width: 94%;
  position: absolute;
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.Title12 {
  height: 36px;
  align-items: center;
  display: inline-flex;
}

.title12Width {
  width: 100%;
}

.title1Width {
  width: 100%;
}

.Text6 {
  color: #2e2e38;
  font-size: 24px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 32px;
  word-wrap: break-word;
}

/* UPload document styles Started */
.uploadinput {
  width: 1400px !important;
}

.uploaderdiv {
  width: 100% !important;
}

.uploadHeader {
  width: 90% !important;
}

.ContentDivider1 {
  align-self: stretch !important;
  background: rgba(0, 0, 0, 0);
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 8px;
  display: inline-flex !important;
  width: 100% !important;
  padding-top: 8px;
}

.Divider1 {
  flex: 1 1 0;
  height: 1px;
  background: #d7d7dc;
}

.Text1 {
  text-align: center;
  color: #9897a6;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  line-height: 20px;
  word-wrap: break-word;
}

.DefaultInputFieldBase1 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
  padding-top: 8px;
}

.Label1 {
  color: #656579;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.Frame53841 {
  align-self: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  width: 100% !important;
}

.proceedbtn1 {
  flex-wrap: wrap;
  gap: 6px;
  .motif-button {
    height: 100%;
  }
}

.Input12 {
  flex: 1 1 0;
  align-self: stretch;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: white;
  border-radius: 4px;
  border: 1px #c3c3cb solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.Content1 {
  flex: 1 1 0;
  height: 20px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.Link1 {
  width: 20px;
  height: 20px;
  position: relative;
}

.Vector11 {
  width: 17.92px;
  height: 9.58px;
  left: 1.04px;
  top: 5.21px;
  position: absolute;
  background: #656579;
}

.ChkAll1 {
  display: inline-flex;
  padding-right: 10px;
}

.dropzone-container {
  height: 240px;
}
.motifModaluploadDoc {
  max-width: 1050px !important;
}

.Frame53401 {
  width: 100%;
  height: 778px;
  left: 32px;
  top: 332px;
}

.Frame54261 {
  left: 1px;
  top: 0px;
  width: 100%;
  display: inline-flex;
  position: sticky;
}

.Table1 {
  width: 100%;
  align-self: stretch;
  background: white;
  border: 1px #d7d7dc solid !important;
  gap: 16px;
  display: grid !important;
}

.CardHeader1 {
  align-self: stretch;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px #d7d7dc solid;
  justify-content: space-between;
  align-items: flex-start !important;
  display: flex !important;
  width: 100%;
}

.CardTitle1 {
  flex: 1 1 0;
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
  padding-left: 10px;
}

.TextAndSupportingText1 {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
}

.text12 {
  align-self: stretch;
  background: rgba(0, 0, 0, 0);
  justify-content: "flex-start";
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.Text13 {
  color: #2e2e38;
  font-size: 16px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.SupportingText1 {
  align-self: stretch;
  color: #656579;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  line-height: 20px;
  word-wrap: break-word;
  padding-top: 10px;
}

.SupportingText1Danger {
  align-self: stretch;
  color: var(--Red-700, #a40011) !important;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  line-height: 20px;
  word-wrap: break-word;
}

.CardActions1 {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.Button1 {
  border-radius: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  padding-bottom: 10px;
}

.ButtonBase13 {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  overflow: hidden;
  border: 1px #fdbaab solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.Text14 {
  color: #ff4136;
  font-size: 16px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.Button2 {
  display: inline-flex;
}

.Frame534012 {
  width: 100%;
  height: 300px;
  left: 0px;
  top: 48px;
  position: absolute;
  margin-top: 50px;
}

.Frame681 {
  top: 130px;
  width: 100%;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Frame691 {
  width: 360px;
  justify-content: center;
  align-items: "center";
  display: inline-flex;
}

.EmptyState1 {
  width: 512px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  bottom: 80px;
}

.EmptyState2 {
  width: 352px;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  display: inline-flex;
}

.Content2 {
  height: 176px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: "20px";
  display: flex;
}

.Text15 {
  align-self: stretch;
  text-align: center;
  color: #2e2e38;
  font-size: 18px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}

.Rectangle5361 {
  width: 100%;
  height: 516px;
  left: 0px;
  top: 0px;
  position: absolute;
  border-radius: 4px;
  border: 1px #d7d7dc solid;
}

.Frame534013 {
  width: 1000px;
  height: 400px;
}

.table12 {
  width: 1000px;
  height: 400px;
}

.error-message {
  background-color: #fce4e4;
  border: 1px solid #fcc2c3;
  float: left;
  padding: 10px 10px;
  margin-top: 10px;
  width: 900px;
}

.error-text {
  color: #cc0033;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  text-shadow: 1px 1px rgba(250, 250, 250, 0.3);
}

.btnRed {
  background-color: #ea011d !important;
}

.btnBlock {
  background-color: #2e2e38 !important;
}

.sharepoint-modal {
  max-width: 1050px !important;
}

.motif-table-title-bar-alt {
  min-height: 100px !important;
}

.sharepoint-modal-footer-container {
  width: 100%;
  height: 48;
  background: rgba(0, 0, 0, 0);
  border: "1px rgba(0, 0, 0, 0) solid";
  justify-content: "flex-end";
  align-items: "center";
  gap: 12;
  display: "inline-flex";
}

.footer-items {
  justify-content: "flex-end";
  align-items: "center";
  gap: 12;
  display: "flex";
}

.footer-button-container {
  text-align: "right";
  color: "#656579";
  font-size: 14;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: "300";
  word-wrap: "break-word";
}

.main-motif-table-filter {
  padding-right: 24px;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}


.row-adjustment {
  width: 100%;
  margin-bottom: 16px;
}

.icon-page {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 8px;
}

.document-icon{
  min-width: 20px;
  min-height: 20px;
}

.docname {
  color: var(--Neutrals-Light-theme-900, #2E2E38);
  /* Body 2/Regular */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.docname-container {
  display: flex;
  height: 72px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  margin-right: 50px;
}

.no-gap {
  gap: 0px;
}

/* 
.doc-header-container {
  display: flex;
  width: 1376px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border: 1px solid var(--Neutrals-Light-theme-200, #d7d7dc);
  background: var(--Neutrals-Light-theme-00-White, #fff);
} */

.doc-filter-container {
  display: flex;
  padding: 0px 30px;
  justify-content: space-between;
  align-self: stretch;
  flex-direction: column;
}

.doc-filters {
  display: flex;
  gap: 16px;
}

.doc-filter-text {
  width: 319px;
  flex: 1 0 0;
  color: var(--Neutrals-Light-theme-700, #656579);

  /* Caption/Light */
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}

.doc-details-container {
  height: 36px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  display: flex;
  width: 245px;
  flex-shrink:0;
}

.doc-details-states {
  text-align: center;
  color: black;
  font-size: 12px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
}
.doc-limit {
  display: flex;
  align-items: center;
  gap: 4px;
}

.doc-details {
  color:#9897A6
}

.reload-doc-image-button {
  border-radius: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.motif-button-content {
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  justify-content: center;
  word-wrap: break-word;
}

.motif-upload-doc-button {
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  background: var(--Neutrals-Light-theme-900, #2e2e38);
}

.motif-upload-doc-button-disable {
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  background: var(--Neutrals-Light-theme-900, #9897A6);
  word-wrap: break-word;
}

.motif-theme-light {
  /* overflow: auto !important; */
}

.nodoctext {
  text-align: center;
  color: #656579;
  font-size: 12px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300px;
  word-wrap: break-word;
  padding-top: 16px;
  width: 100%;
}

.uploaderdiv .motif-file-uploader-label-text {
  width: 230px;
}

.sharpointInput {
  width: 90%;
  padding-left: 2px;
}

.sharpointInput .motif-input-component .motif-input:not([type="password"]) {
  padding-left: 38px;
}

.sharepointLinkIcon1 {
  position: absolute;
  bottom: 8px;
  left: 12px;
}

.sharepointLinkIcon2 {
  position: absolute;
  bottom: 35px;
  left: 5px;
}

.searchIcon {
  width: 20px;
  height: 20px;
  z-index: 999;
  position: relative;
  top: 13px;
  left: 30px;
}

.sharePointSearchIcon {
  width: 20px;
  height: 20px;
  z-index: 999;
  position: absolute;
  top: 13px;
  left: 8px;
}

.searchTxtBox input {
  padding-left: 35px !important;
}

.upload-sharepoint-form-cont {
  width: 100%;
  display: flex;
  gap: 10px;
}

.upload-sharepoint-form {
  width: 100%;
  display: flex;
  gap: 9px;
}

.local-upload-footer-div {
  display: inline-flex;
  float: right;
  gap: 10px;
}

.local-selected-file-div {
  width: 1000px;
  height: 300px;
}

.file-upload-error-message {
  color: red;
}

.upload-files-grid-container {
  width: 100%;
  height: 190px;
  .ag-header-cell, .ag-cell {
    word-break: break-word !important;
}
}
.upload-files-grid-container-delete-error{
  width: 100%;
  height: 270px;
}

.add-document-file-status-div {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.add-document-file-status {
  display: flex;
  gap: 12px;
  word-break: normal;
}
.rotate-icon {
  animation: spin 0.5s infinite linear;
  height: 23px;
}
.add-document-grid-action-button-div {
  margin-top: 8px;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.add-document-sharepoint-footer-container {
  width: 100%;
  height: 48;
  background: rgba(0, 0, 0, 0);
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}

.add-document-sharepoint-footer-div {
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  display: flex;
}

.add-document-sharepoint-footer-message {
  text-align: right;
  color: #656579;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  word-wrap: break-word;
}

.greyed-out-row {
  background-color: grey;
  color: white;
  opacity: 0.5;
}

#uploadtable .ag-cell-value,
.ag-group-value {
  overflow: unset !important;
}

.file-upload-warning-message {
  color: #975100;
}

/* UPload document styles Ended */
.document-download-delete {
  position: absolute;
  width: 100%;
}

#uploadtable .ag-row:nth-last-child(-n + 2) {
  .document-download-delete {
    bottom: 0px;
  }
}

#uploadtable .ag-row:nth-child(-n + 2) {
  .document-download-delete {
    bottom: unset;
  }
}

.grid-document-name {
  word-break: normal;
}

#toastUploadInitiated,
#toastLocalUploadInPorgress,
#toastUploadComplete {
  z-index: 100;
  width: 33%;
  margin-left: 40%;
  top: 5px;
}

#toastUploadInitiated .motif-toast-content {
  border: 1px solid var(--Red-300, #ff735e);
  background: var(--Red-25, #fcf5f3);
  color: var(--Red-700, #a40011);
  border-radius: 8px;
  height: 44px;
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

#toastLocalUploadInPorgress .motif-toast-content {
  border: 1px solid var(--Blue-300, #55a6fc);
  background: var(--Blue-25, #f4f7fc);
  color: var(--Blue-600, #0f69ae);
  border-radius: 8px;
  height: 44px;
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

#toastUploadComplete .motif-toast-content {
  border: 1px solid var(--Green-300, #2db757);
  background: var(--Green-25, #e7fce8);
  border-radius: 8px;
  height: 44px;
  color: var(--Green-600, #197335);
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

#deletedTab{
  display: none;
}
#errorTab{
  display: none;
}
.download-icon-style{
  color:#9897A6;
  width: 20px;
  height: 20px;
}
.dropdown-item-style{
  color: var(--Neutrals-Light-theme-900, #2E2E38);
/* Body 1/Light */
font-family: EYInterstate;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 24px; /* 150% */
}
.document-toast-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
 
  .document-toast-message-header {
    color: var(--Neutrals-Light-theme-900, #2e2e38);
    font-weight: 400;
  }
 
  .document-toast-message-content {
    color: var(--Neutrals-Light-theme-700, #656579);
    font-weight: 300;
  }
}
.actionborder {
  border-radius: var(--btn-border-radius, 4px);
  border: var(--border-width-thin, 1px) solid
    var(--border-colors-default-secondary, #c3c3cb);
  padding: 5px;
}
.motif-dropdown-menu[x-placement^=bottom-custom]{
  left: -177px;
}
.border-dropdown{
  border-radius: 4px;
}


.motif-button-content{
  margin-bottom: 142px;
}

.tooltip-content{
  word-wrap: break-word;
  max-width: 150px;
  white-space:pre-wrap;
}
.projectDashboardDiv .ProjectDashboardContent1{width: 400px;align-items: center;}
.processingToasts .motif-toast{position: fixed;width: 410px !important;margin-left: 0 !important;right: 320px;left: inherit;}

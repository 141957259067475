.genericModal {
    padding: 24px;
    .motif-modal-headline {
        padding: 0px;
    }
    .motif-modal-body  {
        padding: 24px 0px 0px 0px;
    }
    .motif-modal--header-icon-button {
        width: 3.43rem;
        height: 3.43rem;
        align-self: center;
    }
}
.modalNoHeaderBorder {
  .motif-modal-header {
    border: none;
  }
}
.modalHeaderVerticalPadding {
    .motif-modal-headline {
        padding: 10px 0px;
    }
}
.modalMotifFooter {
    .motif-footer  {
        padding: 24px 0px 0px 0px;
    }
}
.modalMotifFooterModal {
    .motif-modal-footer  {
        padding: 24px 0px 0px 0px;
    }
}
.startIconClose {
    .motif-modal--header-icon-button {
        align-self: start;
    }
}
.modalFooterBorder {
    .motif-modal-footer  {
        border-top: 1px solid #E6E6E9;
    }
}
.h62 {
    height: 62%;
}
.h100 {
    height: 100%;
}
.notification-managment-container {
    width: 100%;
    height: calc(100vh - 150px);
}

.motif-Create-notification-button {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.text-button {
    color: var(--btn-primary-color, #FFF);

    /* Button/md */
    font-family: EYInterstate;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.bell {
    width: 20px;
    height: 20px;
}

.no-notification-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 245px);
}

.notification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    display: flex;
}

.icon-no-notification {
    width: 172px;
    height: 101.136px;
    flex-shrink: 0;
}

.text-notification-found {
    color: var(--Neutrals-Light-theme-900, #2E2E38);
    text-align: center;
    font-family: EYInterstate;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.nevigation-notification-tab .motif-tab-button.motif-active {
    font-weight:500;
}

.notification-remove-toast {
    border-radius: 8px;
    gap: 12px;
    width:92%;
    position:"relative";
    bottom:60px;
    left:70px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.notification-toast-content {
    font-family: EYInterstate;
    font-weight: 600 !important;
    width: Fill (1, 312px)px;
    height: Hug (52px)px;
    gap:12px;
}

.nevigation-notification-tab {
    margin-bottom:32px;
}
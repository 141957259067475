.ProjectDashboardEditDetails {
  /* width: 1300px;
  height: 960px; */
  width: auto;
  height: auto;
  /* position: relative; */
  background: white;
  display: flex;
  padding-top: 56px;
}

.ProjectDashboardVerticalNavigation {
  width: 64px;
  height: 904px;
  left: 0px;
  top: 56px;
  position: absolute;
  background: white;
  border-right: 1px #e6e6e9 solid;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.ProjectDashboardContent {
  width: 64px;
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}

.ProjectDashboardContent1 {
  width: 575px;
  align-self: stretch;
  padding-top: 6px;
  padding-bottom: 6px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.ProjectDashboardNav {
  align-self: stretch;
  height: 269px;
  padding-top: 24px;
  padding-bottom: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.ProjectDashboardNavigation {
  align-self: stretch;
  height: 221px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  display: flex;
}

.ProjectDashboardButton {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.ProjectDashboardButton1 {
  border-radius: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.ProjectDashboardLogoButton {
  width: 44px;
  height: 44px;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.ProjectDashboardLogoButtonBase {
  padding: 12px;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  overflow: hidden;
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.ProjectDashboardLogoMenu {
  width: 20px;
  height: 20px;
  position: relative;
}

.ProjectDashboardLogoVector {
  width: 16.25px;
  height: 12.92px;
  left: 1.88px;
  top: 3.54px;
  position: absolute;
  background: #656579;
}

.ProjectDashboardButtonBase {
  flex: 1 1 0;
  align-self: stretch;
  padding: 8px;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  overflow: hidden;
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: flex;
}

.ProjectDashboardButtonBase1 {
  padding: 12px;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  overflow: hidden;
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.ProjectDashboardChatLines {
  width: 20px;
  height: 20px;
  position: relative;
}

.ProjectDashboardVector {
  width: 17.92px;
  height: 17.92px;
  left: 1.04px;
  top: 1.04px;
  position: absolute;
  background: #656579;
}

.ProjectDashboardVector1 {
  width: 14.58px;
  height: 17.92px;
  left: 2.71px;
  top: 1.04px;
  position: absolute;
  background: #656579;
}

.ProjectDashboardVector2 {
  width: 19.58px;
  height: 15.42px;
  left: 0.21px;
  top: 1.88px;
  position: absolute;
  background: #656579;
}

.ProjectDashboardVector3 {
  width: 17.92px;
  height: 14.58px;
  left: 1.04px;
  top: 3.54px;
  position: absolute;
  background: #656579;
}

.ProjectDashboardVector4 {
  width: 14.58px;
  height: 16.25px;
  left: 2.71px;
  top: 1.88px;
  position: absolute;
  background: #656579;
}

.ProjectDashboardVector5 {
  width: 16.25px;
  height: 17.91px;
  left: 1.87px;
  top: 1.04px;
  position: absolute;
  background: #656579;
}

.ProjectDashboardVector6 {
  width: 16.25px;
  height: 12.92px;
  left: 1.88px;
  top: 3.54px;
  position: absolute;
  background: #656579;
}

.ProjectDashboardVector7 {
  width: 30px;
  height: 10.95px;
  background: #ffe600;
}

.ProjectDashboardVector8 {
  width: 12.14px;
  height: 15.18px;
  background: white;
}

.ProjectDashboardVector9 {
  width: 15.15px;
  height: 15.18px;
  background: white;
}

.ProjectDashboardPage {
  width: 20px;
  height: 20px;
  position: relative;
}

.ProjectDashboardGroup {
  width: 20px;
  height: 20px;
  position: relative;
}

.ProjectDashboardOpenBook {
  width: 20px;
  height: 20px;
  position: relative;
}

.ProjectDashboardContentDivider {
  width: 40px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.ProjectDashboardDivider {
  flex: 1 1 0;
  height: 1px;
  background: #d7d7dc;
}

.ProjectDashboardBookmarkBook {
  width: 20px;
  height: 20px;
  position: relative;
}

.ProjectDashboardHeaderNav {
  width: 100%;
  height: 56px;
  padding-left: 10px;
  padding-right: 16px;
  left: 0px;
  top: 0px;
  position: absolute;
  background: white;
  border-bottom: 1px #e6e6e9 solid;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
}

.ProjectDashboardLogoAndMenu {
  /* width: 212px; */
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.ProjectDashboardMenu {
  width: 20px;
  height: 20px;
  position: relative;
}

.ProjectDashboardLogoEngGap {
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: flex;
}

.ProjectDashboardLogoProduct {
  /* width: 160px; */
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.ProjectDashboardLogo {
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}

.ProjectDashboardContainerLogo {
  width: 40px;
  height: 40px;
  padding-top: 4.02px;
  padding-bottom: 5.3px;
  padding-left: 5px;
  padding-right: 5px;
  background: #2e2e38;
  border-radius: 2.5px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.ProjectDashboardScaledLogo {
  width: 30px;
  height: 30.68px;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.ProjectDashboardProductName {
  color: #2e2e38;
  font-size: 16px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.ProjectDashboardSearchGroup {
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.ProjectDashboardIconGroup {
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

.ProjectDashboardButtonsGroup {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.ProjectDashboardButtonGroupBase {
  padding: 12px;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  overflow: hidden;
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ProjectDashboardBell {
  width: 20px;
  height: 20px;
  position: relative;
}

.ProjectDashboardNavDropdown {
  padding: 2px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.ProjectDashboardDropdown {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  margin-top: 8px;
}

.ProjectDashboardAvatar {
  border-radius: 200px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
}

.ProjectDashboardAvatarImage {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 48px;
}

.imghovernew {
  cursor: pointer;
  background-color: rgba(71, 161, 215, 0.5);
}

.utilmargin {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.Backgroung-color{
  background-color: #f5f5f9;
}

.utilmargin:has(.prompt-library){
  min-height: 91vh;
}


.projectdashboardnavdiv {
  background-color: white;
  display: flex;
  margin: 0;
  width: 100%;
}

.instantheightnavdiv.motif-vertical-navigation.motif-vertical-navigation-collapsed {
    height: 185%;
}

.projectInsigtnavdiv {
  background-color: white;
  display: flex;
  margin: 0;
  width: 100%;
}
.ProjectDashboardPageContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-right: 16px;
  gap: 16px;
}

.active-menu {
  background: #d7d7dc;
  border: 1px rgba(0, 0, 0, 0) solid;
  border-radius: 4px;
}

.usermenucontainer {
  width: 275;
  height: 240;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  display: inline-flex;
  z-index: 1;
  position: absolute;
  right: 0;
  margin-right: 25px;
}

.userMenupopupcontainer {
  height: 200;
  padding-top: 8;
  padding-bottom: 8;
  border-radius: 4;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10;
  display: inline-flex;
}

.usermenupopupstyle {
  width: 275;
  background: white;
  box-shadow: 0px 8px 16px rgba(35, 35, 47, 0.08);
  border-radius: 4;
  border: 1px #e6e6e9 solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.usermenuwidthscratch {
  align-self: stretch;
  border-radius: 4;
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.usermenuwidth {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.usermenubox {
  align-self: stretch;
  padding-left: 14;
  padding-right: 14;
  padding-top: 10;
  padding-bottom: 10;
  background: rgba(0, 0, 0, 0);
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8;
  display: inline-flex;
}

.usermenuchane {
  flex: 1 1 0;
  height: 24;
  justify-content: flex-start;
  align-items: center;
  gap: 8;
  display: flex;
}

.usermenushow {
  justify-content: flex-start;
  align-items: center;
  gap: 10;
  display: flex;
}

.displaymenu {
  color: #2e2e38;
  font-size: 16;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  line-height: 1;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashboard-breadcrumb {
  font-weight: bold !important;
}
.dashboard-breadcrumb > a {
  font-size: unset;
  text-decoration: none;
}

.img-hover {
  cursor: pointer;
}

.ProjectDashboardDropdownDiv {
  width: 275px;
  height: 240px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  display: inline-flex;
  z-index: 1;
  position: absolute;
  right: 0;
  margin-right: 25px;
}

.userMenupopupcontainerDiv {
  width: 275px;
  background: white;
  box-shadow: 0px 8px 16px rgba(35, 35, 47, 0.08);
  border-radius: 4;
  border: 1px #e6e6e9 solid;
  flex-direction: "column";
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.usermenuwidthDiv {
  align-self: stretch;
  padding: 14px;
  background: rgba(0, 0, 0, 0);
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}
.projectDashdisplaymenuDiv {
  display: flex;
  align-items: center;
}

.AvatarDiv {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 48px;
}

.CurrentUserNameDiv {
  margin-left: 8px;
}
.EmailDiv {
  margin-left: 1px;
  margin-top: 5px;
}
.delete-toast-msg{
  margin-bottom: 32px;
  width: 89%;
  left: 7%;
}

#ProjectDashboardProfileDropdown {
  box-shadow: 0px 8px 16px 0px #23232F14;
  border-radius: 4px;
  border: 1px solid  #E6E6E9;
  min-width: 275px;
  min-height: 114px;
  margin-left: -16px;
  margin-top: 5px;
}

.projectDashboardprofileImageContainer {
  width: 60px;
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 20px;
}
.dropdownContainer {
  position: absolute;
  z-index: 1000;
  display: block;
}

.Avatar {
  &:focus, &:hover {
    filter: brightness(95%);
  }
  border-radius: 200px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
}

.Avatar[aria-expanded="true"] {
  cursor: default;
  filter: brightness(90%);
}

.userAvatar {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 48px;
}

.projectDashlogoutcontainer {
  &:hover {
    background-color: #F3F3F5;
  }
  margin: 4px 0;
}

.projectDashuserguidecontainer {
  &:hover {
    background-color: #F3F3F5;
 }
 margin: 4px 0;
}
 
.projectDashuserAvatarUserMenuItem {
  &:focus,
   &:hover {
     background-color: #F3F3F5;
   }
}

  .userguide{
    right: 54%;
    top: 16%;
    text-decoration: none;
    margin-left: 8px;
  }
  
  .userguideimg{
    top: 16%;
    left :19%;
    vertical-align: middle;
  }
  
  .logoutimg{
    top: 16%;
    left :19%;
    vertical-align: middle;
  }

  .logout{
    right: 54%;
    top: 16%;
    text-decoration: none;
    margin-left: 8px;
  }

  .projectDashuserAvatarUserMenuItem {
    text-align: left !important;
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #2E2E38 !important;
    height: 56px !important;
    transition: background-color 0.3s;
  }
  
  .userAvatarEmailMenuItem {
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    color: #9897A6;
  }
  
#badgepopupmodal {
    /* min-height: 88vh; */
    width: 55vw;

    .prompt-divspace {
        margin-top: 16px;
    }

    .add-prompt-modal-header {
        padding-top: 0px;
        padding-bottom: 25px;
    }

    .motif-modal-body {
        padding: 1px 2px 2px 2px;
        overflow: auto;
    }

    .motif-modal-footer {
        padding-top: 0px;
    }

    .ql-editor {
        height: 27vh;
        font-size: 1rem;
    }

    @media screen and (min-height: 1000px) {
        .ql-editor {
            height: 50vh;
            font-size: 1rem;
        }
        /* .motif-rich-text-editor .ql-editor {
            max-height: 100% !important;
        } */
    }
    
    .info {
        padding: 8px;
        font-size: 12px;
    }

    .info-icon {
        width: 20px;
        height: 20px;
    }
}

#badgepopupmodal.motif-modal-content {
    max-height: 96%;
}

.max-char-reached-messaage {
    margin-top: 5px;
    color: var(--Neutrals-Light-theme-700, #656579);
    font-family: var(--font-family-primary, EYInterstate);
    /* font-family: EYInterstate; */
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */
    svg {
        display: none !important;
    }
}

.spcl-char-error-message {

    color: var(--Red-600, #C70117);

    /* Caption/Light */
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */
    .motif-error-icon {
        display: none !important;
    }
}

@media screen and (max-width: 1400px) {
    #badgepopupmodal {
        min-height: 88vh;
    }
}
#documentUploadModal {
  padding: 24px;
  gap: 12px;
  overflow: unset;
  max-height: 110%;
  max-width: 90%;
}

#documentUploadModal .motif-modal-headline {
  padding: 0px !important;
  border: 0px !important;
}

#documentUploadModal .motif-modal-header {
  border: 0px !important;
}

#documentUploadModal .motif-modal-body {
  padding: unset;
  height: 100%;
  overflow: unset;
  overflow-y: unset;
}

.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
}

#documentUploadModal .motif-footer {
  background-color: unset;
  border-style: unset;
  border-color: unset;
  border-width: unset;
  height: unset;
  padding: unset;
}

#documentUploadModal .motif-button {
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.modal-uploader-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.divider-container {
  display: flex;
  height: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.upload-doc-divider {
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-top: var(--border-width-thin, 1px) solid
    var(--Neutrals-Light-theme-100, #e6e6e9);
}

.uploader-modal-body-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: var(--border-width-thin, 1px) solid
    var(--Neutrals-Light-theme-200, #d7d7dc);
  background: var(--Neutrals-Light-theme-00-White, #fff);
}

.document-header {
  display: flex;
  padding: 20px 24px;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: var(--border-width-thin, 1px) solid
    var(--Neutrals-Light-theme-200, #d7d7dc);
}

.header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.document-uploader-title {
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Body 1/Regular */
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.document-uploader-description {
  color: var(--Neutrals-Light-theme-700, #656579);

  /* Body 2/Regular */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.document-invalid-files-description {
  color: var(--Red-500, #ea011d);
  /* Body 2/Light */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
}

.document-search-filter-wrapper {
  display: flex;
  padding: 16px 0px 16px 24px;
  align-items: center;
  align-self: stretch;
  border-bottom: var(--border-width-thin, 1px) solid
    var(--Neutrals-Light-theme-200, #d7d7dc);
}

.document-checkbox-filter-wrapper {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.document-tab-header {
  display: flex;
  width: 100%;
  padding: 20px 24px;
  align-items: flex-start;
  border-bottom: 1px solid var(--Neutrals-Light-theme-200, #d7d7dc);
  background: var(--dev-only-transparent, rgba(0, 0, 0, 0));
}

.document-uploader-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.document-uploader-card-header {
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Body 1/Regular */
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.document-uploader-card-text {
  color: var(--Neutrals-Light-theme-700, #656579);

  /* Body 2/Light */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
}

.document-uploader-body {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.document-uploader-tabs {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.document-checkbox-filers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.checkbox-filters-box {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  height: 16px;
}

.checkbox-filter {
  display: flex;
  width: 70px;
  align-items: flex-start;
}

.motif-checkbox-custom {
  display: flex !important;
  width: 16px !important;
  height: 16px !important;
  justify-content: center !important;
  align-items: center !important;
}

.motif-checkbox-label {
  color: var(--Neutrals-Light-theme-900, #2e2e38) !important;

  /* Body 2/Regular */
  font-family: EYInterstate !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.checkbox-filter-text {
  align-self: stretch;
  color: var(--Neutrals-Light-theme-700, #656579);

  /* Caption/Light */
  font-family: EYInterstate;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px; /* 150% */
}

.document-states-wrapper {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
}

.document-states-value {
  color: #000;
  text-align: center;

  /* Caption/Regular */
  font-family: EYInterstate;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.document-states-value-error {
  color: var(--Red-500, #ea011d);
}

.searchIconDocument {
  width: 20px;
  height: 20px;
  z-index: 1;
  position: relative;
  top: 13px;
  left: 12px;
}
.searchTxtBoxDocument input {
  padding-left: 40px !important;
  position: relative;
  margin-top: -25px;
}

#documentUploaderSearch .motif-form-field {
  margin-bottom: unset;
}

#documentUploaderTabs {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

#documentUploaderTabs > button {
  color: var(--Neutrals-Light-theme-700, #656579);

  /* Body 2/Regular */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  display: flex;
  padding: 9px 4px 11px 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

#projectWorkspaceTab > .motif-active {
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Body 2/Regular */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.document-uploader-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
}

.document-upload-left-flex {
  flex-grow: 1;
  display: flex;
  gap: 16px;
  align-items: center;
}

.document-upload-primary-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

.document-tabs {
  width: 100%;
  position: relative;
}

.document-uploader-document-table {
  width: 100%;
  position: relative;
}

.document-uploader-document-table > .motif-toast-fixed-bottom {
  position: absolute !important;
}

.hiddenTabContent {
  display: none;
}

.modify-documents-footer-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.modify-selection-text {
  color: var(--Neutrals-Light-theme-500, #9897a6);

  /* Button/md */
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.doc-upload-remove-documents-button {
  border-radius: 4px;
  border: var(--border-width-thin, 1px) solid var(--Red-500, #ea011d);
  color: var(--Red-500, #ea011d);
}

.document-uploader-doc-name-wrapper {
  display: flex;
  gap: 12px;
}

.document-uploader-doc-name-wrapper-password {
  overflow: hidden;
  text-overflow: ellipsis;
  .motif-tooltip-wrapper .motif-tooltip-trigger-wrap {
    display: inherit;
  }
}

.document-uploader-doc-name-label {
  display: inline-block;
  margin-left: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.doc-name-label {
  margin-left: 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
}

.document-uploader-doc-icon {
  display: flex;
  gap: 8px;
  border-radius: 20px;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
  }
}

.iconDoc {
  vertical-align: top;
}

.loaderDiv {
  height: calc(100vh - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .refreshListText {
    font-family: EYInterstate;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
  }

  .refreshIcon {
    animation: rotation 2s linear infinite;
  }
}

.doc-remove-button {
  color: var(--Neutrals-Light-theme-00-White, #fff);

  /* Button/lg,xl,2xl */
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  border-radius: 4px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  background: var(--Red-500, #ea011d);
}

.doc-remove-button:disabled {
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  background: var(--Neutrals-Light-theme-500, #9897a6);
}

.hidden-doc-tab {
  display: none !important;
}

.document-replace-copy-description {
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Body 2/Light */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
}

.document-name-replace-modal {
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Body 2/Regular */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  display: flex;
  width: 320px;
  padding-top: 8px;
  align-items: flex-start;
  gap: 8px;
}
.error-message-doc-upload {
  color: var(--toast-error-support-color, #A40011);
font-family: var(--font-family-primary, EYInterstate);
font-size: var(--font-size-14, 14px);
font-style: normal;
font-weight: var(--font-weight-light, 300);
line-height: var(--line-height-20, 20px); /* 142.857% */
}

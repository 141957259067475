.Frame1DocSetting {

    width: 100%;
    position: relative;
    background: white;
}

.Frame2DocSetting {

    height: 126px;
    padding-bottom: 24px;
    top: 10px;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex
}

.Frame3DocSetting {

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: flex;
}

.Frame4DocSetting {
    border-radius: 6px;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: inline-flex;
}

.Frame5DocSetting {
    justify-content: center;
    align-items: center;
    gap: 5px;
    display: flex;

}

.Frame6DocSetting {
    color: #2E2E38;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word;
}

.Settings1 {

    width: 16px;
    height: 16px;
    position: relative;
}

.Settings2 {
    width: 5px;
    height: 9px;
    left: 5.50px;
    top: 3.50px;
    position: absolute;
    background: #9897A6;

}

.Settings3 {

    justify-content: center;
    align-items: center;
    gap: 5px;
    display: flex;
}

.Settings4 {
    color: #7F7F91;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word;
}

.Settings5 {

    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
}

.Settings6 {
    flex: 1 1 0;
    height: 36px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: flex;

}

.Settings7 {
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    display: inline-flex;
}

.Settings8 {
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex;
}

.Settings9 {
    color: #2E2E38;
    font-size: 28px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 36px;
    word-wrap: break-word;

}

.ResetButton1 {

    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex;
}

.ResetButton2 {
    border-radius: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.ResetButton3 {
    /* padding-left: 16px; padding-right: 16px; padding-top: 10px; padding-bottom: 10px;  */
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
    overflow: hidden;
    border: 1px #C3C3CB solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex;

}

.ResetButton4 {
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
}

.SaveButton1 {
    border-radius: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.SaveButton2 {

    /* padding-left: 16px; padding-right: 16px; padding-top: 10px; padding-bottom: 10px;  */
    background: #2E2E38;
    border-radius: 4px;
    overflow: hidden;
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex;
}

.SaveButton3 {
    color: white;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
}

.ReportAuthor1 {

    width: 100%;
    height: 56px;
    padding-left: 10px;
    padding-right: 16px;
    left: 0px;
    top: 0px;
    position: absolute;
    background: white;
    border-bottom: 1px #E6E6E9 solid;
    justify-content: space-between;
    align-items: flex-start;
    display: inline-flex;
}

.ReportAuthor2 {
    
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex;

}

.ReportAuthor3 {
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: flex
}

.ReportAuthor4 {
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: flex
}

.ReportAuthor5 {
    border-radius: 4px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex
}

.ReportAuthor6 {
    width: 40px;
    height: 40px;
    padding-top: 4.02px;
    padding-bottom: 5.30px;
    padding-left: 5px;
    padding-right: 5px;
    background: #2E2E38;
    border-radius: 2.50px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.ReportAuthor7 {
    width: 30px;
    height: 30.68px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.ReportAuthor8 {
    width: 30px;
    height: 10.95px;
    background: #FFE600
}

.ReportAuthor9 {
    width: 12.14px;
    height: 15.18px;
    background: white
}

.ReportAuthor10 {
    width: 15.15px;
    height: 15.18px;
    background: white
}

.ReportAuthor11 {

    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}

.PlaceHolder1 {
    align-self: stretch;
    padding-top: 12px;
    padding-bottom: 6px;
    
    align-items: flex-start;
    gap: 8px;
    display: flex;
}

.PlaceHolder2 {
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.PlaceHolder3 {

    padding: 2px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.PlaceHolder4 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex
}

.PlaceHolder5 {
    border-radius: 200px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex
}


.Noofdocuments1 {
    top: 110px;
    position: absolute;
    color: #2E2E38;
    font-size: 24px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 32px;
    word-wrap: break-word
}

.Noofdocuments2 {
    top: 170px;
    position: absolute;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    display: inline-flex
}

.Noofdocuments3 {
    width: 326px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex
}

.Noofdocuments4 {
    align-self: stretch;
    height: 94px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.Noofdocuments5 {
    align-self: stretch;
    height: 70px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.Noofdocuments6 {
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}

.Noofdocuments7 {
    align-self: stretch;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
    border-radius: 4px;
    border: 1px #C3C3CB solid;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex
}

.Noofdocuments8 {
    flex: 1 1 0;
    height: 24px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex;
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        margin-right: 20px;
    }
}

.Noofdocuments9 {
    flex: 1 1 0;
    color: #656579;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 24px;
    word-wrap: break-word
}

.SetMaximumLimit1 {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex
}

.SetMaximumLimit2 {
    color: #656579;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 18px;
    word-wrap: break-word;
}

.DocumentSize1 {
    width: 326px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex
}

.DocumentSize2 {
    align-self: stretch;
    height: 94px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.DocumentSize3 {
    align-self: stretch;
    height: 70px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.DocumentSize4 {
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}

.DocumentSize5 {
    align-self: stretch;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
    border-radius: 4px;
    border: 1px #C3C3CB solid;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex
}

.DocumentSize6 {
    flex: 1 1 0;
    height: 24px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex;
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        margin-right: 20px;
    }
}

.DocumentSize7 {
    flex: 1 1 0;
    color: #656579;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 24px;
    word-wrap: break-word
}

.MaxDocSize1 {

    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex
}

.MaxDocSize2 {
    color: #656579;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 18px;
    word-wrap: break-word
}

.WidthTextbox {

    width: 320px !important;
}

.toastStyle {

    width: 600px !important;
}

.ConfirmationStyle1 {
   
    width: 100%;
    height: 62px;
    padding: 16px;
    background: #E7FCE8;
    box-shadow: 0px 16px 32px rgba(35, 35, 47, 0.08);
    border-radius: 8px;
    border: 1px #2DB757 solid;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: inline-flex;  
    margin-top: 10px;
}

.ConfirmationStyle2 {
    flex: 1 1 0;
    height: 30px;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: flex
}

.ConfirmationStyle3 {
    width: 167px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 12px;
    padding-right: 4px;
    background: #48E674;
    border-radius: 16px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
}

.ConfirmationStyle4 {
    color: #0A3616;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word
}

.ConfirmationStyle5 {
    color: #197335;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 20px;
    word-wrap: break-word
}

.ConfirmationStyle6 {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    display: flex;
}

.ConfirmationStyle7 {
    border-radius: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.ConfirmationStyle8 {
    background: rgba(0, 0, 0, 0);
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex;
}

.ConfirmationStyle9 {
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
}

.ConfirmationStyle10 {
    width: 20px;
    height: 20px;
    position: relative;
}

.ConfirmationStyle11 {
    width: 11.67px;
    height: 11.25px;
    left: 4.38px;
    top: 4.38px;
    position: absolute;
    background: #2E2E38;
}
.RemoveUnderline{

    text-decoration: none;
}

.paddingTop5{
    padding-top: 5px;
}
.darken{
    filter: brightness(0.5);
}
.config-container{
    height: calc(100vh - 95px);
}

.ii-prompt-detail-no-selection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(55vh - 100px);
    text-align: center;
    gap: 16px;
    padding-top:48px;
  }

  .ii-prompt-no-selection-div {
    display: flex;
    flex-direction: column;
  }
  .icon-noPrompt {

}

  .ii-prompt-no-selection-text {
    color: var(--Neutrals-Light-theme-900, #2e2e38);
    text-align: center;
    font-family: EYInterstate;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

 
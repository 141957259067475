#EditProgressPopup {
    width: 540px;
    height: 264px;
    padding: 24px;
    border-radius: 8px;
    display: table;
}

.sub-container {
    display: flex;
    flex-direction: column;
}

.row-container {
    text-align: left;
    display: table-row;
}

.row-cell {
    display: table-cell;
    padding: 3px;
}

.buttonGroup {
    gap: 12px !important;
    float: right;
}

.button {
    height: 48px;
}

.Label {
font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
font-size: 16px;
font-weight: 300;
line-height: 24px;
text-align: left;

}

.input-row {
 
    display: flex;
    margin-bottom: 10px;
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}


#EditProgressPopup .motif-modal-body
{
    padding: 4px;
}

#EditProgressPopup .motif-modal-headline 
{
    padding: 0px !important;
    border: 0px !important;
}

#EditProgressPopup .motif-modal-header
{
    border: 0px !important;
}

.warning-symbol-container {
    border-radius: 24px;
    background:#FCF5F3;
    width: 48px;
    height: 48px;
}

.head-div
{
    padding-top: 5px;;
}
.EditProjectEditPageHeader {
    height: 126px;
    /* padding-top: 24px; */
    padding-bottom: 24px;
    left: 10px;
    /* top: 56px; */
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex
}

.EditProjectBreadcrumb {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.EditProjectBreadcrumbBase {
    border-radius: 6px;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: inline-flex
}

.EditProjectBreadcrumbItem {
    justify-content: center;
    align-items: center;
    gap: 5px;
    display: flex
}

.EditProjectText {
    color: #2E2E38;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word
}

.EditProjectText1 {
    color: #9897A6;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word
}

.EditProjectText2 {
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex
}

.EditProjectText3 {
    color: #2E2E38;
    font-size: 28px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 36px;
    word-wrap: break-word
}

.EditProjectText4 {
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}

.EditProjectText5 {
    color: white;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}

.EditProjectText6 {
    color: #2E2E38;
    font-size: 24px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 32px;
    word-wrap: break-word
}

.EditProjectProjectHeaderText {
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex
}
.EditMemberHeaderText {
    align-self: stretch;
    justify-content: flex-start;
   
    gap: 8px;
    display: inline-flex
}

.EditProjectProjectHeaderText1 {
    color: #2E2E38;
    font-size: 28px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 36px;
    word-wrap: break-word
}

.EditProjectNavArrowRight {
    width: 16px;
    height: 16px;
    position: relative
}

.EditProjectVector {
    width: 5px;
    height: 9px;
    left: 5.50px;
    top: 3.50px;
    position: absolute;
    background: #9897A6
}

.EditProjectContent {
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex
}

.EditProjectEditContent {
    width: 100%;
    height: 24px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
    /* min-height: 124vh; */
}

.instant-insights .EditProjectEditContent{
    min-height: 0%;
}

/* .EditProjectEditContent:has(.parent-project-detail) {
    min-height: 156vh;
} */



.EditProjectTitle {
    flex: 1 1 0;
    height: 36px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: flex
}

.EditProjectTitle1 {
    width: 978px;
    height: 36px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: inline-flex
}

.EditProjectEditTitle {
    flex: 1 1 0;
    height: 36px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: flex
}

.EditProjectTextAndSupportingText {
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    display: inline-flex
}

.EditProjectEditTextAndSupportingText {
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    display: inline-flex
}

.EditProjectActions {
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
}

.EditProjectEditActions {
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
}

.EditProjectEditCancelButtonBase {
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
    overflow: hidden;
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex
}

.EditProjectEditCancelText {
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}

.EditProjectEditSaveButton {
    border-radius: 0px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}


.EditProjectEditSaveButtonBase {
    padding-left: 2px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex
}

.EditProjectEditSaveText {
    color: white;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}


.EditProjectEditCancelButton {
    border-radius: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.EditProjectButton2 {
    border-radius: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}


.EditProjectButtonBase {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
    overflow: hidden;
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex
}

.EditProjectButtonBase1 {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #2E2E38;
    border-radius: 4px;
    overflow: hidden;
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex;
}


.EditProjectFrame1 {
    height: 648px;
    left: 96px;
    top: 182px;
    position: absolute;
    border-radius: 4px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: inline-flex
}
#toastDuplicate.motif-toast-fixed-top,
#toastDuplicate.motif-toast-fixed-bottom {
    width: 92% !important;
    left: 5% !important;
    position: fixed !important;  /* Ensure the positioning is fixed */
}
#toastError.motif-toast-fixed-top, 
#toastError.motif-toast-fixed-bottom {
    width: 54.8% !important;
    left: 5% !important;
    position: fixed !important;  /* Ensure the positioning is fixed */
}
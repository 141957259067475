.projectName {
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  font-weight: 300;
  line-height: 20px;
  word-wrap: break-word;
}

.projectDesc {
  color: #656579;

  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  font-weight: 300;
  line-height: 20px;
  word-wrap: break-word;
}

.projectNameDesc {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.allProjectPlatformAdmin1 {
  height: 60px;
  background: white;
  border-radius: 8px 8px 0px 0px;
  border-bottom: none !important;
  border: 1px solid rgb(215, 215, 220);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.allProjectPlatformAdmin2 {
  align-self: stretch;
  padding: 10px 24px;
  justify-content: space-between;
  display: inline-flex;
}

.allProjectPlatformAdmin3 {
  width: 605px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
}

.allProjectPlatformAdmin4 {
  align-self: stretch;
  height: 44px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
}

.allProjectPlatformAdmin5 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  height: 100%;
}

.allProjectPlatformAdmin6 {
  align-self: stretch;
  background: rgba(0, 0, 0, 0);
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.allProjectPlatformAdmin7 {
  color: rgb(46, 46, 56);
  font-size: 16;
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  font-weight: 400;
}

.ProjectChip{
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0.00));
  background: var(--Neutrals-Light-theme-50, #F3F3F5);
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.descriptivePlatformAdmin1 {
  align-self: stretch;
  color: rgb(101, 101, 121);
  font-size: 14;
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  font-weight: 300;
}

.openPlatformAdmin1 {
  align-self: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
}

.openPlatformAdmin2 {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.openPlatformAdmin3 {
  padding: 2px 6px 2px 8px;
  background: rgb(243, 243, 245);
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
  height: 15px;
}

.divMotifTable {
  width: 1295px;
  height: 500px;
  padding-bottom: 100px;
}

.openPlatformAdmin4 {
  text-align: center;
  color: rgb(46, 46, 56);
  font-size: 14;
  font-weight: 400;
  line-height: 20;
  overflow-wrap: break-word;
}

.motif-menu-overlay {
  background: none !important;
}

.motifbuttonchange {
  background: white;
  color: black;
  border: 0px solid var(--Neutrals-Light-theme-300, #c3c3cb);
  line-height: normal;
}

.motifbuttonchange1 {
  background: white;
  color: black;
  border: 1px solid var(--Neutrals-Light-theme-300, #c3c3cb);
  line-height: normal;
}

.sectorStyle .motif-select-wrapper-options {
  top: auto !important;
}

.dateRange .motif-date-picker-wrapper {
  width: 373px !important;
}

.subSectorStyle .motif-select-wrapper-options {
  top: auto !important;
}

.subsubSectorStyle .motif-select-wrapper-options {
  top: auto !important;
}

.paddingLeftimage {
  padding-left: 6px;
}

.searchIconimg .motif-search-input-icon svg {
  display: block;
  position: relative;
  bottom: 3px;
}

.areaStyle .motif-select-wrapper-options {
  top: auto !important;
}

.regionStyle .motif-select-wrapper-options {
  top: auto !important;
}

.countryStyle .motif-select-wrapper-options {
  top: auto !important;
}

#menuFilter .motif-menu-header {
  display: none;
}

.dateRange {
  font-size: 14;
  font-weight: 400;
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
}

.XClose {
  font-size: 14;
  font-weight: 400;
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  cursor: pointer;
}

.SectorAccordionMultiLevel
  .motif-accordion:last-of-type
  .motif-accordion-trigger {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.LocationAccordionMultiLevel
  .motif-accordion:last-of-type
  .motif-accordion-trigger {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.DealSizeAccordion .motif-accordion:last-of-type .motif-accordion-trigger {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.StatusAccordian .motif-accordion:last-of-type .motif-accordion-trigger {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.StatusAccordian
  .motif-accordion-content-visible:not(.motif-metadata)
  .motif-accordion-content {
  border: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top: none;
}

.StatusAccordian .motif-accordion-content {
  padding-top: 4px;
}

.DealSizeAccordion .motif-accordion-content {
  padding-top: 4px;
}

.motifToastExportdone1 {
  background: #e7fce8;
  box-shadow: 0px 16px 32px rgba(35, 35, 47, 0.08);
  width: 1216;
  height: 76;
  border-radius: 8;
  border: 1px #2db757 solid;
  margin-bottom: 20;
  margin-left: 54;
}

.motifToastExportdone2 {
  color: #197335;
  font-size: 14;
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

.motifToastExportdone3 {
  color: #197335;
  font-size: 14;
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

.main-container {
  padding-left: 18px;
}

.main-container-div {
  width: 99%;
  margin-top: 20px;
}

.super-admin-container {
  width: 100%;
  height: 44px;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
  margin-top: 20px;
}

.super-admin-div {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.super-admin-divs {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
}

.super-admin-top {
  flex: 1 1 0;
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.super-admin-filter {
  flex: 1 1 0;
  align-self: stretch;
  color: #656579;
  font-size: 16px;
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  font-weight: 300;
  width: 330px;
  word-wrap: break-word;
}

.project-header-container {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.project-header-div {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: rgba(0, 0, 0, 0);
  overflow: "hidden";
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.export-logs {
  color: #2e2e38;
  font-size: 16px;
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

.export-button {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.filter-container {
  position: "relative";
}

.filter-div {
  position: absolute;
  background: "rgb(101, 101, 121)";
}

.filter-button {
  background: white;
  color: black;
  border: 1px solid var(--Neutrals-Light-theme-300, #c3c3cb);
  line-height: normal;
}

.projectStatus-open {
  background: #E7FCE8;
  color: #26A04B;
  border-radius: 16px;
  padding: 2px 8px 2px 6px;

}

.projectStatus-close {
  background: #fcf5f3;
  color: #A40011;
  border-radius: 16px;
  padding: 2px 8px 2px 6px;
}

.motif-table .ag-row-odd {
  background: #fff !important;
}
.motif-table .ag-row-hover > .ag-column-hover {
  background: #f3f3f5 !important;
}
.motifbuttonchange1 {
  min-width: 40px !important;
  padding: 8px !important;
  margin: 5px 0;
}
.exportButton{
  justify-content: space-between;
}
.motifbuttonchange2{
  padding: 8px 16px !important;
  align-items: flex-start;
  background: white;
  color: black;
  border: 1px solid var(--Neutrals-Light-theme-300, #c3c3cb);
  line-height: normal;
  gap: 8px;
}
.motifbuttonchange2:focus {
  background: #d7d7dc;
}
.searchIconimg > div > input{
  padding: 7px !important;
}

.exportDropdown .motif-pagination-select .motif-dropdown-menu{
  top: -185px !important;
}
.rotate{
  rotate: 180deg;
}

.motif-input {
  border-radius: 5px !important;
}
.EngagementID {
  padding: 28px;
}

.Project-Table-Border-Radius {
  border-radius: 0px;
  border-top: none;
}

.ag-cell:focus {
  outline: none !important;
  border: none !important;
}
 .superAdminDashboard-table-container{
  .pagination-control-wrapper{
    background: #fff;
    padding: 8px 24px !important;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid #E6E6E9;
    border-top: none;
  }
 }


.motif-dropdown.motif-pagination-select svg {
  transform: rotate(180deg) !important;
}

.motif-pagination-select.motif-dropdown-open svg {
  transform: rotate(0deg) !important;
}

.super-admin-filter {
  .motif-input{
    padding-left: 30px !important;
  }
  .motif-icon-button {
    left: 4px ;
    top: 10px ;
  }
}

#shareTWPopup {
  width: 540px;
  radius: 8px;
  padding: 24px;
  overflow: hidden;
  display: table;
}

.sub-container {
  gap: 8px;
  border: 1px 0px 0px 0px;
  display: flex;
  flex-direction: column;
}

.row-container {
  text-align: left;
  display: table-row;
}

.row-cell {
  display: table-cell;
  padding: 3px;
}

.buttonGroup {
  gap: 12px !important;
  float: right;
}

.button {
  height: 48px;
}

.Label {
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.input-row {
  display: flex;
  margin-bottom: 10px;
}

.hint-text {
  font-family: EYInterstate;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
  color: #656579;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.inline-flex-container {
  float: left;
  display: inline-flex;
}

#shareTWPopup .motif-modal-body {
  overflow: unset;
  border-top: 1px solid var(--Neutrals-Light-theme-200, #d7d7dc);
  padding-top: 12px;
  padding-left: 1px;
  padding-right: 1px;
  height: 278px;
}

#shareTWPopup .motif-modal-header {
  border: unset;
}

#shareTWPopup .motif-modal-header .motif-modal-headline {
  padding: unset;
}

#selectionSection > .motif-form-field {
  margin-bottom:unset;
}
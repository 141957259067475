

.ii-delete-Div {
  width: 968px;
  height: 44px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
  display: inline-flex;
}

.li-delete-button {
  color: #2e2e38;
  background-color: transparent;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 4px;
  border: 1px solid #c3c3cb;
  display: flex;
  gap: 8px;
  padding: 10px 16px;
  background: #fff;
}
.li-delete-button:disabled {
  color: var(--Neutrals-Light-theme-500, #9897a6);

  img {
    opacity: 0.5;
    filter: grayscale(100%);
    pointer-events: none;
    cursor: not-allowed;
  }
}

.li-deletemodel-ul {
  margin: unset;
}

.txtdeleteprompt {
  padding-left: 5px;
}
.li-del-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.li-move-prompt {
  color: #fff;
  font-size: 16px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
  gap: 8px;
  padding: 10px 16px;
  display: flex;
}
.li-delete-bullet {
  margin-bottom: 0%;
}


.dynamic-height {
  height: calc(100vh - 150px);
}

#motifModalMoveInstantInsightPrompt {
  max-height: 42vw;
  display: flex;
  gap: 24px;

  .motif-modal-header {
    border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  }

  .motif-modal-body {
    padding: 0px;
  }

  .motif-form-field {
    margin-bottom: unset;
  }

  .motif-modal-footer {
    padding: unset;
  }
}

.ii-move-prompt-header-container {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--border-colors-default-primary, #e6e6e9);
}

.ii-move-prompt-header {
  color: var(--Neutrals-Light-theme-900, #2e2e38);
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.category-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.section-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.subinner-div {
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.inner-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.inner-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  color: var(--input-label-color, #656579);

  /* Body 2/Regular */
  font-family: var(--font-family-primary, EYInterstate);
  font-size: var(--font-size-14, 14px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--line-height-20, 20px);
  /* 142.857% */
}

.inner-value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--input-read-only-color, #2E2E38);
  text-overflow: ellipsis;

  /* Body 2/Light */
  font-family: var(--font-family-primary, EYInterstate);
  font-size: var(--font-size-14, 14px);
  font-style: normal;
  font-weight: var(--font-weight-light, 300);
  line-height: var(--line-height-20, 20px);
  /* 142.857% */
}

.subsection-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.ii-move-prompt-modal-row {
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
}

.ii-move-prompt-modal-label {
  color: var(--Neutrals-Light-theme-700, #656579);

  /* Body 2/Regular */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

#motifModalConfirmMovePrompt {
  display: flex;
  gap: 24px;
  .motif-modal-header {
    border-style: unset;
  }

  .motif-modal-body {
    padding: 0px;
  }

  .motif-modal-footer {
    padding: unset;
  }
}

#motifModalDeletePrompt {
  display: flex;
  gap: 24px;
  .motif-modal-header {
    border-style: unset;
  }

  .motif-modal-body {
    padding: 0px;
  }

  .motif-modal-footer {
    padding: unset;
  }
}

.confirm-move-prompt-modal-header {
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Subtitle/Regular */
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.warning-image-div {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  background: var(--Orange-25, #fcf5f0);
}

.confirm-move-prompt-body-container {
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Body 1/Light */
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
}

.motif-button-primary-alt:hover {
  background-color: #E6E6E9;
}
#motifModalEditStructure {
  max-height: inherit;
  display: flex;
  gap: 24px;
  .motif-modal-header {
    border-bottom: 1px solid #E6E6E9;
  }
  .motif-modal-body {
    padding: 0px;
  }
  .motif-form-field {
    width: 100%;
  }
  .motif-modal-footer {
    padding: unset;
  }
}
.ii-edit-structure-header-container_add {
  display: flex;
  padding: 16px 0px 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--border-colors-default-primary, #e6e6e9);
}
.category-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.section-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.subinner-div {
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.inner-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.inner-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  color: var(--input-label-color, #656579);
  /* Body 2/Regular */
  font-family: var(--font-family-primary, EYInterstate);
  font-size: var(--font-size-14, 14px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--line-height-20, 20px);
  /* 142.857% */
}
.inner-value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--input-read-only-color, #2E2E38);
  text-overflow: ellipsis;
  /* Body 2/Light */
  font-family: var(--font-family-primary, EYInterstate);
  font-size: var(--font-size-14, 14px);
  font-style: normal;
  font-weight: var(--font-weight-light, 300);
  line-height: var(--line-height-20, 20px);
  /* 142.857% */
}
.subsection-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.move-prompt-list-style {
  list-style: unset; 
}

.motif-modal-header-edit {
  border-style: unset !important;
}
* {
  scrollbar-width: thin;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.motif-progress-loader-fullscreen {
  margin-top: 0 !important;
  z-index: 2000;
}

.motif-toast-fixed-bottom {
  z-index: 2000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.HeaderLayout {
  display: flex;
  padding: 24px 0px 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: inherit;
}

.HeaderContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.HeaderTitle {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
}

.HeaderAction {
  display: flex;
  align-items: center;
  gap: 8px;
}

.HeaderText {
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* H4/Regular */
  font-family: EYInterstate;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 128.571% */
}

.PageContainer {
  width: 100%;
  padding: 0px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.manageStructureComponent .projectScopeSlection{max-width: 320px;margin: 24px 0 16px;}
.manageStructureComponent .select-project-scope-dropdown-title{padding-bottom: 6px;display: block;}
.manageStructureMain{height: calc(100vh - 440px);min-height: 550px;display:flex;flex-direction: row;justify-content: space-between;margin-bottom: 60px;}
.manageStructureMain .editStructure{width: 49.5%;height: 100%;border: 1px solid #E6E6E9;padding: 24px;display: flex;flex-direction: column;border-radius: 4px;}
.manageStructureMain .treeView{width: 49.5%;height: 100%;border: 1px solid #E6E6E9;padding: 24px;display: flex;flex-direction: column;border-radius: 4px;}
.manageStructureMain .structureTitle {
    font-size: 18px;
    font-weight: 400;
    color: #2E2E38;
    line-height: 28px;
    font-family: EYInterstate;
    padding-bottom: 20px;
    border-bottom: 1px solid #E6E6E9;
    margin-bottom: 8px;
}
.discard-wrapper {
    display: flex;
    justify-content: space-between;
}
.manageStructureMain .structureTitle span {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    font-family: EYInterstate;
}

.toast-div {
    left: 5.4%;
    width: 93%;
    margin-bottom: 50px;
    z-index: 1
}

.toast-msg {
    font-weight: 500;
}

.manageStructureMain .structureData{height: 100%; overflow-y: auto;}
.manageStructureMain .noScopeDiv{height: 100%;display: flex;justify-content: center;}
.manageStructureMain .noScopeMsgCont{gap: 0;}
.manageStructureMain .no-project-scope-detail{color: #656579;}
.manageStructureMain .buttonsGroup{display: flex;justify-content: flex-end;gap:8px;margin-top: 12px;}
.manageStructureMain .iconBtn{padding: 8px 14px;font-size: 14px;display: flex;align-items: center;line-height: 20px;min-width: inherit;}
.manageStructureMain .iconBtn img {margin-right: 6px;}
.manageStructureMain .infoGroup{margin-top: 12px;}
.manageStructureMain .infoGroup .motif-label{font-family:EYInterstate;font-weight: 400;line-height: 20px;}
#motifModalEditStructure .ii-move-prompt-modal-row{padding: 12px 0;font-family: EYInterstate;}
#motifModalEditStructure .ii-move-prompt-modal-row.new{padding: 0 0 12px;}
#motifModalEditStructure .subinner-div{padding: 6px 0;}
#motifModalEditStructure .motif-form-field{margin-bottom: 0;}
#motifModalEditStructure{gap: 4px;}
#motifModalEditStructure .ii-move-prompt-modal-label.scope{font-weight: 300;font-family: EYInterstate;color: #2E2E38;}
#motifModalEditStructure .ii-move-prompt-modal-label.subSection{margin-top: 10px;}
.renameTitle{padding: 12px 0 0;display: block;}
.ii-move-prompt-header-container{padding-bottom: 5px;}
#motifModalEditStructure .motif-modal-header > .motif-icon-button{height: 48px !important;width: 48px;}
#motifModalEditStructure .share-alert-btns-div{padding-top: 40px;}
#motifModalEditStructure .motif-modal-header{padding-bottom: 14px;}

.discard-button {
    display: flex;
    padding: 8px 14px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.discard-button:disabled{border-color: #C3C3CB;}
.discard-button:disabled .discard-text{color: #9897A6;}
.discard-button:disabled .cross-icon{opacity: 0.5;}

.cross-icon {
    width: 20px;
    height: 20px;
}

.discard-text {
    color: var(--button-secondary-color, #2E2E38);

    /* Button/sm */
    font-family: var(--font-family-primary, EYInterstate);
    font-size: var(--font-size-14, 14px);
    font-style: normal;
    font-weight: var(--font-weight-regular, 400);
    line-height: var(--line-height-20, 20px);
    /* 142.857% */
}
.prompt-managment-container {
  width: 100%;
}

#tabsPromptManagement {
  display: flex;
  gap: 16px;
  .motif-tab-button {
    color: var(--Neutrals-Light-theme-700, #656579);

    /* Body 1/Regular */
    font-family: EYInterstate;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    padding: 10px 4px;
  }

  .motif-tab-button.motif-active {
    color: var(--Neutrals-Light-theme-900, #2e2e38);

    /* Body 1/Regular */
    font-family: EYInterstate;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    padding: 10px 4px;
    --tab-navigation-horizontal-button--border-color: #FFF !important;
  }
  .motif-tab-button.active {
    background-color: #FFF !important;
    border-color: var(--tab-navigation-horizontal-button-active--border-color) !important;
  }
}

#tabsPPPromptManagement {
  display: flex;
  gap: 16px;
  .motif-tab-button {
    color: var(--Neutrals-Light-theme-700, #656579);

    /* Body 1/Regular */
    font-family: EYInterstate;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    padding: 10px 4px;
    background: transparent !important;
    &.active{
      border-color: #000 !important;
    }
  }
}
.mlabel-message-desc {
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--Neutrals-Light-theme-700, #656579);
}

.motif-rich-texteditor1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--Neutrals-Light-theme-300, #C3C3CB);
}

.motif-rich-editor-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
}

.motifeditor {
    color: var(--Neutrals-Light-theme-900, #2E2E38);
    width: 640px;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    flex: 1 0 0;
    align-self: stretch;
    font-weight: 300;
    text-align: left;
    margin-top: 8px;
}

.ql-snow .ql-tooltip {
    left: 10px !important;
    padding: 0px 16px !important;
    gap: 6px !important;
    align-items: flex-start !important;
    flex: 1 0 0 !important;
    align-self: stretch !important;
}

.motif-rich-editor-div .ql-container.ql-snow .ql-editor::before {
    padding: 20px;
    color: #999;
}

.motif-rich-editor-div .ql-editor {
    padding: 10px;
    height: 200px;
}

.editor-error {
    border: 1px solid #ea011d !important;
}

.linkError {
    font-family: EYInterstate;
    color: red;
    color: var(--input-support-text-error-color, #C70117);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    /* 150% */

}

.motif-link-label {
    font-family: EYInterstate !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    /* 166.667% */
    color: var(--card-header-color, #2E2E38);
}

.link-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--button-border-radius, 4px);
}

.ql-snow .ql-tooltip .ql-action {
    justify-content: center;
    align-items: center;
    border-radius: var(--button-border-radius, 4px);
    color: #0F69AE !important;

}

.link-minput-error{
  border : 1px solid red !important;
}
.ql-snow .ql-tooltip::before{
    content: "Enter link" !important;
    color: var(--card-header-color, #2E2E38);
font-family: EYInterstate !important;
font-size: 12px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 20px !important; 
}
.DashProjBreadcrumb {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.DashProjBreadcrumbBase {
  border-radius: 6px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}

.DashProjBreadcrumbItem {
  font-size: 12px;
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  font-weight: bold;
  line-height: 18px;
  word-wrap: break-word;
}

.DashProjNavArrowRight {
    width: 16px;
    height: 16px;
    position: relative
}

.filterwrapper {
    width: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
}

.filterwrapperinsider {
    align-self: stretch;
    height: auto;
    background: white;
    box-shadow: 0px 16px 32px rgba(35, 35, 47, 0.08);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
}

.Frame5407 {
    width: auto;
    height: auto;
    position: absolute;
    border: 1px #E6E6E9 solid;
}

.Frame5454 {
    height: 85vh;
    top: 0px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
}

.Frame5406 {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1px;
    display: inline-flex;
}

.filterwrapperdiv1 {
    align-self: stretch;
    height: auto;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 24px;
    padding-bottom: 24px;
    background: white;
    border-bottom: 1px #E6E6E9 solid;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: flex;
}

.filterwrapperdiv2 {
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex;
}

.filterwrapperdiv3 {
    flex: 1 1 0;
    height: 30px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: flex;
}

.filterText {
    align-self: stretch;
    color: rgb(46, 46, 56);
    font-size: 20px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.dateRangediv {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    width: 100%;
    padding-left: 16px;
    font-size: 14;
    font-weight: 400;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
}

.dateRangediv .motif-date-picker-wrapper {
    width: 405px !important;
    padding-right: 6px !important;
}

.accordiandiv {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    width: 94%;
    position: relative;
    left: 16px;
}

.accordiandiv .motif-accordion:last-of-type .motif-accordion-trigger {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.accordiandiv .motif-accordion-content-visible:not(.motif-metadata) .motif-accordion-content {
    border: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top: none;
}

.accordiandiv .motif-accordion-content {
    padding-top: 4px;
}

.SectorAccordionMultiLeveldiv {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    width: 94%;
    position: relative;
    left: 16px;
}

.SectorAccordionMultiLeveldiv .motif-accordion:last-of-type .motif-accordion-trigger {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.MotifAccordiondiv {
    width: 100%;
}

.LocationAccordionMultiLeveldiv {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    width: 94%;
    position: relative;
    left: 16px;
}

.LocationAccordionMultiLeveldiv .motif-accordion:last-of-type .motif-accordion-trigger {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.DealSizeAccordiondiv {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    width: 94%;
    position: relative;
    left: 16px;
}

.DealSizeAccordiondiv .motif-accordion:last-of-type .motif-accordion-trigger {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.DealSizeAccordiondiv .motif-accordion-content {
    padding-top: 4px;
}

.Buttondiv {
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
    border: 1px #C3C3CB solid;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.Closediv {
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.Buttondiv1 {
    padding: 10px 20px 10px 0px;
    border-top: 1px #E6E6E9 solid;
    left: 0px;
    position: relative;
    background: white;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 12px;
    display: flex;
}

.add-section-button {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 4px;
    border: 0px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
    background: var(--Neutrals-Light-theme-900, #2e2e38);
  }
  
  .create-section-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
    padding: 12px 2px;
    border-top: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
  }
  
  .create-section-title-span {
    color: var(--Neutrals-Light-theme-700, #656579);
  
    /* Body 2/Regular */
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  
  .section-input {
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    width: 100%;
    border: 1px solid var(--Neutrals-Light-theme-300, #c3c3cb);
    background: var(--Neutrals-Light-theme-00-White, #fff);
  }
  
  #motifModalAddSection {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  #motifModalAddSection .motif-modal-body {
    padding: unset;
  }
  
  #motifModalAddSection .motif-modal-footer {
    padding: unset;
  }
  

  #motifModalAddSection .motif-modal-header {
    border-style: unset;
    border-width: unset;
    border-color: unset;
  }
  #motifModalAddSection .motif-modal-header .motif-modal-headline {
    padding: unset;
  }

  #motifModalAddSection .motif-button-primary {
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
    /* background: var(--Neutrals-Light-theme-900, #2e2e38); */
  
    color: var(--Neutrals-Light-theme-00-White, #fff);
    font-family: EYInterstate;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  
  #motifModalAddSection .motif-button-primary-alt {
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--Neutrals-Light-theme-300, #c3c3cb);
    background: var(--dev-only-transparent, rgba(0, 0, 0, 0));
    color: var(--Neutrals-Light-theme-900, #2e2e38);
  
    /* Button/lg,xl,2xl */
    font-family: EYInterstate;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
  }
  
  .tw-section-list-container {
    display: flex;
    width: 100%;
    height: 544px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    overflow-y: auto;
  }
  
  #tw-section-list .motif-accordion {
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
    opacity: 0.8;
    background: var(--Neutrals-Light-theme-25, #f9f9fa);
  }
  
  #tw-section-list .motif-accordion-trigger {
    background: var(--Neutrals-Light-theme-25, #f9f9fa);
    border: unset;
  }
  
  #tw-section-list .motif-accordion-content {
    padding: 3px 16px 16px 16px;
  }
  
  .tw-favorite-tile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid var(--Neutrals-Light-theme-200, #d7d7dc);
    background: var(--Neutrals-Light-theme-00-White, #fff);
    padding: 16px;
    gap: 24px;
  
    color: var(--Neutrals-Light-theme-900, #2e2e38);
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  }
  
  .tw-section-container {
    display: flex;
    width: 100%;
    /* width: 320px; */
    height: 700px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid var(--Neutrals-Light-theme-200, #d7d7dc);
    background: var(--Neutrals-Light-theme-00-White, #fff);
  }
  
  .tw-section-search-container {
    display: flex;
    width: 100%;
    /* padding: 16px; */
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
    border-bottom: 1px solid var(--Neutrals-Light-theme-200, #d7d7dc);
    background: var(--dev-only-transparent, rgba(0, 0, 0, 0));
  }
  
  .tw-section-search-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    padding: 16px;
  }
  
  #tw-search-box .motif-button {
    min-width: unset;
    /* display: flex; */
    /* width: 36px; */
    height: 45px;
    justify-content: center;
    align-items: center;
    border-color: var(--search--border-color);
    border-style: var(--search--border-style);
    border-width: var(--search--border-width);
  }


  #tw-search-box .motif-form-field {
    margin-bottom: unset;
  }
  
  .tw-section-container-footer {
    display: flex;
    width: 100%;
    /* width: 320px;
    height: 84px; */
    padding: 16px;
    padding-bottom: 24px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 0px 0px 6px 6px;
    border: 1px solid var(--Neutrals-Light-theme-200, #d7d7dc);
    background: var(--Primary-White, #fff);
  }
  
  .tw-footer-box {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
  
  .tw-footer-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
  }
  
  .tw-footer-button {
    display: flex;
    gap: 8px;
  }
  
  #btnTWExport:hover {
    background-color: #dcd8d6;
  }
  
  #shareSuccessMessage {
    width: 86%;
    margin-left: 8%;
    margin-bottom: 1%;
  }
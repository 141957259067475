.Frame1 {
  height: 648px;
  left: 32px;
  top: 260px;
  position: absolute;
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
}

.Frame11 {
  width: 800px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
}

.selectMember {
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.Text6 {
  color: #2e2e38;
  font-size: 24px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 32px;
  word-wrap: break-word;
}

.ms-PeoplePicker {
  width: 753px;
}

.divMotifTable {
  width:100% !important;
  height: 400px;
  padding-bottom: 100px;
}

.memberGrid {
  margin-top: 47px;
  height: 350px;
}

.hoverMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 5px;
  pointer-events: none;
}

/* to disable the control in members screen for Non-SaT users */
.projectDashboardContainer {
  pointer-events: none;
  opacity: 0.7;
}

/* to enable the control in members screen for SaT users */
.projectDashboardContainer.activeMembers {
  pointer-events: auto;
  opacity: 1;
  min-height: 91vh;
}


.avtImage {
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 48px;
}

.roleImage {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.avtColumn {
  display: flex;
  align-items: center;
}

.Namedisplay {
  margin-left: 8px;
}

.Emaildisplay {
  margin-left: 1px;
}

.Input1 {
  align-self: stretch !important;
  /* padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important; 
    padding-bottom: 0x !important; */
  background: white !important;
  /* border-radius: 4px !important;
    border: 1px #C3C3CB solid !important; */
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 8px !important;
  display: inline-flex !important;
  width: 510px !important;
  height: 50%;
}

.role-alignment {
  display: flex;
  align-items: center;
}

.role-tooltip {
  width: "10px";
  height: "10px";
  border-radius: 50%;
}

.motif-button-content {
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
}

.TextPicker {
  width: 755px;
  height: 34px;
  /* padding-left: 14px;
    padding-right: 14px;
    padding-top: 10px;
    padding-bottom: 10px; */
  background: "white";
  border-radius: "4px";
  overflow: "hidden";
  border: 1px #c3c3cb solid;
  justify-content: flex-start;
  align-items: "center";
  gap: "8px";
  display: "inline-flex";
}

.delete-icon {
  width: 16.78px;
  height: 16px;
  left: 22px;
  top: 12px;
  position: absolute;
  /* background: #2e2e38; */
}

#roleimg {
  padding-left: 4px;
}

.motif-tooltip-bottom {
  inset: 7px auto auto 0.1rem !important;
}

#contentwrapper {
  position: relative;
  width: 100%;
}

#tooltiprole {
  height: 28px;
  width: 90%;
  float: left;
  color: var(--Neutrals-Light-theme-25, #F9F9FA);
  font-family: EYInterstate;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  flex: 0 0 65%;
}

#tooltiproledetail {
  color: var(--Neutrals-Light-theme-500, #9897A6);
  font-family: EYInterstate;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
}

#tooltipUserRoles {
  color: var(--Neutrals-Light-theme-500, #9897A6);
  font-family: EYInterstate;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}

.linespace {
  margin-bottom: 5%;
}

#tooltipwrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

#right {
  align-items: end;
}

#wrapper {
  position: relative;
  width: 100%;
}

#divrole {
  float: left;
}

#divtooltip {
  float: left;
}

.role-tooltip {
  .motif-tooltip-content-children {
    margin: 0px;
    padding: 12px;
  }
}
#wrapper{
  padding-bottom: 8px;
}
#closeicon{
  float: right;
}
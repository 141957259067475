    .discard-modal {
    display: flex;
    height: 508px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}
.dialog-modal {
    padding: 24px 24px 0px;
  }
.modalDelete-test-instant-title {
    color: var(--Neutrals-Light-theme-900, #2E2E38);
    /* Subtitle/Regular */
    font-family: EYInterstate;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.discard-modal .motif-modal-header .motif-modal-headline {
    padding: 0rem 0.8571428571rem 0rem 1.4285714286rem;
}

.confirm-button {
    background: var(--Red-500, #EA011D);
    border-radius: 4px;
    border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0.00));
}
.Cancel-button{
    border: 1px solid var(--Neutrals-Light-theme-300, #C3C3CB);
}
.delete-modal-header {
    border: none;
}

.delete-modal-header span {
    margin-top: 15px;
    display: block;
    font-family: EYInterstate;
    color: var(--Neutrals-Light-theme-900, #2E2E38);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
}

.title-container {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.deletePrompt-icon {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.deletePrompt-icon img {
    border-radius: 24px;
    background: var(--Red-25, #FCF5F3);
}
.ii-notification-header-icon-div {
    display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  background: var(--Neutrals-Light-theme-50, #F3F3F5);
  width: 52px;
  position: relative;
  bottom: 15px;
}
.delete-instant-delete-prompt-container{
    color: var(--Neutrals-Light-theme-900, #2E2E38);
    font-family: EYInterstate;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    
}
ul.delete-testInstant-list {
    list-style: none;
    padding: 0;
    margin: 0px 0px 0px 7px;
    height: 150px;
    margin-top:5px;
}

ul.delete-testInstant-list li {
    margin-bottom: 2px;
}

ul.delete-testInstant-list li::before {
    content: "\2023";
    font-size: 18px;
    display: inline-block;
    width: 1em;
}
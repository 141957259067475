.instant-insights {
  width: 100%;
  height: 100%;
  position: relative;
}

.responseNotAvailable-Maindiv{
  width: 100%; 
  /* height: 12%;  */
  background: white; 
   border-radius: 3px;
  /* overflow: hidden;  */
  border: 1px #D7D7DC solid; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  display: inline-flex
}
.responseNotAvailable-div{
  width: 1168px; 
  align-self: stretch; 
  justify-content: flex-start; 
  align-items: center; 
  gap: 20px; 
  display: flex
}
.responseNotAvailable-div1{
  flex: 1 1 0; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 4px; 
  display: inline-flex
}
.responseNotAvailable-div2{
  width: 1168px; 
  height: 24px; 
  justify-content: flex-start; 
  align-items: center; 
  gap: 8px; 
  display: inline-flex
}
.responseNotAvailable-div3{
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--Neutrals-Light-theme-900, #2e2e38);
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */  
  position: relative;
  left: 2%;
}

.projectdashboardnavdiv {
    width: 117%;
}

.prompt-accordion {
  width: 100%;
}

.heading {
  margin: 0px;
}

.sub-heading {
  margin: 0px;
}

.plain-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
}

.plain-button img {
    width: 85%;

}

.motif-tree-node-button {
  img{
   max-width: unset;
   max-height: unset;
  }
}

.right-section {
    width: 67%;
    height: 175%;
    top: 10%;
    right: 2%;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 17px;
    display: inline-flex;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: -12px;
}

.left {
  /* Optional: Add margin to separate from other content */
  margin-right: auto;
}

.right {
  /* Optional: Add margin to separate from other content */
  margin-left: 0px;
}
.rightFav {
  /* Optional: Add margin to separate from other content */
  margin-left:auto;
  margin-top: 7px;
}
.overlay {
    position: fixed; /* Fixed position relative to the viewport */
    top: 32%; /* top position */
    left: 74%; /* left position */
    background-color: white; /* Semi-transparent background */
    color: black;
    padding: 15px;
    z-index: 1000; /* Ensure it appears on top of other elements */
    border-radius: 5px;
    border: solid 1px lightgray;
    overflow-y: hidden;
    max-height: 400px;
    width: 24%;
}

.top-title-container {
    display: flex; /* Use flexbox */
    align-items: center; /* Align items vertically */
}

.top-title-container div {
    flex: 1;  /* Allow the div to grow and take up remaining space */
    padding: 10px 0px 0px 105px;  /* Padding for content */
}

.overlay:hover {
    overflow-y: auto;  /* Show the vertical scrollbar on hover */
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
    padding-top: 130px;
}

.no-docs {
    align-self: stretch;
    text-align: center;
    color: #2e2e38;
    font-size: 18px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 28px;
    word-wrap: break-word;
}
.instant-toast-msg{
  width: 65%;
  left: 33%;
  margin-bottom: 32px;

}
.instants-being-generated {
  color: var(--Neutrals-Light-theme-900, #2E2E38);
  text-align: center;
  /* Body 1/Regular */
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.instants-being-status {
  color: var(--Neutrals-Light-theme-700, #656579);
text-align: center;
/* Caption/Light */
font-family: EYInterstate;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: 18px; /* 150% */
position: relative;
left: 10%;
}
.instants-generate {
  margin-bottom: 4%;
  color: var(--Neutrals-Light-theme-900, #2E2E38);
text-align: left;
/* Body 2/Regular */
font-family: EYInterstate;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}
.no-doc-text {
    text-align: center;
    color: #656579;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300px;
    word-wrap: break-word;
    padding-top: 16px;
    align-self: center;
}

.btn-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}


.source-documents {
    box-sizing: border-box;
    width: 144px;
    margin: 0px;
}

.blue-highlight {
    border: solid 1px #036ac4;
}

.list-items {
    border: none !important;
}

.file-title {
    border: none !important;
    padding-left: 2px !important;
}

.file-list {
    padding-left: 0px;
}

.left-section {
  display: flex;
  flex-flow: column;
  height:490px ;
  width: 29%;
  top: 90px;
  position: absolute;
  border: 1px #E6E6E9 solid;
  overflow-x: auto;
}

.left-scope {
    margin: 1rem;
    width: 100%;
}

.left-scopeEdit {
    margin-left: auto;
    margin-right: 1rem;
}

.leftscope {
    display: flex;
    align-items: center;
    width: 100%;
}

.top-content {
  flex: 1;
}

.tree {
  flex: 1 1 auto;
   height: 600px;
   max-height: 600px;
    width: 100%;
    padding-top: 1rem;
    background: white;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    overflow: auto;
    border-top: 1px solid #E6E6E9;
    /* border-bottom: 1px solid #E6E6E9; */
    margin-bottom: 66px;
}

.noScopeBoard {
  width: 70%;
  float: right;
  position: relative;
  top:21%
  /* margin: 3rem; */
}
.noScopes {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.noScopeDiv {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: inline-flex;
  /* margin-left: 25%; */
}

.noScopeDiv_noinstant {
  width: 352px;
  height: 232px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}

.noScopeMsgCont {
  align-self: stretch;
  height: 76px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}
.noResponseMsg {
  align-self: stretch;
  height: 22px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}
.createScope-btn{
  display: flex;
  align-self: stretch;
  justify-content: center;
  padding-left: 1%;
}
.spaceprogress{
  height :14px;
}
.createScopetBtn {
  display: flex;
  align-self: stretch;
  justify-content: center;
  margin-left: 14%;
  height: 44px;  
}

.icon {
  border: 2px solid #E6E6E9;
  padding: 3px;
  /* background-color: #E6E6E9; */
  border-radius: 4px;
  /* height: 18px; */
  margin-left: 4px;
  margin-right: 5px;
  margin-top: 2px;
}

.icon-red-info {
  height: 25px;
  margin-right: 5px;
  margin-top: 2px;
}

.tree-icon-refresh {
  height: 18px;
  border: none;
  background-color: #E6E6E9;
  border-radius: 8px;
  padding: 3px;
  margin: 3px;
  margin-right: 5px;
}

img.icon-red-info + img.tree-icon-refresh{
  margin-top: 5px;
}

.icon-only {
  display: flex;
}

.div-checkbox {
  display: contents;
}

.relative {
  position: relative;
}
.select-none {
  -webkit-user-select: none;
  user-select: none;
}
.bg-blue {
  background: #80caff;
}

.bg-blue h1, .bg-blue h2, .bg-blue h3, .bg-blue h4, .bg-blue h5, .bg-blue h6, .bg-blue ul, .bg-blue ol, .bg-blue li, .bg-blue p {
  background: #80caff;
}

.favorite-response-modal-container {
  position: fixed;
  width: 94%;
  bottom: 0;
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-top: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
  background: var(--Neutrals-Light-theme-00-White, #fff);
}

.mark-fav-options {
  display: flex;
  height: 44px;
  align-items: center;
  gap: 7px;
}

.mark-fav-option-text {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--Neutrals-Light-theme-900, #2e2e38);
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.mark-fav-option-buttons {
  display: flex;
  align-items: flex-start;
  gap: 7px;
}
.mark-fav-option-selected {
  display: flex;
  align-items: flex-start;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  background: var(--Neutrals-Light-theme-200, #d7d7dc);
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Button/md */
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  pointer-events: none;
}

.mark-fav-option {
  display: flex;
  align-items: flex-start;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  background: var(--dev-only-transparent, rgba(0, 0, 0, 0));
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Button/md */
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.mark-fav-confirmation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.accaordian-header-container {
  display: flex;
  width: 100%;
  padding: 9px 0px;
  align-items: flex-start;
  align-self: stretch;
}

.accordian-header {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
}

.accordian-header-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.accordian-header-text {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--Neutrals-Light-theme-900, #2e2e38);
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.header-fav-div {
  display: flex;
  align-items: center;
  gap: 12px;
}

.motif-accordion-title-content {
  width: 100% !important;
}


.project-detail-link {
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: none;
    color: #0f69ae;
}

.no-project-scope-title {
    text-align: center;
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
}

.no-project-scope-detail {
    text-align: center;
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 20px;
    word-wrap: break-word;
}
.generate-instant-detail {
  color: var(--Neutrals-Light-theme-700, #656579);
text-align: center;
font-family: EYInterstate;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 20px; /* 150% */
}
.unfavorite-confirmation-modal-footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 12px;
}
.download-document-image{
  margin-right: 5px;
}
.download-span-align{
   display: flex;
}

.instant-left-button-div{
  flex: 0 1 auto;
  width: 26%;
  background-color: white;
  position: fixed;
  bottom: 0;
  display: flex;
  padding-bottom: 14px;
  margin-top: 20px;
  margin-bottom: 13px;
  align-items: flex-start;
  gap: 20px;
    justify-content: center;
}
.export-button-base{
  border-radius: 4px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0.00));
  background: var(--dev-only-transparent, rgba(0, 0, 0, 0.00));
  color: var(--Neutrals-Light-theme-900, #2E2E38);
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.refresh-button-base{
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
#scopeSelect .motif-select-wrapper-options {
  transform: translate(0px, 44px) !important;
}
#btnExportInsight:not(:disabled):hover{
  background-color: #DCD8D6;
}

#motifModalRefreshInsights .motif-modal-header {
  min-height: 90px;
}

.progress-bar-div {
  margin-top: 4px;
}
.progressbar-div {
  margin-top: 0%;
}
.width-left {
  width: 18%;
}

#motifModalExportInsights .motif-modal-header {
  min-height: 90px;
}

.instant-insights-header-export {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  background: var(--Blue-50, #d2e1fa);
  width: 42px;
}
.instant-insights-export-title {
  height: 28px;
  align-self: stretch;
  color: var(--Neutrals-Light-theme-900, #2e2e38);
  /* Subtitle/Regular */
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  margin-top: 8px;
}
.InputInstantInsight {
  align-self: stretch !important;
  background: white !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 8px !important;
  display: inline-flex !important;
  width: 100% !important;
}

.sourcedivider{
  color: #E6E6E9;
  border-style: solid;
}

.instant-insights-response-ai-container{
  gap: 4px; 
  display: inline-flex
}
.instant-insights-height{
  height: 1100px; 
  max-height: 1100px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-x: hidden;
}
.instant-insights-height1{
  
  width: 99%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  
}
.instant-insights-height-notAvailable{
  height: 1100px; 
  max-height: 1100px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-x: hidden;
}
.instant-insights-response-ai-text{
  color: #B26100; 
  font-size: 1rem; 
  font-family: EYInterstate; 
  font-weight: 300;
  word-wrap: break-word
}
.instant-insights-noresponse-ai-text{
  color: #C70117; ; 
  font-size: 1rem; 
  font-family: EYInterstate; 
  font-weight: 300;
  word-wrap: break-word
}

.select-project-scope-dropdown-title{
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #656579;
}
.no-instant-generated {
  text-align: center;
  color: #2E2E38;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}
.no-instant-generated-msg {
  color: var(--Neutrals-Light-theme-700, #656579);
text-align: center;
/* Body 2/Light */
font-family: EYInterstate;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 20px; /* 142.857% */
position: absolute;
top: 74%;
}
.no-instant-status {
  text-align: center;
  color: #2E2E38;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}
.buttondiv{
  position: absolute;
    z-index: 1;
}

.linkspace{
  flex: 1;
}
.select-project-scope-dropdown-field {
  margin: 0px;
}

.tree-div {
  .motif-checkbox-disabled {
    pointer-events: none;
  }
}
.modalContent {
  font-family: EYInterstate;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

#badgepopupmodal {
  font-family: EYInterstate;
}

.ii-acknowledgement-header-icon-div {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  background: var(--Neutrals-Light-theme-50, #F3F3F5);
}

.ii-acknowledgement-header-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ii-acknowledgement-title {
  color: var(--Neutrals-Light-theme-900, #2E2E38);
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

#motifModalAcknowledgeRefresh {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}

#motifModalAcknowledgeRefresh .motif-modal-header  {
  border-style: unset;
  /* border-width: unset;
  border-color: unset; */
}

/* #motifModalAcknowledgeRefresh > .motif-modal-header{
  border-style:unset;
} */

#motifModalAcknowledgeRefresh .motif-modal-body,
#motifModalAcknowledgeRefresh .motif-modal-footer, 
#motifModalAcknowledgeRefresh .motif-modal-header .motif-modal-headline {
  padding: unset;
}

#motifModalAcknowledgeRefresh > .motif-modal-body {
  overflow: hidden;
} 

#motifModalAcknowledgeRefresh .motif-button-primary {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  /* background: var(--Neutrals-Light-theme-900, #2e2e38); */

  color: var(--Neutrals-Light-theme-00-White, #fff);
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

#motifModalAcknowledgeRefresh .motif-button-primary-alt {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--Neutrals-Light-theme-300, #c3c3cb);
  background: var(--dev-only-transparent, rgba(0, 0, 0, 0));
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Button/lg,xl,2xl */
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}


.utilmargin .instant-insights .left-section{height: calc(100vh - 240px);position: sticky;top: 0px;}
.utilmargin .instant-insights .tree{margin-bottom: 10px;height: inherit;max-height: inherit;}
.ProjectDashboardEditDetails .instantheightnavdiv.motif-vertical-navigation.motif-vertical-navigation-collapsed {height: calc(100vh - 56px)}
.utilmargin .instant-insights .right-section{height: auto;}
.utilmargin .instant-insights .instant-insights-height{height: calc(100vh - 370px);margin-bottom: 40px;max-height: inherit;}
@media screen and (max-width: 1500px){
  .utilmargin .instant-insights .instant-insights-height{height: calc(100vh - 320px)}
}
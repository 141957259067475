.progress-container {
    width: 136%;
    height: 4px;
    background-color: #E6E6E9;
    /* Light-colored container */
    position: relative;
}

.progress-bar {
    width: 50%;
    height: 100%;
    background-color: #2E2E38;
    /* Dark-colored progress bar */
    animation: oscillate 2s infinite linear;
}

@keyframes oscillate {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}
.responseTabNavigation {
  --tab-navigation--color: #656579;
  --tab-navigation-active--color: #2e2e38;
  .motif-tab-button {
    font-weight: 400 !important;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    margin-right: 0.5rem;
  }
}
.originalRespTabContainer {
  display: block;
}
.modifiedRespTabContainer {
  display: none;
}

.modified-response-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.ql-editor {
  font-size: 16px;
}

.edit-icon-container {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}
.edit-icon-button {
  margin-left: auto;
}
.transfer-icon-button {
  margin-left: auto;
}
.explore-phase-edit-response-container {
  display: flex;
  width: 100%;
}
.edit-response-container {
  margin-left: auto;
  display: flex;
  gap: 8px;
}
div[class^='divModifiedResponse'], div[class*=' divModifiedResponse'] {
  &.ql-editor {
    padding: 0px;
    ul > li::before {
      content: '\26AC';
    }
  }
}


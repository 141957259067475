/* .removeNotificationText {
    color: #2E2E38;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

#removeNotificationLines {
    text-align: justify;
    padding-top: 24px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
}

.removeSymbolContainer {
    border-radius: 24px;
    background: #FCF5F3;
    width: 48px;
    height: 48px;
}

.warningCircle {
    padding-left: 12px;
    padding-top: 12px
}

.btnConfirm {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.00);
    background: #2E2E38;

    width: 100px;
    height: 48px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    position: relative;
    left: 69%;
    bottom: 21px;
}

.btnCancel {
    width: 92px;
    height: 52px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    border-radius: 4px;
    position: relative;
    bottom: 79px;
    left: 45%;
}

.modalFooter {
    height: 48px;
    margin-top: 24px;
    padding-right: 0px;
}

.textISConfirmModalBody {
    font-family: var(--primary-font);
    font-size: 16px;
    font-weight: var(--weight-light);
    line-height: 24px;
    padding-bottom: 32px;
}

.modalDiscardIncompleteStructure {
    .ii-acknowledgement-header-icon-div {
        background-color: #FCF5F3;
    }

    .motif-button {
        font-size: 18px;
        font-weight: normal;
        line-height: 50px;
    }
} */


.removeNotificationPopup {
    padding: 24px;
}

.RemoveNotification-header {
    border-style: none;
    padding-bottom: 24px;
}

.RemoveNotification-header .motif-modal-headline {
    padding: 0px;
}

.RemoveNotification-icon {
    padding: 12px;
    width: 48px;
    height: 48px;
    border-radius: var(--icon-featured-border-radius, 200px);
    background: var(--icon-featured-bg-color, #F3F3F5);
}

.RemoveNotification-title {
    height: 28px;
    align-self: stretch;
}

.RemoveNotification-body {
    padding: 0%;
    padding-bottom: 24px;
}

.RemoveNotificationPopup-innerbody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    line-height: 32px;
    font-family: var(--font-family-primary, EYInterstate);
    font-size: 16px;
    gap: 8px;
}

.RemoveNotification-footer {
    flex-direction: row-reverse;
    align-items: flex-end;
    padding: 0;
}

.RemoveNotification-footer button {
    margin-left: 10px;
    font-size: 18px;
}
* {
    list-style-position: inside;
}

.accordiandiv-notification .motif-accordion-content-visible:not(.motif-metadata){
    border:1px solid #FFFFFF;
    border-radius: 0;
}

.accordian-trigger-notification{
    border-radius: 0px !important;
    /* border-bottom: none !important; */
}

.notification-icon-image{
 height: 20px;
 width: 20px;
}

.notification-type-name{
    font-family: EYInterstate;
    font-weight: 400;
    font-size:14px;
    line-height: 20px;
    margin-left: 12px;
}


.notification-content {
    font-family: EYInterstate;
    padding: 16px 24px !important;
    border-radius: 0px !important;
    font-weight: 300 !important;
    font-size: 14px;
    line-height: 20px;

}

.notification-created-date{
    font-family: EYInterstate;
    font-size:12px;
    margin-left: 8px;
    color: #747480;
}


.notification-button-add-on {
    display: flex;
    gap: 20px;
    margin-top: 16px;
}

.notification-trigger-content{
    display: flex;
    align-items: center;
    
}

.accordion-notification{
    flex: 1;
}

.motif-accordion-content-visible:not(.motif-metadata) .motif-accordion-trigger{
    border-bottom: none;
}
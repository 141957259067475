.DashProjProjectLandingPage {
    width: 1440px;
    height: auto;
    position: relative;
    background: white;
    margin-left: 10px;
}

.DashProjPageHeader {
    height: 126px;
    /* padding-top: 24px; */
    padding-bottom: 24px;
    left: 10px;
    top: 56px;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex
}

.DashProjText1 {
    color: #9897A6;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word
}
.DashProjContent {
    width: 1000px;
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: inline-flex
}
.DashProjTitle {
    flex: 1 1 0;
    height: 36px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: flex
}
.DashProjTextAndSupportingText {
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    display: inline-flex
}
.DashProjTextAndSupportingText-member {
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    display: inline-flex;
    height: 20px;
}
.DashProjText2 {
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex
}
.DashProjText3 {
    color: #2E2E38;
    font-size: 28px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 36px;
    word-wrap: break-word;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 43px;
}
.right-panel-nav-container {
    width: 366px;
    height: 33px;
    padding-right: 37px;
    left: 10px;
    top: 123px;
    position: absolute;
    background: rgba(0, 0, 0, 0);
    border-bottom: 1px #e6e6e9 solid;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
  }
  .right-panel-nav-source-doc-container {
    padding-left: 24px;
    padding-right: 24px;
    justify-content: center;
    align-items: flex-start;
    display: inline-flex;
    gap: 24px;
  }
  .ProjViewDetailFrame1 {
    width: 978px;
    /* height: 620px; */
    left: 10px;
    top: 78px;
    position: absolute;
}
.ViewProjSelect {
    width: 310px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: inline-flex
}
.DashProjInputDropdownBase {
    align-self: stretch;
    height: 50px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.DashProjInputWithLabel {
    align-self: stretch;
    height: 50px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}
.DashProjLabel {
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}

.DashProjLabel1 {
    align-self: stretch;
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 24px;
    word-wrap: break-word;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 43px;
    width: 90%;
}
.DashProjLabelDescription{
    align-self: stretch;
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 24px;
    word-wrap: break-word;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    height: 94px;
    width: 90%;
}
.ProjViewDetailFrame2 {
    width: 978px;
    /* height: 620px; */
    left: 10px;
    top: 164px;
    position: absolute;
}
.DashProjSelect1 {
    width: 350px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: inline-flex
}
.DashProjFrame5383 {
    align-self: stretch;
    justify-content: space-between;
    align-items: flex-start;
    display: inline-flex
}

.DashProjFrame5357 {
    height: 20px;
    justify-content: space-between;
    align-items: center;
    display: flex
}
.DashProjCheckbox {
    height: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.DashProjCheckbox1 {
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    display: flex
}
.DashProjIconWithTooltip {
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    display: flex;   
}
.DashProjInputWithLabel1 {
    align-self: stretch;
    height: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}
.ProjViewDetailFrame3 {
    width: 978px;
    /* height: 620px; */
    left: 10px;
    top: 236px;
    position: absolute;
}
.DashProjFrame5 {
    width: 978px;
    height: 620px;
    left: 10px;
    top: 321px;
    position: absolute;
}
.dealSize {
    float: left;
    width: 33.33%;
    padding: 10px;
    top: 303px;
    position: absolute;
}
.tarSector {    
    padding: 29px;
    top: 281px;
    position: absolute;
    left: 289px;
}
.tarSubSector {
    padding: 29px;
    top: 281px;
    position: absolute;
    left: 600px;
}
.tarSubSubSector {
    float: left;
    width: 33.33%;
    padding: 10px;
    top: 380px;
    position: absolute;
}
.tarDataHosting {
    padding: 29px;
    top: 357px;
    position: absolute;
    left: 290px;
    width: 248px;
}
.projectScope{
    padding: 29px;
    top: 357px;
    position: absolute;
    left: 600px;
    width: 310px;
}
.DashProjScopeLabel {
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
}


.MemberDashProjFrame3 {    
    top: 67px;
    position: absolute;
}
.projectDescription{
    float: left;
    width: 33.33%;
    padding: 10px;
    top: 456px;
    position: absolute;
}
.ViewProjHeaderNav {
    width: 1350px;
    height: 56px;
    padding-left: 10px;
    padding-right: 16px;
    left: 0px;
    top: 0px;
    position: absolute;
    background: white;
    border-bottom: 1px #E6E6E9 solid;
    justify-content: space-between;
    align-items: flex-start;
    display: inline-flex
}

.ViewProjLogoAndMenu {
    /* width: 160px; */
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
}
.ViewProjLogoEngGap {
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: flex
}
.ViewProjLogoProduct {
    /* width: 160px; */
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: flex
}

.ViewProjLogo {
    border-radius: 4px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex
}
.ViewProjContainerLogo {
    width: 40px;
    height: 40px;
    padding-top: 4.02px;
    padding-bottom: 5.30px;
    padding-left: 5px;
    padding-right: 5px;
    background: #2E2E38;
    border-radius: 2.50px;
    justify-content: center;
    align-items: center;
    display: inline-flex
}
.ViewProjProductName {
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}
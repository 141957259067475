.CreateDataFrame1 {
    height: 648px;
    width: 100%;
    position: absolute;
    border-radius: 4px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: inline-flex;
    padding: 0px 32px;
}

.coming-soon .motif-select-checkbox {
    display: none !important;
}

.coming-soon {
    border-top: 1px #E6E6E9 solid;
    font-size: 14px;
    font-family: EYInterstate;
    font-style: italic;
    font-weight: 300;
}

.coming-soon .motif-select-checkbox {
    display: none !important;
}

.coming-soon {
    border-top: 1px #E6E6E9 solid;
    font-size: 14px;
    font-family: EYInterstate;
    font-style: italic;
    font-weight: 300;
}

.EditDataFrame1 {
    height: 648px;
    left: 3px;
    top: 150px;
    /* position: absolute; */
    border-radius: 4px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: inline-flex;
    width: 100%;
}

.Title1 {
    width: 100%;
    height: 36px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: inline-flex
}


.Text6 {
    color: #2E2E38;
    font-size: 24px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 32px;
    word-wrap: break-word
}

.Frame2 {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: inline-flex;
    margin-top: 20px;

}


.Select {
    width: 25%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: inline-flex
}

.InputDropdownBase {
    align-self: stretch;
    height: 70px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.ProjectDetailDropdown {
    width: 100%;
}


/* .DesktopCreateProject {
    width: 1300px;
    height: 1050px;
    position: relative;
    background: white
} 

.PageHeader {
    height: 126px;
    padding-top: 24px;
    padding-bottom: 24px;
    left: 32px;
    top: 56px;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex
}

.Breadcrumb {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.BreadcrumbBase {
    border-radius: 6px;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: inline-flex
}

.BreadcrumbItem {
    justify-content: center;
    align-items: center;
    gap: 5px;
    display: flex
}*/

.Text {
    color: #2E2E38;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word
}

.NavArrowRight {
    width: 16px;
    height: 16px;
    position: relative
}

.Vector {
    width: 5px;
    height: 9px;
    left: 5.50px;
    top: 3.50px;
    position: absolute;
    background: #9897A6
}

.Vector3 {
    width: 30px;
    height: 10.95px;
    background: #FFE600
}

.Vector4 {
    width: 12.14px;
    height: 15.18px;
    background: white
}

.Vector5 {
    width: 15.15px;
    height: 15.18px;
    background: white
}
/*
.Text1 {
    color: #9897A6;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word
}

 .Content {
    width: 1300px;
    height: 44px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex
} 

.Content1 {
    flex: 1 1 0;
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
}
.Content2 {
    flex: 1 1 0;
    height: 24px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
} 


.Content3 {
    align-self: stretch;
    height: 42px;
    padding-top: 2px;
    padding-left: 4px;
    padding-right: 4px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.Content4 {
    width: 272px;
    align-self: stretch;
    padding-top: 6px;
    padding-bottom: 6px;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.Title {
    flex: 1 1 0;
    height: 36px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: flex
}

.Text3 {
    color: #2E2E38;
    font-size: 28px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 36px;
    word-wrap: break-word
}

.Actions {
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
}

.Button {
    border-radius: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}


.ButtonBase {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
    overflow: hidden;
    border: 1px #C3C3CB solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex
}

.ButtonBase1 {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #2E2E38;
    border-radius: 4px;
    overflow: hidden;
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex
}

.Text4 {
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}


.Text5 {
    color: white;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}


.Text7 {
    flex: 1 1 0;
    color: #656579;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 24px;
    word-wrap: break-word
}

.Text8 {
    flex: 1 1 0;
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}


.Text9 {
    align-self: stretch;
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}*/

.Text10 {
    align-self: stretch;
    color: red;
    font-size: 13px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
    margin-top: 2px;
}

.WrapText {
    word-wrap: normal;
}

.ArrowRight {
    width: 20px;
    height: 20px;
    position: relative
}

.InputWithLabel {
    align-self: stretch;
    height: 70px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.Label {
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}

.motif-form-field-select-input {
    width: 100% !important;
}

.motif-typeahead {
    width: 100% !important;
}

.motif-input-component {
    width: 100% !important;
}

.Input {
    align-self: stretch !important;
    /* padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important; 
    padding-bottom: 0x !important; */
    background: white !important;
    /* border-radius: 4px !important;
    border: 1px #C3C3CB solid !important; */
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 8px !important;
    display: inline-flex !important;
    width: 100% !important;
}

.InputCLient {
    align-self: stretch !important;
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 8px !important;
    display: inline-flex !important;
}


.PaceIdInput {
    align-self: stretch !important;
    /* padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important; 
    padding-bottom: 0x !important; */
    /* border-radius: 4px !important;
    border: 1px #C3C3CB solid !important; */
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 8px !important;
    display: inline-flex !important;
    padding-right: 2rem !important;
}
.engagementId{
    width: 100%;
}
.errMsg{
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;
    color: #C70117;
}

.Input button {
    padding-left: 8px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.InputCategory {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.ProjectInput {
    align-self: stretch !important;
    /* padding-left: 14px !important; */
    padding-right: 14px !important;
    /* padding-top: 10px !important; */
    padding-bottom: 10px !important;
    /* background: white !important; */
    /* border-radius: 4px !important;
    border: 1px #C3C3CB solid !important; */
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 8px !important;
    display: inline-flex !important;
    width: 485px !important;
}

.Input1 {
    padding-top: 2px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex
}

.Input2 {
    align-self: stretch;
    flex: 1 1 0;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: white;
    border-radius: 4px;
    border: 1px #C3C3CB solid
}

.InputDescription {
    align-self: stretch !important;
    /* padding-left: 14px !important; */
    padding-right: 14px !important;
    /* padding-top: 10px !important; */
    padding-bottom: 10px !important;
    background: white !important;
    /* border-radius: 4px !important;
    border: 1px #C3C3CB solid !important; */
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 8px !important;
    display: inline-flex !important;
    height: 76px;
}

.InputMotif {
    --input-textarea--border-width: 200px;
}

.PlaceholderWrapper {
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    display: flex
}

.NavArrowDown {
    width: 20px;
    height: 20px;
    position: relative
}

.StaticLabelInputField {
    width: 326px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex
}

.StaticLabelInputField1 {
    width: 25%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex
}

.DefaultInputFieldBase {
    align-self: stretch;
    height: 70px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}


.NewFrame {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: inline-flex
}

.Frame5383 {
    align-self: stretch;
    justify-content: space-between;
    align-items: flex-start;
    display: inline-flex
}

.Frame5357 {
    height: 20px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 182px;
}

.Checkbox {
    height: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.Checkbox1 {
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    display: flex
}

.CheckboxBase {
    width: 16px;
    height: 16px;
    position: relative;
    background: white;
    border-radius: 1px;
    border: 1px #7F7F91 solid
}

.IconWithTooltip {
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    display: flex
}

.HelpCircle {
    width: 16px;
    height: 16px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.TextArea {
    width: 78.88%;
    height: 118px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex
}

.Textarea {
    align-self: stretch;
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.LabelGroup {
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    display: inline-flex
}

.LabelOutside {
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 20px;
    word-wrap: break-word
}


.SupportingText {
    align-self: stretch
}

.LogoAndMenu {
    /* width: 160px; */
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
}

.LogoEngGap {
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: flex
}

.LogoProduct {
    /* width: 160px; */
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: flex
}

.Logo {
    border-radius: 4px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex
}

.ContainerLogo {
    width: 40px;
    height: 40px;
    padding-top: 4.02px;
    padding-bottom: 5.30px;
    padding-left: 5px;
    padding-right: 5px;
    background: #2E2E38;
    border-radius: 2.50px;
    justify-content: center;
    align-items: center;
    display: inline-flex
}

.ScaledLogo {
    width: 30px;
    height: 30.68px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.ProductName {
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}

.ProjectDetailDivider {
    width: 100%;
    height: 1px;
    background: #E6E6E9;
    margin-top: 10px;
}

.delete-icon {
    width: 16.78px;
    height: 16px;
    left: 22px;
    top: 12px;
    position: absolute;
    /* background: #2e2e38; */
  }

  .parent-project-detail {
    width: 100%;
  }

 .projectdetail-bottom-desc{
    color: var(--Neutrals-Light-theme-700, #656579);
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
 } 

 .engagement-id-input {
    width: 100%;
 }

 .error-box{
    width: 100%;
 }

 .pace-id-error {
    margin-top: -10px;
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;
    color: #C70117;
 }
 .delete-toast-msg{
    margin-bottom: 32px;
    width: 89%;
    left: 5%;
  }

  .info-icon-image{
    align-items: center;
    margin-top: 4px;
  }

  .Label-Engagement{
    color: #2E2E38;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
    gap: 4px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
.GroupVectorHeight {
 width : 30%
  }
.GroupVector {
    position: absolute;
   left: 20%;
   top: 23%;
  }

.LogoutDiv{
    position: absolute;
    left: 68%;
    top: 45%;
}
.LogOutBox{
    width: 362px;
    height: 255px;
    padding: 32px;
    background: white;
    box-shadow: rgba(35, 35, 47, 0.08) 0px 4px 8px;
}
.LogOutSucess{
    align-self: stretch;
    color: rgb(46, 46, 56);
    font-size: 20px;
    font-family: EYInterstate;
    font-weight: 400;
    height: 18%;
}
.LogOutmessage{
    width: 317px;
    color: rgb(101, 101, 121);
    font-size: 16px;
    font-family: EYInterstate;
}
.LogOutBtnDiv{
    width: 70%;
    height: 65px;
    /* justify-content: flex-start; */
    align-items: flex-end;
    gap: 11px;
    display: inline-flex;
}
.motifLogoutButton{
    width: 75%;
}

.header {
  display: flex;
  height: 56px;
  padding: 0px 16px 0px 10px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-bottom: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
  background: var(--Neutrals-Light-theme-00-White, #fff);
  width: 100%;

  .logomenu {
    gap: 8px;
    display: flex;
    align-self: stretch;
    align-items: center;

    .logoDiv {
      display: flex;
      height: 44px;
      align-items: center;
      gap: 12px;

      .logo {
        display: flex;
        width: 40px;
        height: 40px;
        padding: 4.023px 5px 5.301px 5.001px;
        justify-content: center;
        align-items: center;
        border-radius: 2.5px;
        background: #2e2e38;
      }
    }

    .projectTitle {
      color: var(--Neutrals-Light-theme-900, #2e2e38);

      /* Body 1/Regular */
      font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
    }
  }

  .NavDropdown {
    padding: 2px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: flex;
  }

  .Dropdown {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    margin-top: 5px;
  }

  .Avatar {

    &:focus,
    &:hover {
      filter: brightness(95%);
    }

    border-radius: 200px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex;
  }

  .Avatar[aria-expanded="true"] {
    cursor: default;
    filter: brightness(90%);
  }
}

.headerContainer {
  width: 275px;
  height: 240px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  display: inline-flex;
  z-index: 1;
  position: absolute;
  right: 0;
  margin-right: 25px;
}

.userMenuPopup {
  width: 275px;
  background: white;
  box-shadow: 0px 8px 16px rgba(35, 35, 47, 0.08);
  border-radius: 4px;
  border: 1px #e6e6e9 solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.userMenuCont {
  align-self: stretch;
  padding: 14px;
  background: rgba(0, 0, 0, 0);
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.userMenuAvatar {
  display: flex;
  align-items: center;
}

.userAvatar {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 48px;
}

.engagementLocationSelectDiv {
  padding-top: 0.5%;
  display: inline-flex;
  margin-left: auto;
  padding-right: 10px;
}

.engagementLocationSelectLabel {
  padding-top: 3%;
  text-align: right;
  color: #2E2E38;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  padding-right: 5px;
}

.engagementLocationSelectLabelStatic {
  padding-left: 122px;
  padding-top: 3%;
  text-align: right;
  color: #2E2E38;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  padding-right: 5px;
}

.engagementLocationImage {
  height: 1.5rem;
  width: 1.5rem;
  padding-top: 0rem;
  vertical-align: middle;
  margin-left: 0.5rem;
}

.engagementLocationTooltipText {
  width: 20rem;
  height: 3rem;
  color: '#F9F9FA';
  font-size: 12;
  font-family: 'EYInterstate';
  font-weight: '400';
  word-wrap: 'break-word'
}

.logoutimg {
  top: 16%;
  left: 19%;
  vertical-align: middle;
}

.logout {
  right: 54%;
  top: 16%;
  text-decoration: none;
  margin-left: 5px;
  font-family: EYInterstate;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.userguide {
  right: 54%;
  top: 16%;
  text-decoration: none;
  margin-left: 8px;
}

.userguideimg {
  top: 16%;
  left: 19%;
  vertical-align: middle;
}

.divider {
  margin-top: 10px;
  margin-bottom: 10px;
}

#ProfileDropdown {
  box-shadow: 0px 8px 16px 0px #23232F14;
  border-radius: 4px;
  border: 1px solid #E6E6E9;
  min-width: 275px;
  min-height: 114px;
  margin-left: -16px;
  margin-top: -1px;
}

.logoutcontainer {
  &:hover {
    background-color: #F3F3F5;
  }
  margin: 4px 0;
}

.userguidecontainer {
  &:hover {
    background-color: #F3F3F5;
  }
  margin: 4px 0;
}

.userAvatarUserMenuItem.motif-dropdown-item {
  &:focus,
  &:hover {
    background-color: #F3F3F5;
  }
}

.profileImageContainer {
  width: 60px;
  float: right;
  display: flex;
  padding: 6px 10px 6px 0px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.dropdownContainer {
  position: absolute;
  z-index: 1000;
  display: block;
}

.notificationImage {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  background: var(--dev-only-transparent, rgba(0, 0, 0, 0));
}

.NavDropdown {
  padding: 2px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.Dropdown {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.AvatarDiv {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 48px;
}

.userAvatarUserMenuItem {
  margin-left: 2px;
  text-align: left !important;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2E2E38 !important;
  height: 56px !important;
  transition: background-color 0.3s;
}

.userAvatarEmailMenuItem {
  font-family: EYInterstate;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: #9897A6;
}

.displaymenuDiv {
  display: flex;
  align-items: center;
}
.ii-prompt-management-tab-container {
  margin-top: 16px;
  height: calc(100vh - 280px);
  margin-bottom: 30px;
  position: relative;
}

.right-prompts-pane {
  overflow-y: auto;
  max-height: calc(100vh - 390px);
  margin-top: -16px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ii-right-prompts-pane {
  overflow-y: auto;
  max-height: calc(100vh - 413px);
  margin-top: -16px;
}

.right-test-propmpts-pane {
  overflow-y: auto;
  max-height: calc(100vh - 375px);
}

.ii-prompt-left-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 0 0;
  align-self: stretch;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(35, 35, 47, 0.06),
    0px 4px 8px 0px rgba(35, 35, 47, 0.08);
  height: calc(100vh - 270px);
  border-top: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
  margin-left: 3px;
  position: fixed;
  width: 320px;
  transition: 0.3s ease all;
}
.rightPortion{
  transition: 0.3s ease all;
    width: calc(100% - 335px);
    justify-content: flex-end;
    display: flex;
    position: absolute;
    right: 0px;
    height: calc(100vh - 250px);
    margin-left: 15px;
}

.ii-prompt-project-scope-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  border-bottom: 1px solid var(--Grey-200, #eaeaf2);
  background: #fff;
}

.ii-prompt-select-scope-label {
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #656579;
}

#ii-prompt-project-scope .motif-form-field {
  margin-bottom: unset;
}
.ii-tree-container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}

.ii-left-menu-footer {
  display: flex;
  width: 100%;
  height: 84px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
  bottom: 0px;
  border-top: 1px solid var(--Grey-200, #eaeaf2);
  background: var(--Primary-White, #fff);
}

.accordion-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordion-title .motif-icon-button img{vertical-align: middle;}
.prompt-edited-toast {
  bottom: 20px;
  z-index: 1000;
  position: absolute;
  width: 100% !important;
  right: 0;
  left: inherit;
  margin: 0;
}

.edited-chip {
  --chip--border-color: #FDBC84;
  --chip--color: #D07200;
  --chip--bg-color: #FCF5F0;
  margin-right: 10px;
}

.prompt-status-chip-new {
  --chip--color: #197335;
  --chip--bg-color: #ffffff;
  margin-right: 10px;

  border-radius: var(--chip-border-radius, 16px);
  border: var(--border-width-thin, 1px) solid var(--accent-success-extra-C, #48E674);

  .motif-chip-button {
    color: var(--chip-outlined-success-color, #197335);
    text-align: center;

    /* Body 2/Regular */
    font-family: var(--font-family-primary, EYInterstate);
    font-size: var(--font-size-14, 14px);
    font-style: normal;
    font-weight: var(--font-weight-regular, 400);
    line-height: var(--line-height-20, 20px); /* 142.857% */
  }
}

.prompt-status-chip-edited {
  --chip--color: #975100;
  --chip--bg-color: #FCF5F0;
  margin-right: 10px;

  border-radius: var(--chip-border-radius, 16px);
  border: var(--border-width-thin, 1px) solid var(--accent-warning-extra-C, #FDBC84);

  .motif-chip-button {
    color: var(--chip-outlined-warning-color, #975100);
text-align: center;

/* Body 2/Regular */
font-family: var(--font-family-primary, EYInterstate);
font-size: var(--font-size-14, 14px);
font-style: normal;
font-weight: var(--font-weight-regular, 400);
line-height: var(--line-height-20, 20px); /* 142.857% */
  }
}

.dialog-modal {
  padding: 24px 24px 0px;
}

.dialog-modal>.motif-modal-footer {
  padding-right: 0px;
}

.dialog-modal>.motif-modal-body {
  padding: 0px;
}

.dialog-modal>.motif-modal-header>.motif-modal-headline {
  padding-left: 0px !important;
}

.edited-chip>.motif-chip-button {
  font-weight: 400;
}

.chars-left {
  display: flex;
  justify-content: right;
  font-family: var(--font-family-primary, EYInterstate);
  font-size: 12px;
  font-style: normal;
  margin-left: auto;
  
}
.title-label {
  margin-bottom: 0;
  margin-top: 16px;
}

.title-prompt {
  margin-top: 16px;
  margin-bottom: 0;
}

.motif-modal-header > .motif-icon-button {
  height: 4.230000rem !important;
}

.info-div {
  display: flex;
  gap: 6px;
  margin-top: 12px;
}

.info-icon-div {
  display: flex;
}

.span-btn-txt {
  margin-left: 7px;
}

.btn-manage-scope {
  display: flex;
  justify-content: center;
  background: white;
  color: black;
  border: 1px solid var(--Neutrals-Light-theme-300, #c3c3cb);
  line-height: normal;
  margin-right: 15px;
}

.ii-prompt-response-detail {
  margin-bottom: 20px;
  width: 100%;
  padding-left: 5px;
}

.ii-prompt-detail-no-selection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 360px);
  text-align: center;
  gap: 16px;
}
.powerpointPrompts .ii-prompt-detail-no-selection{height: calc(100vh - 480px);}

.ii-prompt-no-selection-div {
  display: flex;
  flex-direction: column;
}

.ii-prompt-no-selection-text {
  color: var(--Neutrals-Light-theme-900, #2e2e38);
  text-align: center;
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.ii-prompt-no-selection-desc {
  color: var(--Neutrals-Light-theme-700, #656579);
  text-align: center;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.ii-detail-container {
  display: flex;
  /* padding-bottom: var(--border-width-thin, 1px); */
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
}

.ii-detail-subsection-header {
  color: var(--header-title-color, #2e2e38);

  /* Subtitle/Regular */
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

#tabPromptResponse {
  width: 100%;
  display: flex;
  gap: 16px;
  .motif-tab-button {
    color: var(--tab-navigation-button-color, #656579);

    /* Body 2/Regular */
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    padding: 9px 4px 11px 4px;
    justify-content: center;
    gap: 8px;
  }

  .motif-active {
    color: var(--tab-navigation-button-selected-color, #2e2e38);
    border-radius: var(--tab-navigation-button-border-radius, 2px)
      var(--tab-navigation-button-border-radius, 2px) 0px 0px;
    border-bottom: var(--border-width-medium, 2px) solid
      var(--alt-border-colors-default-extra-B, #2e2e38);
  }
}

#promptIIActivePromptDetail {
  width: 100%;
  .motif-accordion-trigger {
    padding: 24px 24px;
  }
}

.activeNode {
  background-color: #E6E6E9;
  width: 209px;
  height: 40px;
}

#promptIITestPromptDetail {
  width: 100%;
  display: none;

  .motif-accordion-trigger {
    padding: 24px 24px;
  }
}

.ii-prompt-response-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ii-prompt-action-section {
  width: 100%;
  display: flex;
  flex: 1 0;
  align-self: stretch;
  justify-content: flex-end;
  gap: 8px;
  height: 47px;
}

.ii-prompt-action-section-active {
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  flex: 1 0;
  align-self: stretch;
  justify-content: flex-end;
  gap: 8px;
}

.ii-detail-prompt-accordion {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}

.instant-toast-msg {
  width: 69.8%;
  left: 28.7%;
  bottom: 6%;
}

.instant-toast-msg-delete {
  width: 68.7%;
  left: 28.1%;
  margin-bottom: 55px;
   z-index: 1; 
}

.ii-prompt-accordion {
  border-radius: 5px;
  border: 1px solid var(--Neutrals-Light-theme-200, #d7d7dc);
  background: var(--Neutrals-Light-theme-00-White, #fff);
}
.ii-prompt-selected-accordion {
  border-radius: 5px;
  border: 2px solid var(--color-blue-600, #0f69ae);
  background: var(--Neutrals-Light-theme-00-White, #fff);
}

.ii-response-accordion-trigger {
  display: flex;
  align-items: center;
  gap: 20px;
}

.ii-prompt-detail-text {
  color: var(--Neutrals-Light-theme-700, #656579);

  /* Body 2/Light */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
  align-self: stretch;

  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  word-break: break-all;
}
.ii-prompt-detail-text ol, .ii-prompt-detail-text ul{padding-left: 16px;}

.system-prompts-category-container {
  display: flex;
  padding: 8px 0px;
  align-items: flex-start;
  flex: 1 0 0;
  flex-direction: column;
}

.system-prompts-category {
  display: flex;
  margin: 0px 8px;
  padding: 8px;
  width: 95%;

  & .active {
    border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
    background: var(--Neutrals-Light-theme-100, #e6e6e9);
  }
}

.system-prompts-category:hover {
  cursor: pointer;
  background: var(--Neutrals-Light-theme-100, #e6e6e9);
}

.system-prompts-category-name {
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Body 2/Light */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
}

.errorText {
  font-family: var(--font-family-primary, EYInterstate);
  font-size: 12px;
  font-style: normal;
  bottom: 22px;
  display: flex;
  }

.prompt-textarea.error-border {
  border-color: #C70117 !important; 
}

.prompt-textarea:focus-visible.error-border{
  outline: none !important;
}

.max_limit{
  margin-bottom: 7x;
  display: flex;
  font-family: var(--font-family-primary, EYInterstate);
  font-size: 12px;
  font-style: normal;
}

.error_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
}

.manage-structure-modal-input.error-border {
  border: 1px solid red !important; 
  outline: none;  
}
.manage-structure-modal-input:focus-visible.error-border{
  outline: none !important;
}

.prompt-move-success-message {
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
}
#editPromptModalnew .motif-input-component .motif-text-area:focus-visible{outline-offset: -2px;}
#editPromptModalnew .title-label, #editPromptModalnew .title-prompt{color: #656579;font-weight: 400;}
#editPromptModalnew .info{color:#2E2E38;font-weight: 400;}
#editPromptModalnew.dialog-modal>.motif-modal-footer {padding-bottom: 24px;}
#editPromptModalnew.dialog-modal>.motif-modal-header>.motif-modal-headline {padding-bottom: 36px;}
#editPromptModalnew .motif-modal-header > .motif-icon-button{height: 48px !important;width: 48px;}
#editPromptModalnew .title-label .motif-label, 
#editPromptModalnew .title-prompt .motif-label{padding-bottom: 6px;}
.new-chip {--chip--color: #197335;--chip--border-color: #48E674;--chip--bg-color: #fff;}

.disable-checkbox-prompt-accordion {
  pointer-events: none;
}
.li-delete-button.positionRight{position: absolute;max-width: 300px;right: 15px;}
.motif-content-switcher .motif-button{background: #fff;color: #2E2E38;border-radius: 4px;}
.motif-content-switcher .motif-button.motif-active{background: #D7D7DC;}
.motif-content-switcher .motif-button:first-child{border-radius: 4px 0 0 4px}
.motif-content-switcher .motif-button:last-child{border-radius: 0 4px 4px 0}
.motif-checkbox-disabled .motif-checkbox-custom{pointer-events: none;}
.li-add-button{color: #2e2e38;
  background-color: transparent;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 4px;
  border: 1px solid #c3c3cb;
  display: flex;
  gap: 8px;
  padding: 10px 16px;
  background: #fff;height: 47px;}
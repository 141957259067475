.motif-carousel-container{width: 515px;}
.motif-carousel-container .motif-carousel-item{margin-bottom: 0;display: flex;align-self: center;}
.motif-carousel-container .motif-carousel-item .card-container{left: 16px;display: block;align-items: center;margin: 0;width: 470px;color: #2E2E38;background-color: #F9F9FA;border: 1px solid #C3C3CB;height: 40px;border-radius: 8px;min-height: inherit;padding: 0 16px;font-size: 14px;font-weight: 400;position: relative;}
.motif-carousel-container .motif-carousel-dot-indicator{display: none;}
.motif-carousel-container .motif-carousel-previous-btn, 
.motif-carousel-container .motif-carousel-next-btn{opacity: 1;background: transparent;}
.motif-carousel-slides-container{display: block;margin: 0;width: 100%;height: 100%;}
.motif-carousel-container .motif-carousel-previous-btn{left: -14px;}
.motif-carousel-container .card-container.knownIssues{color: #A40011;border-color: #FF735E;background-color: #FCF5F3;}
.motif-carousel-container .card-container.plannedMaintainance{color: #975100;border-color: #EE8300;background-color: #FCF5F0}
.rightPart .motif-icon-button:hover{background: transparent;}
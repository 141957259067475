.publish-genericModal {
    height: 460px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.00);
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 0px rgba(35, 35, 47, 0.06), 0px 16px 32px 0px rgba(35, 35, 47, 0.08);
    padding: 24px;
    box-sizing: border-box;
}

.publish-genericModal.motif-modal-header-text {
    color: var(--Neutrals-Light-theme-900, #2E2E38);
    font-family: 'EYInterstate', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.publish-genericModal .motif-modal-header .motif-modal-headline {
    padding: 0;

}


.publish-genericModal .motif-modal-body {
    display: block;
    padding: 0px;
    font-family: EYInterstate;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    margin-top: 10px;
}

.publish-genericModal .modalContent li {
    display: flex;
    font-family: 'EYInterstate', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #2E2E38;
    margin-bottom: 8px;

}

.publish-genericModal .modalContent li:before {
    content: "▸";
    font-size: 18px;
    font-weight: bold;
    color: #2E2E38;
}


.publish-genericModal .motif-modal-body ul,
ol {
    padding-left: 0;
    margin-left: 0;
}

#btnCancel {
    border-radius: 4px;
    border: 1px solid var(--Neutrals-Light-theme-300, #C3C3CB);
    background: var(--dev-only-transparent, rgba(0, 0, 0, 0.00));
}

#btnAcknowledge {

    border-radius: 4px;
    border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0.00));
    background: var(--Neutrals-Light-theme-900, #2E2E38);
}

.publish-motif-toast {
    position: absolute;
    width: 100%;
    left: inherit;
    right: 0;
    bottom: 20px;
    z-index: 1;
}

.publish-motif-toast .motif-toast-content {
    box-shadow: 0px 4px 8px 0px rgba(35, 35, 47, 0.06), 0px 16px 32px 0px rgba(35, 35, 47, 0.08);
    font-size: 14px;
    line-height: 20px;

}

.publish-motif-toast .motif-toast-text-container {
    font-weight: 400;
    padding: 16px 0;
    border-color: #2DB757;
}
#addTestPrompt .motif-input-component .motif-text-area:focus-visible,
#addTestPrompt .motif-input-component .motif-input:focus-visible{outline-offset: -2px;}
#addTestPrompt .motif-form-field{margin-bottom: 0;}
#addTestPrompt .motif-rich-text-editor .ql-editor{height: 350px;overflow-y: auto;}
#addTestPrompt .motif-modal-header .motif-modal-headline{padding-top: 0;padding-bottom: 0;}
#addTestPrompt .motif-modal-header{padding-bottom: 24px;}
#addTestPrompt .motif-modal-header > .motif-icon-button{height: 48px !important;width: 48px;}
#addTestPrompt .motif-modal-footer{padding: 24px 0;}
.motif-accordion-content-visible:not(.motif-metadata){border: 2px solid #0F69AE;}
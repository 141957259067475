/* Add this CSS to your stylesheet (e.g., App.css) */
.markdown-table table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .markdown-table th,
  .markdown-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .markdown-table th {
    text-align: left;
    background-color: #f2f2f2;
  }

  .markdown-hide {
    display: none;
  }
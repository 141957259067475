.gapbetweenicons div img,
.gapbetweenicons img {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background-color: transparent;
  margin: 2px;
  border-color: #c3c3cb;

  &:hover {
    background-color: #e6e6e9;
  }
}

.thumbsup,
.thumbsdown,
.thumbsupactive,
.thumbsdownactive {
  padding: 2px;
  width: 35px;
  height: 35px;
}

.thumbsupactive,
.thumbsdownactive {
  padding: 2px;
  width: 35px;
  height: 35px;
  background-color: #d7d7dc;
}
.gapbetweenicons {
  display: flex;
  gap: 4px;
  flex-direction: row;
  flex-wrap: nowrap;
}

.gapbetweenicons .motif-icon-button {
  height: 30px !important;
  width: 30px !important;
}

.active {
  background-color: #D7D7DC !important;
  border-color: #C3C3CB !important;
}

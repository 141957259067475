.selectfavoriteInput {
  align-self: stretch !important;
  background: white !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 8px !important;
  display: inline-flex !important;
  width: 100% !important;
}

.selectfavoriteInput {
  align-self: stretch !important;
  background: white !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 8px !important;
  display: inline-flex !important;
  width: 100% !important;
}

.eyd-select-div {
  display: flex;
  width: 40%;

  .motif-form-field {
    margin-bottom: unset;
  }
}

.Input-eyd-select {
  align-self: stretch !important;
  background: white !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 8px !important;
  /* display: inline-flex !important; */
  width: 80% !important;
}

.eyd-buttons-div {
  display: flex;
  width: 60%;
}

.right-panel-nav-container-row {
  width: 100%;
  height: 33px;
  /* padding-right: 37px; */
  left: 34px;
  top: 78px;
  position: absolute;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px #e6e6e9 solid;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}

.right-panel-nav-source-doc-container-explore {
  width: 100%;
  justify-content: space-around;
  align-items: center;
  display: inline-flex;
  gap: 24px;
}

.right-panel-nav-source-doc {
  color: #656579;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

.right-panel-doc-count {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  margin: 10px 0px 0px 10px;
}

.select-files {
  margin-left: 10px;
}

.select-file-type-title {
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

.search-icon {
  width: 20px;
  height: 20px;
  z-index: 999;
  position: relative;
  top: 39px;
  left: 14px;
}

.search-text-box {
  width: 94%;
  height: 44px;
}

.search-text-box input {
  padding-left: 40px !important;
}

.search-text-box input::placeholder {
  color: #656579;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.search-field {
  width: 100%;
  margin-left: 10px;
}

.checkbox-list-filetypes {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.checkbox-list-filetypes .motif-checkbox-label {
  color: #2e2e38;
  font-weight: 400;
}

.checkbox-list-filetypes .motif-checkbox {
  margin-top: 0px;
}

.warning-toast {
  width: 100%;
  margin-top: 15px;
}

.uploaded-docs-container {
  border: 1px solid lightgrey;
  border-radius: 8px;
  width: 100%;
  height: 80%;
  margin-top: 20px;
}

.checkbox-list-filetypes .motif-checkbox-label {
  margin-left: 3px;
  margin-right: 13px;
}

.source-doc-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  top: 122px;
  position: absolute;
  left: 34px;
}

.right-panel-doc-count-text {
  color: #444;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.right-panel-doc-count-digit {
  border-radius: 16px;
  border: 1px solid #d7d7dc;
  background: #fff;
  display: flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-weight: bold;
}

.right-panel-select-files-text {
  position: absolute;
  top: 185px;
  left: 34px;
  width: 100%;
  height: 70px;
  text-align: left;
  color: #656579;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.right-panel-select-files-dropdown {
  position: absolute;
  top: 210px;
  left: 20px;
  width: 100%;
  height: 70px;
  text-align: left;
  color: #656579;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.src-doc-drpdwn-body-container {
  position: relative;
}

.item-container {
  margin: 14px;
}

.motif-dropdown-menu:before {
  display: none;
}

.right-panel-select-files-dropdown>div>div.motif-dropdown-menu {
  left: 0px;
  top: 45px !important;
  width: 100%;
}

.right-panel-select-files-dropdown>div,
.right-panel-select-files-dropdown>div>div.motif-dropdown-trigger {
  width: 100%;
}

.right-panel-select-files-dropdown>div>div>button {
  text-align: left;
}

.src-doc-filter-type-chip-container {
  border-bottom: 1px #e6e6e9 solid;
  width: 309px;
  top: 25px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.src-doc-filter-type-chip-container-wrap {
  border-bottom: 1px #e6e6e9 solid;
  width: 309px;
  top: 25px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.src-doc-filter-type-blank-container {
  border-bottom: 1px #e6e6e9 solid;
  width: 309px;
  top: 45px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.src-doc-filter-type {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0);
  background: #f3f3f5;
  padding: 2px 6px 2px 8px;
  position: relative;
  top: -11px;
  width: 65px;
}

.src-doc-filter-chip-cancel-button {
  bottom: 11px;
  left: -1px;
  align-self: center;
  background: transparent;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
  height: 0px;
  margin-left: -0.14286rem;
  margin-right: 0;
  padding: 0.14286rem;
  position: relative;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  min-width: 0rem;
  border-radius: 50%;
}

.motif-button .motif-icon {
  height: 15px;
}

.right-panel-favorites {
  position: absolute;
  top: 140px;
  left: 34px;
  width: 100%;
  text-align: left;
  color: #656579;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 8px 16px 16px 16px;
  border-radius: 8px 8px 0 0;
  border: var(--border-width-thin, 1px) solid var(--border-colors-default-extra-B, #D7D7DC);
}

.right-panel-history {
  position: absolute;
  top: 121px;
  left: 34px;
  width: 100%;
  height: 70px;
  text-align: left;
  color: #656579;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.src-doc-dropdown-button {
  font: 400 14px/22px EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  display: flex;
  padding: 8px 10px 5px 10px;
  width: 100%;
  border: 1px solid rgba(225, 225, 230);
}

.src-doc-dropdown-button:hover {
  background-color: #fff;
}

.src-doc-dropdown-text {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  display: inline-block;
  margin-right: 28px;
  font: 400 14px/22px EYInterstate, "Arial Unicode MS", Arial, sans-serif;
}

.src-doc-drpdwn-icon {
  position: relative;
  margin-left: auto;
}

.src-doc-drpdwn-icon-with-type-selection {
  position: relative;
}

.motif-select-input {
  padding-top: 0px;
}

.right-panel-docs-container {
  top: 152px;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.right-panel-docs-container-with-noselection {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  overflow-y: auto;
  height: 453px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 15px;
  position: -webkit-sticky;
  position: sticky;
}

.right-panel-docs-container-with-noselection .right-panel-doc-checkbox {
  margin-top: 0px;
}

.no-doc-found-txt {
  color: #2e2e38;
  position: absolute;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  top: 53%;
  left: 28%;
}

.no-doc-instruction-txt {
  position: absolute;
  top: 57%;
  left: 17%;
  color: #656579;
  text-align: center;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  height: 36px;
}

.no-doc-button {
  position: absolute;
  top: 62%;
  /* left:8%; */
  padding-top: 8px;
  align-self: center;
  margin-left: 28%;
}

.right-panel-docs-container-with-more-chip {
  left: 34px;
  top: 275px;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  overflow-y: auto;
  height: 100%;
}

.right-panel-doc-checkbox {
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  align-items: center;
  height: auto;
}

.right-panel-doc-name {
  color: #2e2e38;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  text-decoration-line: underline;
  text-decoration-color: #7f7f91;
  display: inline-block;
  width: 100%;
}

.right-panel-doc-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  /* Add border bottom */
}

.right-panel-download-button {
  margin-left: 10px;
}

.right-panel-doc-name {
  border: none;
  /* Remove any border from .right-panel-doc-name */
}

.right-panel-active-card {
  padding-bottom: 9px;
  /* padding-left: 4px;
  padding-right: 4px; */
  background: rgba(0, 0, 0, 0);
  border-top-right-radius: 2px;
  /* overflow: hidden; */
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.right-panel-active-select {
  border-bottom: 2px #2e2e38 solid;

  .right-panel-nav-source-doc,
  .right-panel-container-favourate-tab {
    color: #2e2e38 !important;
  }
}

.public_source_document {
  width: 100%;
}

.public_source_document_icon {
  width: 49px;
  height: 49px;
  margin-top: 200px;
  position: relative;
  left: 40%;
}

.wrapper-public-section {
  margin-left: 76px;
}

.txt-public-search-enabled {
  color: #000;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  position: relative;
  left: 26%;
}

.txt-confidential-doc {
  color: #000;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
}

.motif-search-input-icon svg {
  display: none;
}

.ActiveConf {
  background: white;
  color: #2e2e38;
}

.ActivePublic {
  background: #e6e6e9;
  color: #2e2e38;
}

.confpublic {
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  word-wrap: break-word;
  border-radius: 5px;
}

.marginpublic {
  margin-left: 5px;
}

.favorites-prompts-list1 {
  width: 100%;
  height: 85%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  top: 58%;
  position: absolute;
  left: 34px;
  overflow-y: auto;
  border-radius: 0 0 8px 8px;
  border: 1px solid #E6E6E9;
  border-top: 0;
  padding: 16px;
  background: var(--Neutrals-Light-theme-50, #F3F3F5);
  margin-top: -2px;
}

.favorites-prompts-list2 {
  width: 100%;
  height: 85%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  top: 47%;
  position: absolute;
  left: 34px;
  overflow-y: scroll;
}

.favorites-prompts {
  width: 100%;
  background: var(--Neutrals-Light-theme-00-White, #fff);
  border-radius: 6px;
  border: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
}

.favorites-prompt-type {
  border-radius: 16px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0.00));
  background: var(--Purple-25, #F8F6FA);
}

.favorites-conversationprompt-confidential {
  text-align: center;
  color: #724BC3;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  position: relative;
  left: 2%;
}

.favorites-conversationprompt-public {
  text-align: center;
  color: #A04082;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  position: relative;
  left: 2%;
}

.favorites-prompts:hover {
  border-radius: 4px;
  border: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
  background: var(--Neutrals-Light-theme-50, #f3f3f5);
  cursor: pointer;
}

.favorites-prompts-content {
  width: 100%;
  display: flex;
  padding: 16px;
}

.favorites-prompts-header {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  width: 95%;
  /* min-width: 250px; */
}

.favorites-prompt-title {
  align-self: stretch;
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.favorites-prompt-question {
  align-self: stretch;
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  line-height: 20px;
  word-wrap: break-word;

  line-height: 1.5em;
  max-height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


.favorites-prompt-question>p {
  margin: 0px;
}

.favorites-prompt-question>ul,
.favorites-prompt-question>ol {
  margin: 0px;
  padding-left: 16px;
}

.favorites-prompt-question>ul>li,
.favorites-prompt-question>ol>li {
  margin: 0px;
  margin-bottom: unset;
}

.favorites-star {
  width: 24px;
  height: 24px;
  position: relative;
}

.favorites-star-icon {
  width: 16.78px;
  height: 16px;
  left: 4px;
  top: 4px;
  position: absolute;
  /* background: #2e2e38; */
}

.favorites-footer {
  height: 18px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: flex;
  width: 100%;
  /* align-items: flex-end; */
}

.favorites-footer-date {
  display: flex;
  padding: 10px 10px 10px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-top: var(--border-width-thin, 1px) solid var(--Neutrals-Light-theme-100, #E6E6E9);

  color: var(--Neutrals-Light-theme-700, #656579);

  font-family: EYInterstate;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
}

.favorites-broken-link {
  width: 24px;
  height: 24px;
  position: relative;
}

.favorites-broken-link-icon {
  width: 16.78px;
  height: 16px;
  left: 4px;
  top: 4px;
  position: absolute;
  /* background: #2e2e38; */
}

.prompt-type-button {
  flex: 1 1 0;
  height: 36px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #d7d7dc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  border: 1px #fff solid !important;
  justify-content: center;
  align-items: center;
  display: flex;
}

.CentralActive {
  border: 1px solid var(--Neutrals-Light-theme-300, #fff) !important;
  background-color: var(--Neutrals-Light-theme-200, #fff) !important;
  border-bottom: 2px solid #000 !important;
}

#badgepopuptext {
  text-align: justify;
}

#divspace {
  margin-bottom: 0.4rem;
}

.BadgeFooterButtonContent {
  width: 100%;
  height: 44px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
}

.motif-modal-confidential-body {
  /* width: 980px; */
  height: 316px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
}

#motifModalConfidential {
  max-width: 500px !important;
  max-height: 600px !important;
}

.motif-modal-public {
  max-width: 500px !important;
  max-height: 600px !important;
}

.motif-modal-confidential-row-Container {
  flex: 1 1 0;
  height: 36px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  overflow: hidden;
  border: 1px #c3c3cb solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.motif-modal-confidential-row-fileIcon {
  width: 20px;
  height: 20px;
  position: relative;
}

.motif-modal-confidential-row-text {
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400px;
  word-wrap: break-word;
}

.motif-modal-confidential-row {
  gap: 16px;
  display: grid;
}

.motif-modal-public-body {
  padding: 24px;
  background: white;
  box-shadow: 0px 16px 32px rgba(35, 35, 47, 0.08);
  border-radius: 8px;
  border: 1px rgba(0, 0, 0, 0) solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
}

.motif-modal-public-body-span {
  color: #2e2e38;
  font-size: 16px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300px;
  text-decoration: underline;
  line-height: 24px;
  word-wrap: break-word;
}

.divExploreHeader {
  width: 1270px;
  height: 36px;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: inline-flex;
}

.divexploretext {
  color: #2e2e38;
  font-size: 28px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400px;
  word-wrap: break-word;
  margin-top: 1%;
  margin-bottom: 2%;
}

.divRightPanel {
  width: 367px;
  height: 1019px;
  position: relative;
  float: right;
  bottom: 93px;
}

.divFrame123 {
  width: 900px;
  height: 786px;
  position: relative;
  border-radius: 16px;
  border: 1px #d7d7dc solid;
}

.frameexplore1 {
  /* padding: 16px;
left: 13px; */
  top: 80px;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.divImgcontainer1 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.imgcontainerText {
  width: 160px;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}

.imgText1 {
  color: #2e2e38;
  font-size: 16px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 700;
  line-height: 18px;
  word-wrap: break-word;
}

.promptboxContainer {
  width: 736px;
  height: 184px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
}

.promptboxtextalign {
  width: 240px;
  background: white;
  border-radius: 6px;
  overflow: hidden;
  border: 1px #d7d7dc solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.proptboxtext {
  text-align: center;
  color: #656579;
  font-size: 14;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  word-wrap: break-word;
  padding-top: 5px;
}

.promptArea1 {
  width: 946px;
  height: 124px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.prompttypeButtons {
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.promptQuestionAlign {
  align-self: stretch;
  height: 56px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.asquestiondiv {
  position: absolute;
  margin-left: 810px;
  margin-top: 10px;
  cursor: "pointer";
}

.asksendImgalign {
  border-radius: 4px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  background: var(--Neutrals-Light-theme-500, #9897a6);
  height: 27px;
}

.promptresponseNote {
  color: #656579;
  font-size: 12px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  word-wrap: break-word;
  position: relative;
  left: 13px;
}

.explore-data-header {
  width: 100%;
  height: 36px;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: inline-flex;
}

.explore-data-text {
  color: #2e2e38;
  font-size: 28px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400px;
  word-wrap: break-word;
  margin-top: 1%;
  margin-bottom: 2%;
}

.explore-data-right-panel {
  padding-right: 15px;
  width: 35%;
  height: 780px;
  /* float: right;
  position: relative; */
}

.explore-data-container {
  width: 70%;
  height: 786px;
  /* position: absolute; */
  border-radius: 16px;
  border: 1px #d7d7dc solid;
  overflow-x: hidden;
  overflow-y: scroll;
}

.explore-data-container-child {
  padding: 16px;
  /* left: 23px; */
  top: 80px;
  /* position: absolute; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  width: 100%;
}

.explore-data-container-image-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.explore-data-container-image-container-child {
  height: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}

.explore-data-container-image-container-text {
  color: #2e2e38;
  font-size: 16px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 700;
  line-height: 18px;
  word-wrap: break-word;
}

.explore-data-container-promptbox-container {
  width: 736px;
  height: 184px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  /* display: inline; */
}

.explore-data-container-promptbox-container-child {
  width: 240px;
  background: white;
  border-radius: 6px;
  overflow: hidden;
  border: 1px #d7d7dc solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-table;
  height: 80px;
  margin-top: 12px;
  margin-left: 5px;
}

.explore-data-container-promptbox-container-text {
  text-align: center;
  color: #656579;
  font-size: 14;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  word-wrap: break-word;
  padding-top: 30px;
}

.explore-data-container-promptarea-container {
  /* width: 946px; */
  /* height: 124px; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  /* position: relative; */
  /* left: 18px; */
  padding-top: 10px;
}


.explore-data-container-promptarea-buttons {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  width: 100%;
  position: relative;
  margin-left: 20px;
}

.explore-data-container-prompt-question-container {
  align-self: stretch;
  /* height: 56px; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
  position: relative;
  padding-left: 1%;
  width: 100%;

  .motif-tooltip-wrapper {
    width: 100% !important;
    display: flex !important;
  }

  .motif-tooltip-trigger-wrap {
    width: 100% !important;
  }
}

.explore-data-container-prompt-question-container .motif-input {
  padding-right: 10% !important;
}

.explore-data-container-prompt-question-container-child {
  position: absolute;
  margin-left: 96%;
  margin-top: 10px;
  cursor: pointer;
}

.explore-data-container-sendimage {
  border-radius: 4px;
  /* border: 1px solid rgba(0, 0, 0, 0);
  background: #9897a6;
  height: 27px;
  position: relative; */
  bottom: 7px;
  right: 98px;
}

.explore-data-container-response-note {
  color: #656579;
  font-size: 12px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  word-wrap: break-word;
  position: relative;
  width: 94%;
  margin-left: 20px;
}

.explore-prompt-question-container {
  width: 70%;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  float: right;
}

.explore-prompt-question-container-child {
  height: 20px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  width: 100%;
}

.explore-prompt-question-container-text {
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 20;
  word-wrap: break-word;
}

.explore-prompt-question-container-date {
  /* width: 557px; */
  color: #656579;
  font-size: 12px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  line-height: 18px;
  word-wrap: break-word;
}

.explore-prompt-question-header {
  align-self: stretch;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #2e2e38;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.explore-prompt-question-text {
  flex: 1 1 0;
  color: #f9f9fa;
  font-size: 16px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  word-wrap: break-word;
  word-break: break-word;
}

.explore-prompt-question-text>p,
.explore-prompt-question-text>ul,
.explore-prompt-question-text>ol,
.explore-prompt-question-text>li {
  flex: 1 1 0;
  color: #f9f9fa;
  font-size: 16px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  word-wrap: break-word;
  word-break: break-word;
  margin: 0px;
}

.explore-prompt-question-edit-promt-container {
  width: 100%;
  height: 36px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  margin-left: auto;
}

.explore-prompt-question-response-doc-note {
  width: 100%;
  height: 64px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  padding-right: 50px;
  position: relative;
  left: 40px;
}

.explore-prompt-question-response-doc-text {
  width: 922;
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400px;
  word-wrap: break-word;
}

.docContaineralign {
  align-self: stretch;
  justify-content: flex-start;
  gap: 15px;
  display: inline-flex;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  overflow: hidden;
  border: 1px #c3c3cb solid;
  align-items: center;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400px;
}

.contentfamily {
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  word-wrap: break-word;
  font-size: 14px;
}

.explore-prompt-question-response-doc-container {
  flex: 1 1 0;
  height: 36px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  overflow: hidden;
  border: 1px #c3c3cb solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  width: 100%;
}

.explore-prompt-question-response-doc-fileicon {
  width: 20px;
  height: 20px;
  position: relative;
}

.explore-prompt-question-response-icon {
  padding: 6px;
  background: #656579;
  border-radius: 32px;
  border: 1px #656579 solid;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  margin-top: 0.5rem;
}

.explore-prompt-copy-response-icon {
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  gap: 4px;
}

.explore-prompt-question-response-container {
  width: 90%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  padding-top: 20px;
  position: relative;
  left: 50px;
  display: grid;
  grid-template-columns: 0.05fr 1fr;
  grid-gap: 15px;
}

.explore-prompt-question-response-container-child {
  align-self: stretch;
  padding-left: 23px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #f3f3f5;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  /* position: relative; */
  /* left: 44px; */
  /* bottom: 39px; */
  width: 100%;
  margin-top: 5px;
}

.explore-prompt-question-response {
  align-self: stretch;
  color: #2e2e38;
  font-size: 16px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300px;
  word-wrap: break-word;
}

.right-panel-container {
  width: 90%;
  height: 80%;
  position: relative;
  bottom: 121px;
}

.right-panel-container-newprompt-container {
  width: 100%;
  left: 32px;
  top: 24px;
  position: absolute;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.right-panel-container-newprompt-heder {
  flex: 1 1 0;
  height: 36px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #2e2e38;
  border-radius: 4px;
  overflow: hidden;
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.right-panel-container-newprompt-text {
  color: #f3f3f5;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

.right-panel-container-favourate-tab {
  color: #656579;
  font-size: 14px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

/* .right-panel-container-favourate-tab-div {
  width: 68px;
} */

/* .right-panel-container-history-tab-div {
  width: 56px;
} */

.explore-prompt-copy-response-icon>button {
  width: 10px;
  background: transparent;
  border: none;
  /* left: 66%; */
  /* bottom: 46%; */
  position: relative;
}

/* Add all the css before this, this is for chat window spinner*/
.dot-typing {
  position: relative;
  left: -9975px;
  bottom: -18px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  animation: dot-typing 1.5s infinite linear;
}

.aligned {
  display: flex;
  align-items: center;
}

.spaninfo {
  padding: 10px;
}

.linktext {
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  text-decoration-line: none;
  color: #0f69ae;
  margin-left: 5px;
  margin-right: 5px;
}

.exploreDataLinkText {
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  text-decoration-line: none;
  color: #0f69ae;
}

.transferToast {
  margin-left: 7.4rem !important;
  width: 54.7% !important;
}

.transferInstantToast {
  margin-left: 0.4rem;
  width: 62% !important;
}

.responseToast {
  margin-left: 7.4rem !important;
  width: 54.8% !important;
  bottom: 32px;
}

.responseMessageToast {
  margin-left: 7.4rem !important;
  width: 54.8% !important;
  bottom: 32px;
}

.breadcrum-project {
  font-size: 12px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: bold;
  line-height: 18px;
  word-wrap: break-word;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }

  16.667% {
    box-shadow: 9984px -10px 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }

  33.333% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }

  50% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px -10px 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }

  66.667% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }

  83.333% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px -10px 0 0 #9880ff;
  }

  100% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }
}

.explore-rp-search-icon {
  width: 20px;
  height: 20px;
  z-index: 1;
  position: absolute;
  left: 14px;
  top: 13px;
}

.explore-rp-search-textbox input {
  padding-left: 40px !important;
}

.explore-rp-search-textbox input::placeholder {
  color: #656579;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.document-not-available {
  color: var(--Neutrals-Light-theme-500, #9897a6);
}

.noaction-document-not-available {
  pointer-events: none;
}

#selectfavoriteType .motif-select-clean-value {
  visibility: hidden;
}

#selectfavoriteType .motif-select-search-icon {
  visibility: hidden;
}

.dropdown-title {
  margin-bottom: 10px;
  margin-top: 16px;
}

.right-panel-docs-container-favourite {
  width: 100%;
  left: 34px;
  top: 42%;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  display: flex;
  overflow-y: auto;
  height: 100%;
}

.explore-data-left-right-panel {
  display: flex;
  margin-top: 24px;
}

.explore-prompt-question-edit {
  margin-left: auto;
}

.explore-prompt-question-action-items {
  width: 100%;
}

.explore-prompt-phase-parent {
  width: 100%;
}

.toast-Width {
  top: 18%;
  position: absolute;
  width: 90%;
  left: 9%;
}

#ToastClose .motif-toast-close-button {
  display: none;
}

.explore-data-container-prompt-question-container-child>span>span.motif-tooltip-trigger-wrap {
  position: relative;
  bottom: 7px;
  right: 98px;
}

#Toastwarning.motif-toast button {
  display: none;
}

#favourite-button-type.motif-button-group {
  cursor: not-allowed;
}

#favourite-history-search .explore-rp-search-textbox {
  margin-top: 16px;
}

.flagEdited {
  color: #656579;
  font-size: 12px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300;
  line-height: 18px;
}

.explore-prompt-response-ai-container {
  gap: 4px;
  display: flex;
  position: relative;
  width: 95%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.explore-prompt-response-ai-text {
  color: #b26100;
  font-size: 1rem;
  font-family: EYInterstate;
  font-weight: 300;
  word-wrap: break-word;
}

.right-panel-download {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

/* .explore-prompt-response-actions {
  margin-top: 8px;
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
  align-items: center;
  border: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  width: 350px;
} */

.explore-prompt-response-actions {
  margin-top: 8px;
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
  align-items: center;
  border: none;
  right: 0;
  padding: 0;
  width: 350px;
  position: relative;
  bottom: 50px;
  left: 135px;
}

.explore-prompt-response-actions {
  margin-left: 7px;
  margin-top: 8px;
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
  align-items: center;
  border: none;
  right: 0;
  padding: 0;
  width: 350px;
  position: relative;
  bottom: 46px;
  left: 131px;
}

.explore-prompt-response-actions-donot-show {
  display: none;
}

.explore-prompt-response-actions-instantinsights {
  margin-top: 4px;
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
  align-items: center;
  border: none;
  right: 0;
  padding: 0;
  width: 350px;
  position: relative;
  bottom: 2px;
  left: 0;
}

.viewsourcedoc-alignment-instantinsight {
  position: relative;
  bottom: 2px;
}

.explore-prompt-response-actions-originalresponse {
  margin-top: 8px;
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
  align-items: center;
  border: none;
  right: 0;
  padding: 0;
  width: 350px;
  position: relative;
  bottom: 46px;
  left: 96px;
}

.viewdocTransferOriginal {
  left: 138px !important;
}

.viewdocTransfer {
  left: 173px !important;
}

#explore-view-source-doc-icon span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#explore-view-source-doc-icon,
#explore-view-source-doc-icon-instantinsights {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 2px 5px 2px 2px;
  text-align: right;
  border: 2px solid #d7d7dc;
}

#explore-view-source-doc-icon-instantinsights span {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.explore-instant-container {
  width: 70%;
  height: 786px;
  border-radius: 16px;
  border: 1px #d7d7dc solid;
}

.explore-instant-container-child {
  width: 100%;
  height: 750px;
}

.explore-instant-prompt-header {
  height: 50px;
  display: flex;
  padding: 12px 0px 0px 20px;
  border-bottom: 1px solid #d7d7dc;
}

.explore-instant-prompt-text {
  color: #000000;
  font-family: EYInterstate;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.motif-icon-button-instant {
  margin-left: auto;
  margin-right: 1%;
  height: 70%;
  border: groove;
}

.explore-instant-prompt-response {
  width: 100%;
  height: 720px;
  padding: 10px 10px 10px 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.no-doc-found-image {
  position: absolute;
  top: 33%;
  left: 27%;
}

.explore-data-container-prompt-question-container {
  .btnSendPrompt {
    /*height: 36px !important;
    width: 36px !important;*/
    color: #FFF !important;
    position: absolute;
    right: 0.75rem;
    bottom: 0.2rem;
    z-index: 1;
    min-width: unset;
    padding: 4px;
    height: 32px !important;
    width: 32px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: var(--btn-border-radius, 4px) !important;
    background: var(--btn-primary-bg-color, #2E2E38) !important;
  }

  .btnExpandPrompt {
    color: #FFF !important;
    border-radius: 4px !important;
    position: absolute;
    right: 4rem;
    bottom: 0.6rem;
    z-index: 1;
  }

  .btnSendPrompt:disabled,
  .btnSendPrompt.motif-disabled-state {
    background-color: #9897A6 !important;
  }

  .iconSendPrompt {
    vertical-align: middle;
  }

  .motif-input {
    height: 56px;
    font-family: EYInterstate !important;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
}

.expand-modal-container {

  display: flex;
  gap: 24px;
  width: 100%;
  height: 510px;
  padding: 24px;
  overflow-y: unset;
  max-height: none;

  .expand-modal-header {
    font-family: EYInterstate;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    border-bottom: 1px solid #E6E6E9;
    /* padding: 24px 24px 0px 24px; */
    border: unset;
  }



  .expand-modal-body {
    padding: unset;
  }

  .expand-text-editor {
    width: auto;
    height: 100%;
  }

  .motif-rich-text-editor .ql-editor {
    min-height: auto;
    max-height: 478px;
    overflow-y: auto;
  }
}

#modalExpandPrompt {
  height: 100%;
}

#modalExpandPrompt>div {
  height: 100%;
}

#expand-prompt-editor {
  .ql-container {
    height: 329px;
  }
}

.expand-modal-container>.motif-modal-header .motif-modal-headline {
  padding: unset;
  color: var(--Neutrals-Light-theme-900, #2E2E38);

  /* Subtitle/Regular */
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
}

.add-favourite-modal-header {
  border: none !important;
  margin-top: 24px;
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.favorite-rp-help-icon {
  width: 1.714rem;
  height: 17px;
  z-index: 1;
  position: absolute;
  left: 94%;
  top: 30%;
}

#favtitledivspace {
  margin-top: 1rem;
}

.toolhelp>.motif-tooltip-left {
  margin-right: 3.4rem;
  inset: 5px 0px auto auto !important;

  .motif-tooltip-content-children {
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #F9F9FA;
    white-space: pre-wrap;
    margin-right: 0px;
  }
}

#favtitledet>div {
  display: flex !important;
}

.favtitledet .motif-form-field {
  margin-bottom: 20%;
}

.inputtitle {
  margin-left: 2px !important;
  width: 99% !important;
}

#favouritepopupmodal {
  font-family: EY_Interstate;
  padding: 24px;
  width: 70% !important;

  .motif-modal-headline {
    padding: 0px;
  }

  .motif-modal-body {
    padding: 20px 0px;
    min-height: 300px;
  }

  .motif-modal-footer {
    padding: 0px;
  }

}

.btn-collapse {
  width: 48px !important;
  height: 48px !important;
  align-self: center;
  padding-top: 3px !important;

  &:hover {
    background-color: var(--btn-icon-hover--bg-color, #e6e6e9) !important;
    border-radius: 0.3rem !important;
  }
}

.tooltop-copy,
.tooltop-transfer,
.tooltop-edit,
.tooltop-like,
.tooltop-dislike {
  .motif-tooltip-bottom {
    inset: 8.9px auto auto 0.1rem !important;
  }
}

.motif-rich-text-editor .ql-editor {
  min-height: 24px;
  max-height: 300px;
  overflow-y: auto;
  overflow-wrap: anywhere;
}

#expandpromptModal {

  .motif-rich-text-editor .ql-snow.ql-toolbar button svg {
    width: 2rem;
    height: 2rem;
  }

  .motif-rich-text-editor .ql-snow.ql-toolbar button {
    height: 2.2rem;
    width: 2.2rem;
  }

  .motif-modal-body {
    overflow: unset;
  }
}


#explore-prompt-box-editor {
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid #C3C3CB;

  .ql-toolbar {
    display: flex;
    justify-content: flex-end;
    padding-right: 65px;
  }

  .ql-snow {
    border: 0px;
  }
}

#explore-prompt-box-editor>.ql-toolbar {
  border-radius: var(--spacing-0px, 0px) var(--spacing-0px, 0px) 4px 4px;
  background: var(--Neutrals-Light-theme-50, #F3F3F5);
}

#explore-prompt-box-editor>.ql-toolbar>.ql-formats {
  background: var(--Neutrals-Light-theme-50, #F3F3F5);
  display: flex;
  gap: 8px;
}

.tooltip-bulleted-numbered-top {
  align-items: flex-start;
  background-color: var(--tooltip--bg-color);
  color: var(--tooltip--color);
  display: flex;
  font-size: 0.8571428571rem;
  font-weight: 300;
  justify-content: space-between;
  line-height: 1.4285714286rem;
  padding: 0.5714285714rem;
  position: absolute;
  text-align: left;
  z-index: 1000;
  border-radius: 5px;
  place-content: center;
}

.tooltip-bulleted-numbered-top::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid var(--tooltip--bg-color);
}

.tooltip-bulleted-numbered-bottom {
  align-items: flex-start;
  background-color: var(--tooltip--bg-color);
  color: var(--tooltip--color);
  display: flex;
  font-size: 0.8571428571rem;
  font-weight: 300;
  justify-content: space-between;
  line-height: 1.4285714286rem;
  padding: 0.5714285714rem;
  position: absolute;
  text-align: left;
  z-index: 1000;
  border-radius: 5px;
  place-content: center;
}

.tooltip-bulleted-numbered-bottom::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid var(--tooltip--bg-color);
}

.ql-formats>button:disabled {
  pointer-events: none;
}

.tooltipWithoutCloseCenterText {
  .motif-tooltip-content-children {
    margin-right: 0;
  }
}

.tooltipWithoutClose {
  .motif-tooltip-content-children {
    margin-right: 3px;
  }
}

.tooltipExpandBtn {
  .motif-tooltip.motif-tooltip-top {
    margin-bottom: 6px;
  }
}

.sendBtnTooltip {
  .motif-tooltip.motif-tooltip-top {
    margin-bottom: 1.1rem;
  }
}

.sendTooltipContent1 {
  font-family: EYInterstate;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #F9F9FA;
}

.sendTooltipContent2 {
  font-family: EYInterstate;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #9897A6;
}

.custom-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  top: 35px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid var(--tooltip--bg-color);
  ;
}

/* delete conversation popup css  */

.DeleteConversationPopup {
  padding: 24px;
}

.DeleteConversation-header {
  border-style: none;
  padding-bottom: 24px;
}

.DeleteConversation-header .motif-modal-headline {
  padding: 0px;
}

.DeleteConversation-icon {
  padding: 12px;
  width: 48px;
  height: 48px;
  border-radius: var(--icon-featured-border-radius, 200px);
  background: var(--icon-featured-bg-color, #F3F3F5);
}

.DeleteConversation-header>.motif-icon-button {
  height: 48px !important;
  width: 48px;
  border-radius: var(--btn-border-radius, 4px);
}

.DeleteConversation-title {
  font-size: var(--font-size-18, 18.004px);
  font-style: normal;
  line-height: var(--line-height-28, 28px);
}

.DeleteConversation-body {
  padding: 0%;
  padding-bottom: 24px;
}

.DeleteConversationPopup-innerbody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  line-height: 32px;
  font-family: var(--font-family-primary, EYInterstate);
  font-size: 16px;
}

.DeleteConversation-footer {
  flex-direction: row-reverse;
  align-items: flex-end;
  padding: 0;
}

.DeleteConversation-footer button {
  margin-left: 10px;
  font-size: 18px;
}

.DeleteSuccess-toast {
  position: fixed;
  bottom: 30px;
  max-width: 350px
}

.DeleteSuccess-toast .motif-toast-text-container {
  font-size: 14px;
  font-weight: 400;
  font-family: 'ey_interstate';
}

.prompt-toast {
  padding: 8px;
  position: absolute;
  left: 42px !important;
  bottom: calc(51vh - 500px);
  border-radius: var(--toast-border-radius, 8px);
  border: var(--border-width-thin, 1px) solid var(--border-colors-default-secondary, #C3C3CB);
  background: var(--toast-info-bg-color, #F9F9FA);
  box-shadow: 0px 4px 8px 0px rgba(35, 35, 47, 0.06), 0px 16px 32px 0px rgba(35, 35, 47, 0.08);
}

.no-scope-found-txt {
  color: #2e2e38;
  position: absolute;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  top: 60%;
  left: 16%;
}

.personal-no-scope {
  left: 30% !important;
}

.no-scope-instruction-txt {
  position: absolute;
  top: 65%;
  left: 18%;
  color: #656579;
  text-align: center;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  height: 36px;
}

.personal-no-scope-instruction {
  left: 26% !important;
}

.PromptLibraryList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.PromptLibraryCard {
  border: 1px solid #D7D7DC;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 6px;
  background: #fff;
}

.PromptLibraryTitle {
  color: #2e2e38;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 16px;
}

.PromptLibraryButtons {
  border-top: 1px solid #e6e6e9;
  padding: 4px;
  display: flex;
  justify-content: end;
  gap: 8px;
}

.PromptLibraryExpand {
  width: 36px !important;
  height: 36px !important;
}

.PromptLibraryIconBorder {
  width: 36px !important;
  height: 36px !important;
  border-radius: var(--btn-border-radius, 4px) !important;
  border: var(--border-width-thin, 1px) solid var(--border-colors-default-secondary, #C3C3CB) !important;
}

.PromptLibrary {
  width: 36px;
  height: 36px;
  background-color: #2E2E38;
}

.hide {
  display: none;
}

.personal-search {
  margin-top: 20px;
}

.personal-tab-container {
  display: flex;
  flex-direction: column;
}

.personal-tab {
  width: 100%;
  height: 530px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  top: 45%;
  position: absolute;
  left: 34px;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid #E6E6E9;
  padding: 16px;
  border-top: 0;
  border-bottom: 0;
  border-radius: 0;
  margin-top: -1px;
}

.add-new-prompt {
  width: 100%;
  height: 10%;
  top: 130%;
  display: inline-flex;
  position: absolute;
  padding: 16px;
  left: 34px !important;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  border-radius: 0 0 8px 8px;
  border: 1px solid #E6E6E9;
  margin-top: -2px;
}

.PromptLibraryUseprompt {
  width: 36px !important;
  height: 36px !important;
  background-color: #2E2E38 !important;
  border-radius: 4px !important;
}

/* prompt modal popup css  */
.PromptModal {
  padding: 24px;
}

.PromptModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.PromptModalHeader-title {
  flex: 1 0 0;
  color: var(--modal-header-color, #2E2E38);

  /* Subtitle/Regular */
  font-family: var(--font-family-primary, EYInterstate);
  font-size: var(--font-size-18, 18.004px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--line-height-28, 28px);
  /* 155.521% */
}

.PromptCollapseIcon {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 14px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  border-radius: var(--btn-border-radius, 4px);

  &:hover {
    background-color: var(--Neutrals-Light-theme-200, #D7D7DC);
  }
}

.PromptModalBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.PromptModalBody-Text {
  white-space: pre-line;
  color: var(--modal-body-paragraph-color, #2E2E38);
  font-family: var(--font-family-primary, EYInterstate);
  font-size: var(--font-size-16, 16.002px);
  font-style: normal;
  font-weight: var(--font-weight-light, 300);
  line-height: var(--line-height-24, 23.996px);

  p+* {
    margin-left: 25px !important;
  }
}

.PromptModalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
  align-self: stretch;
  padding: 0px !important;
}

.BookmarkFill,
.EditPencil,
.IconRight {
  display: flex;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--btn-border-radius, 4px);
  border: var(--border-width-thin, 1px) solid var(--border-colors-default-secondary, #C3C3CB);

  &:hover {
    background-color: var(--Neutrals-Light-theme-200, #D7D7DC);
  }
}

.right-icon-background {
  background: var(--btn-primary-bg-color, #2E2E38);

  &:hover {
    background-color: var(--Neutrals-Light-theme-200, #444);
  }
}

.add-new-prompt-bgcolor {
 border-radius: var(--btn-border-radius, 4px);
background: var(--btn-primary-bg-color, #2E2E38);
}

.prompt-tab-container-bgcolor {
  background: var(--theme-neutral-25, #F9F9FA);
}

.add-Prompt-Toast-Position {
  display: flex;
  width: 304px;
  padding: 6px;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  left: 42px !important;
  bottom: 69px;
  border-radius: 8px;
  border: 1px solid var(--Green-300, #2DB757);
  background: var(--Green-25, #E7FCE8);
  bottom: calc(49vh - 500px) !important;
  /* Shadows/Light theme/Elevation 05 */
  box-shadow: 0px 4px 8px 0px rgba(35, 35, 47, 0.06), 0px 16px 32px 0px rgba(35, 35, 47, 0.08);
}

.motif-toast-body {
  align-self: stretch;
  color: var(--Green-600, #197335);
  /* Body 2/Regular */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.add-prompt-toast-icon {
  margin-top: -17px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

#add-personal-prompt-ToastClose .motif-toast-close-button {
  margin-top: -17px;
  width: 20px;
  height: 20px;
}

.active-button {
  border: 1px solid var(--Neutrals-Light-theme-300, #c3c3cb) !important;
  background-color: var(--Neutrals-Light-theme-200, #d7d7dc) !important;
}

.add-edit-prompt-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.add-edit-prompt-modal-header-title {
  color: var(--Neutrals-Light-theme-900, #2E2E38);
  /* Subtitle/Regular */
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
}

.add-edit-title-prompt {
  margin-top: 16px;
  margin-bottom: 0;
}
.add-new-personal-prompt .motif-form-field {
  margin-bottom: 0;
}
.add-new-prmpt-plus-icon{
  margin-right: 8px;
}

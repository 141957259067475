.closeProjText {
    color: #2E2E38;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

#closeProjectLines {
    text-align: justify;
    padding-top: 24px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
}

.closeSymbolContainer {
    border-radius: 24px;
    background: #FCF5F3;
    width: 48px;
    height: 48px;
}

.warningCircle {
    padding-left: 12px;
    padding-top: 12px
}

.btnConfirm {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.00);
    background: #EA011D;
    width: 147px;
    height: 48px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    position: relative;
    left: 69%;
    bottom: 21px;
}

.btnCancel {
    width: 92px;
    height: 52px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    border-radius: 4px;
    position: relative;
    bottom: 79px;
    left: 45%;
}

.modalFooter {
    height: 48px;
    margin-top: 24px;
    padding-right: 0px;
}

.textISConfirmModalBody {
    font-family: var(--primary-font);
    font-size: 16px;
    font-weight: var(--weight-light);
    line-height: 24px;
    padding-bottom: 32px;
}

.modalDiscardIncompleteStructure {
    .ii-acknowledgement-header-icon-div {
        background-color: #FCF5F3;
    }
    .motif-button {
        font-size: 18px;
        font-weight: normal;
        line-height: 28px;
    }
}
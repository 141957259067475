.adminDashboardDetails {
    width: auto;
    height: auto;
    background: white;
    display: flex;
    flex-direction: column; 
    overflow: hidden;
  }

.ProjectDashboardPageContainerAdmin {
  display: flex;
  width: 99%;
  flex-direction: column;
  gap: 16px;
}

.adminDashboardHeaderNav {
    display: flex;
    height: 56px;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-bottom: 1px solid #e6e6e9;
    margin-left: 4px;
    border-bottom: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
    background: var(--Neutrals-Light-theme-00-White, #fff);
    padding: 0 16px 0 0px, 10px;
}
.adminDashboardNavDiv {
    background-color: white;
    margin: 0;
    width: 100%;
    display: flex;
  }
.adminDashboardLogoAndMenu {
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    display: flex;
  }
 #adminDashNav .motif-vertical-navigation-menu{
  margin-top: 24px;
 } 

 .adminDashboardLogoButton {
  width: 44px;
  height: 44px;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

 .adminDashboardLogoButtonBase {
  padding: 12px;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  overflow: hidden;
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
  transition: background-color 0.3s;
  cursor: pointer;
}

.adminDashboardLogoButtonBase:hover {
  background-color: #D7D7DC;
}

.adminDashboardActiveMenu {
  background: #E6E6E9;
  border-radius: 4px;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.adminDashboardPara{
  display: flex;
  padding: 8px 12px 8px 14px;
  align-items: center;
  gap: 105px;
  flex: 1 0 0;
}
.motif-vertical-navigation-menuitem-portal{
  padding-left: 1% !important;
  width: 12% !important;
}

#AdminDashboardProfileDropdown {
  box-shadow: 0px 8px 16px 0px #23232F14;
  border-radius: 4px;
  border: 1px solid  #E6E6E9;
  min-width: 275px;
  min-height: 114px;
  margin-left: -16px;
  margin-top: 5px;
}

.profileImageContainer {
  width: 60px;
  float: right;
  display: flex;
  padding: 6px 10px 6px 0px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.dropdownContainer {
  position: absolute;
  z-index: 1000;
  display: block;
}

.Avatar {
  &:focus, &:hover {
    filter: brightness(95%);
  }
  border-radius: 200px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.Avatar[aria-expanded="true"] {
  cursor: default;
  filter: brightness(90%);
}

.userAvatar {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 48px;
}

.adminDashlogoutcontainer {
  &:hover {
    background-color: #F3F3F5;
  }
  margin: 4px 0;
}

.adminDashuserguidecontainer {
  &:hover {
    background-color: #F3F3F5;
  }
  margin: 4px 0;
}

 .adminDashuserAvatarUserMenuItem {
   &:focus,
   &:hover {
     background-color: #F3F3F5;
   }
 }

  .userguide{
    right: 54%;
    top: 16%;
    text-decoration: none;
    margin-left: 8px;
  }
  
  .userguideimg{
    top: 16%;
    left :19%;
    vertical-align: middle;
  }
  
  .logoutimg{
    top: 16%;
    left :19%;
    vertical-align: middle;
  }

  .logout{
    right: 54%;
    top: 16%;
    text-decoration: none;
    margin-left: 8px;
  }

  .adminDashuserAvatarUserMenuItem {
    text-align: left !important;
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #2E2E38 !important;
    height: 56px !important;
    transition: background-color 0.3s;
  }
  
  .userAvatarEmailMenuItem {
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    color: #9897A6;
  }
  
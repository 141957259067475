.response-title-wrapper {
    .response-title {
        font-family: EYInterstate;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .response-date {
        font-family: EYInterstate;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        color: #656579;
    }
}

.response-top-actions-wrapper {
    .btnViewSourceDocs {
        float: right;
    }
}

.response-wrapper {
    border: 1px solid #D7D7DC;
    margin-top: 24px;
    border-radius: 6px;

    .response-content-title-wrapper {
        padding: 20px;
        border-bottom: 1px solid #D7D7DC;
        min-height: 90px;

        .response-content-title {
            font-family: EYInterstate;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        .response-content-sub-title {
            font-family: EYInterstate;
            font-size: 12px;
            font-weight: 300;
            line-height: 20px;
            color: #656579;
        }

        .motif-button {
            border: none;
            padding: 0px;

            &:hover {
                background-color: #FFF;
            }
        }

        .motif-dropdown-item {
            cursor: default;
        }

        .iconArrowDropdown {
            margin-left: 5px;
        }

        .response-verified-icon-wrapper {
            padding: 6px;
            border: 1px solid #C3C3CB;
            border-radius: 3px;
            vertical-align: middle;
            text-align: center;
            width: 2.5725rem;
            height: 2.5725rem;
        }

        .alignContentRight {
            text-align: right;
            text-align: -webkit-right;
        }
    }

    .response-content-wrapper {
        padding: 20px;
        height: 475px;
        overflow-y: auto;
        font-family: EYInterstate;
    }

    textarea {
        width: 100% !important;
    }
}

.response-action-wrapper {
    padding: 15px 0px;
    text-align: -webkit-right;

    .iconUpload {
        margin-right: 10px;
        vertical-align: bottom;
    }

    .btnShare,
    .btnTransfer {
        font-family: EYInterstate;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display: inline-block;
    }

    .btnTransfer {
        margin-left: 10px;
    }
}

.edit-container {
    display: flex;
    gap: 8px;
    float: right;
}

textarea {
    width: 350px;
    height: 350px;
    resize: vertical;
    padding: 10px;
    font-size: 14px;
}

.tagContainer {
    width: 100px;
    height: 30px;
    padding: 2px 6px 2px 8px;
    gap: 4px;
    border-radius: 16px;
    border: 1px 0px 0px 0px;
    border: 1px solid #E6E6E9;

    .view-tags-select .motif-dropdown-menu {
        top: 116% !important;
    }
}

.closeProjectDrpDwnPublic {
    position: relative;
    left: 7px;
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #82336A;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 14px;
    background: #FAF5F8;
}

.closeProjectDrpDwnConfidential {
    position: relative;
    left: 7px;
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #724BC3;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 14px;
    background: #F8F6FA;

}

.divider-favorites {
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.favorites-divider {
    opacity: 0.3;
    margin-top: 0;


}

.datewithlink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -5px;
    margin-left: 19px;
    margin-right: 12px;
    height: 34px;
}

.date {
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    color: #656579;

}
.favdeletetoast{
    left:6.6%;
    width: 19.4%;
    bottom: 5%;
}
.modal-description{
    padding: 0px !important;
}
.btnConfirmFav{
    width: 102px !important;
    left: 79% !important;
}
.btnCancelFav{
    left:57% !important;
}
.toastcontent{
font-family:EYInterstate;;
font-size: 14;
font-weight: 400;
color:  #2E2E38;

}
.toastimg{
    position: relative;
    top:-8px
}
.tagContainerText {
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    width: 56px;
    height: 20px;
    gap: 0px;
    position: relative;
    left: -8px;
}

.notagsContainer {
    width: 130px;
    height: 30px;
    padding: 2px 6px 2px 8px;
    gap: 4px;
    border-radius: 16px;
    border: 1px 0px 0px 0px;
    border: 1px solid #E6E6E9
}
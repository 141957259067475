.tree-div {
    padding: 0px;
}

.noScopeDiv {
    height: 100%;
    display: flex;
    justify-content: center;
}

.noScopeMsgCont {
    gap: 0;
}

.no-project-scope-detail {
    color: #656579;
}
#motifModalConfirmPublishPrompt {
  .motif-modal-footer {
    padding: unset;
  }
}

.confirm-publish-ii-prompt-body-container {
  font-family: var(--font-family-primary, EYInterstate);
  font-style: normal;
  font-weight: var(--font-weight-light, 300);
  color: var(--accordion-content-empty-color, #2e2e38);
}

.confirm-publish-prompt-type-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.confirm-publish-ii-prompt-list ul {
  padding-left: 8px;
}

.confirm-publish-ii-prompt-list li {
  margin-bottom: 0px;
}

#confirm_button {
  margin-top: 24px;
}

.confirm-publish-ii-prompt-header {
  font-size: var(--font-size-16, 16.002px);
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--line-height-24, 23.996px);
  /* 149.956% */
}

.confirm-publish-ii-prompt-description {
  color: var(--accordion-content-empty-support-color, #656579);
  font-size: var(--font-size-14, 14px);
  font-weight: var(--font-weight-light, 300);
  line-height: var(--line-height-20, 20px);
  /* 142.857% */
}

.confirm-publish-ii-prompt-list {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  /* 150% */

  ul {
    list-style: disc;
    margin: unset;
  }
}
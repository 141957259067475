#projectWorkspaceTab > .motif-active {
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Body 1/Regular */
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

#projectWorkspaceTab > button {
  color: var(--Neutrals-Light-theme-700, #656579);

  /* Body 1/Regular */
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

/* .row-with-full-height {
   height: 100%; 
} */
.no-response-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 130px;
}

.no-response {
  align-self: stretch;
  text-align: center;
  color: #2e2e38;
  font-size: 18px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}

.no-response-text {
  text-align: center;
  color: #656579;
  font-size: 12px;
  font-family: EYInterstate, "Arial Unicode MS", Arial, sans-serif;
  font-weight: 300px;
  word-wrap: break-word;
  align-self: center;
}

.no-response-text-container {
  width: 100%;
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
}

.pw-section-container {
  margin-top: 12px;
}

.favorite-responses-container {
  border-radius: 8px;
  border: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
  width: 100%;
  height: 700px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.favorite-responses-header-container {
  display: flex;
  width: 100%;
  height: 24px;
  padding-bottom: 1px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
}
.favorite-responses-header {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.favorite-responses-header-text {
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Body 1/Regular */
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.favorite-filter-inner-box {
  display: inline-flex;
  height: calc(100% - 45px);
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
  width: 100%;
  padding: 16px 0px 0px 0px;
}

.favorite-filter-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  background: var(--Neutrals-Light-theme-00-White, #fff);
  padding: 0px 16px 16px 16px;

  .motif-form-field {
    margin-bottom: unset;
  }
}

.filter-by-origin-input {
  width: 100%;

  .motif-select-input {
    &:hover {
      background: none;
    }
  
    .motif-select-input-arrow {
      height: 36px !important;
      width: 36px !important;
      border-radius: 4px !important;
      top: 0.2rem !important;
      padding: 6px 6px 4px !important;
      right: 0.25rem !important;
      &:hover {
        background-color: #F3F3F5;
      }
    }
  } 
}

.pw-favorites-container {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.pw-favorite-list-container {
  display: flex;
  padding: 16px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  width: 100%;
}

.pw-favorite-box {
  height: 140px;
  display: flex;
  width: 242px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  border: 1px solid #d7d7dc;
  background:  #fff ;
  width: 100%;
}
.pw-favorite-upper-box-active{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  cursor: pointer;
  border: 1px solid #D7D7DC;
background: #F3F3F5;
}

.pw-favorite-upper-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  cursor: pointer;
  &.active {
    background-color: #f3f3f5;
  }
  &:hover {
    background-color: #f3f3f5;
  }
}

.pw-favorite-upper-box-title {
  display: flex;
  padding-bottom: 4px !important;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  padding: 16px;
  color:  #2e2e38;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; 
  justify-content: space-between;
}

.pw-favorite-action-box {
  display: flex;
  padding: 10px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.title-content{
font-family: EYInterstate;
font-size: 14px;
font-weight: 300;
line-height: 20px;
text-align: left;
color:  #2E2E38;
}

.favorite-title-content{
  margin-left: 22px;
  height: 38%;
}
.pw-favorite-action-group {
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  justify-content: space-between;
}

.blank-div-favorite-class {
  width: 33%;
}

.favorite-filter-search-box {
  border-bottom: 1px solid  #d7d7dc;
  background:  #fff;
}

#pwSearch .motif-input {
  display: flex;
  height: 44px;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid  #c3c3cb;
  background:  #fff;
  width: 100%;
}

#pwSearch .motif-form-field-text-input {
  width: 100%;
}

.searchFavorite {
  width: 20px;
  height: 20px;
  z-index: 1;
  position: relative;
  top: 13px;
  left: 12px;
}
.searchTxtBoxFavorite input {
  padding-left: 40px !important;
  position: relative;
  margin-top: -25px;

  &:hover {
    background-color: #F3F3F5 !important;
  }
}

.refresh-icon {
  width: 15px;
}

.icon-box {
  width: 12%;
}

.view-tags-box {
  width: 50%;
}

.view-tags-box .motif-select-input {
  border-radius: 40px;
  border: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
  background: var(--dev-only-transparent, rgba(0, 0, 0, 0));
}

.view-tags-box .motif-select-open {
  background-color: #e6e6e9;
  border-radius: 40px;
  border: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
}

.toast-message-header {
  text-align: left;
  font-weight: bold;
}

.toast-message-body {
  text-align: left;
}

.pw-filter-lables {
  color: var(--Neutrals-Light-theme-700, #656579);

  /* Body 2/Regular */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.favimage{
  display: flex;
  padding-right: 4px;
}
#favorite-box .motif-dropdown-item
{
  padding: 4px !important;
}



#pwFilterByOrigin-listbox > .motif-option-current:after {
  content: url('../../../assets/image/check_PW.svg');
}
#pwFilterByOrigin-listbox > .motif-option-current {
  display: flex;
  justify-content: space-between;
}

#pwFilterbyaction-listbox > .motif-option-current:after {
  content: url('../../../assets/image/check_PW.svg');
}
#pwFilterbyaction-listbox > .motif-option-current {
  display: flex;
  justify-content: space-between;
}

.motif-vertical-navigation-menuitem-portal {
  z-index: 100;
  padding-left: 2.5%;
} 

.DashProjProjectLandingPage {
    width: 1440px;
    height: 960px;
    position: relative;
    background: white;
    margin-left: 10px;
}

.DashProjPageHeader {
    height: 126px;
    /* padding-top: 24px; */
    padding-bottom: 24px;
    left: 10px;
    /* top: 56px; */
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex
}

.DashProjPageHeader2 {
    height: 126px;
    /* padding-top: 24px; */
    padding-bottom: 24px;
    left: 10px;
    /* top: 56px; */
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex
}

.DashProjText {
    color: #2E2E38;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word
}

.DashProjText1 {
    color: #9897A6;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word
}

.DashProjText2 {
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex
}

.DashProjText3 {
    color: #2E2E38;
    font-size: 28px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 36px;
    word-wrap: break-word
}

.DashProjText4 {
    color: white;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}

.DashProjText5 {
    color: #2E2E38;
    font-size: 24px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 32px;
    word-wrap: break-word
}

.DashProjText6 {
    flex: 1 1 0;
    color: #9897A6;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}

.DashProjVector {
    width: 5px;
    height: 9px;
    left: 5.50px;
    top: 3.50px;
    position: absolute;
    background: #9897A6
}

.DashProjVector1 {
    width: 7.82px;
    height: 3.58px;
    transform: rotate(-45deg);
    transform-origin: 0 0;
    background: #9897A6
}

.DashProjVector2 {
    width: 14.33px;
    height: 14.33px;
    background: #656579
}

.DashProjContent {
    width: 1000px;
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: inline-flex
}

.DashProjTitle {
    flex: 1 1 0;
    height: 36px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: flex
}

.DashProjTitle1 {
    width: 100%;
    height: 36px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: inline-flex
}

.DashProjTextAndSupportingText {
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    display: inline-flex
}

.DashProjActions {
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
}

.DashProjButton {
    border-radius: 0px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.DashProjButtonBase {
    padding-left: 2px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex
}

.DashProjFrame1 {
    height: 620px;
    width: 100%;
    top: 120px;
    border-radius: 4px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    display: inline-flex
}

.DashProjFrame {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: inline-flex
}

.DashProjSelect {
    width: 25%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: inline-flex
}

.DashProjInputDropdownBase {
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.DashProjInputDropdownBase1 {
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.DashProjInputWithLabel {
    align-self: stretch;
    height: 50px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.DashProjInputWithLabel1 {
    align-self: stretch;
    height: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.DashProjLabel {
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}

.DashProjLabel1 {
    align-self: stretch;
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 24px;
    word-wrap: break-word
}

.DashProjFrame5383 {
    align-self: stretch;
    justify-content: space-between;
    align-items: flex-start;
    display: inline-flex
}

.DashProjFrame5357 {
    height: 20px;
    justify-content: space-between;
    align-items: center;
    display: flex
}

.DashProjCheckbox {
    height: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.DashProjCheckbox1 {
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    display: flex
}

.DashProjInput {
    padding-top: 2px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex
}

.DashProjInput1 {
    align-self: stretch;
    flex: 1 1 0;
    background: white;
    border-radius: 4px;
    border: 1px solid;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.DashProjCheckboxBase {
    width: 16px;
    height: 16px;
    padding: 2px;
    background: #F3F3F5;
    border-radius: 1px;
    overflow: hidden;
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-content: center;
    align-items: center;
    display: flex
}

.DashProjCheck {
    width: 12px;
    height: 12px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.DashProjIconWithTooltip {
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    display: flex;   
}

.DashProjHelpCircle {
    width: 16px;
    height: 16px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.DashProjHintText {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex
}

.DashProjThisIsAHintTextToHelpUsers {
    color: #656579;
    font-size: 12px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 18px;
    word-wrap: break-word
}

.DashProjFrame3Member {
    width: 1150px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: inline-flex
}


.DashProjTextArea {
    width: 75%;
    height: 118px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex
}

.DashProjTextarea1 {
    align-self: stretch;
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: flex
}

.DashProjLabelGroup {
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    display: inline-flex
}

.DashProjLabelOutside {
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}

.DashProjInputText {
    align-self: stretch;
    flex: 1 1 0;
    color: #2E2E38;
    font-size: 14px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 300;
    line-height: 20px;
    word-wrap: break-word
}

.DashProjVerticalNavigation {
    width: 64px;
    height: 904px;
    left: 0px;
    top: 56px;
    position: absolute;
    background: white;
    border-right: 1px #E6E6E9 solid;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex
}

.DashProjNav {
    align-self: stretch;
    height: 269px;
    padding-top: 24px;
    padding-bottom: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: flex
}

.DashProjNavigation {
    align-self: stretch;
    height: 221px;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    display: flex
}

.DashProjChatLines {
    width: 20px;
    height: 20px;
    position: relative
}

.DashProjPage {
    width: 20px;
    height: 20px;
    position: relative
}

.DashProjGroup {
    width: 20px;
    height: 20px;
    position: relative
}

.DashProjOpenBook {
    width: 20px;
    height: 20px;
    position: relative
}

.DashProjContentDivider {
    width: 40px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex
}

.DashProjDivider {
    flex: 1 1 0;
    height: 1px;
    background: #D7D7DC
}

.DashProjBookmarkBook {
    width: 20px;
    height: 20px;
    position: relative
}

.DashProjHeaderNav {
    width: 1440px;
    height: 56px;
    padding-left: 10px;
    padding-right: 16px;
    left: 0px;
    top: 0px;
    position: absolute;
    background: white;
    border-bottom: 1px #E6E6E9 solid;
    justify-content: space-between;
    align-items: flex-start;
    display: inline-flex
}

.DashProjLogoAndMenu {
    width: 212px;
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex
}

.DashProjMenu {
    width: 20px;
    height: 20px;
    position: relative
}

.DashProjLogoEngGap {
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: flex
}

.DashProjLogoProduct {
    width: 160px;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: flex
}

.DashProjLogo {
    border-radius: 4px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex
}

.DashProjContainerLogo {
    width: 40px;
    height: 40px;
    padding-top: 4.02px;
    padding-bottom: 5.30px;
    padding-left: 5px;
    padding-right: 5px;
    background: #2E2E38;
    border-radius: 2.50px;
    justify-content: center;
    align-items: center;
    display: inline-flex
}

.DashProjScaledLogo {
    width: 30px;
    height: 30.68px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.DashProjProductName {
    color: #2E2E38;
    font-size: 16px;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word
}

.DashProjSearchGroup {
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.DashProjIconGroup {
    justify-content: flex-end;
    align-items: flex-start;
    display: flex
}

.DashProjButtonsGroup {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex
}

.DashProjButtonGroupBase {
    padding: 12px;
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
    overflow: hidden;
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-content: center;
    align-items: center;
    display: flex
}

.DashProjBell {
    width: 20px;
    height: 20px;
    position: relative
}

.DashProjNavDropdown {
    padding: 2px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: flex
}

.DashProjDropdown {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex
}

.DashProjAvatar {
    border-radius: 200px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex
}

.DashProjAvatarImage {
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 48px
}
.DashProjContentMember {
    width: 1050px;
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: inline-flex
}
.DashProjButtonBaseMember {
    padding-left: 2px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px rgba(0, 0, 0, 0) solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex
}
.ProjectDetailLine {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    align-self: stretch;
    background: var(--Neutrals-Light-theme-100, #E6E6E9);
}
.ProjectDetailWrap {
    width: 85%;
    word-wrap: break-word;
}
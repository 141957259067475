.viewSrcDocsModal {
    /*width: 100%;
    max-width: 100%;*/
    max-width: 92vw;
    min-width: 92vw;
    /*height: 100%;
    max-height: 100%;*/
    max-height: 42vw;
    min-height: 42vw;
    --modal--bg-color: #F3F3F5;
    --modal-header--bg-color: #F3F3F5;
    --modal-header--border-color: #F3F3F5;
    --modal-header-close-btn--bg-color: #F3F3F5;
    padding: 24px;
    .motif-modal-headline {
        padding: 10px 0px;
        font-family: EYInterstate;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
    }
    .motif-modal--header-icon-button {
        width: 3.43rem;
        height: 3.43rem;
        align-self: center;
    }
    
    .motif-modal-body  {
        padding: 8px 0px 0px 0px; 
        display: inline-block;
        height: 500px;
        min-width:64vw;
        max-width: 64vw; 
         /*  24px reduced to fit doc viewer */   
    }

    .motif-modal-body-content {
        padding: 8px 0px 0px 0px; 
        display: inline-block;
        height: 500px;
        min-width:64vw;
        max-width: 64vw;
    } 

    .motif-modal-body-sidebar{
        position:absolute;
        margin-left: 65vw;
        margin-right: 10vw;
        height: 30vw;
        margin-top: 67px;
        min-width:24vw;
        max-width: 24vw;
    } 

    .motif-accordion-title-content {
        font-family: EYInterstate;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    .accordionTitle {
        display: -webkit-inline-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
    }
    .rmMargin {
        margin: 0px;
    }
    .rmLeftPadding {
        padding-left: 0px;
    }
}


.vwSrcDocsContainer, .srcDocsContainer {
    padding: 10px;
    background-color: #fff;
}
.pdfViewerContainer {
    background-color: #FFF;
    font-family: EYInterstate;
    font-weight: 400;
    line-height: 24px;
    padding: 10px;
    overflow-x: auto;
}
.paginatorContianer {
    width: 25%;
    float: right;
    margin-right: 10px;
}
.paginatorWrapper {
  display: flex;
  .motif-select-wrapper-options {
    top: auto !important;
    bottom: 0;
  }
}
.pageSelect {
  flex: 2;
}
.srcDocsContent {
  padding: 0px !important;
}
.referenceLink {
  padding: 10px 20px;
  width: 100%;
  text-align: start;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}
.srcDocsGroup {
  /* margin-top: 1px; */
  margin-bottom: 1px;
}
.btnDownload,
.iconNoLink,
.iconTick {
  vertical-align: middle;
  float: right;
}
.titleFileName {
  font-family: EYInterstate;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.iconPrevious,
.txtPageNo,
.iconNext {
  padding: 5px;
}
.disabledAccord {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #fff;
  margin-top: 1px;
  margin-bottom: 1px;
  cursor: no-drop;
}

.srcDocsWrapper {
  width: 100%;
}

.source-wrapper {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  border: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
}


.brokenLinkAccord {
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #9897a6;
  padding: 10px 20px;
  background-color: #fff;
  margin-top: 1px;
  margin-bottom: 1px;
  text-decoration: line-through;
  .motif-tooltip-wrapper {
    float: right;
  }
}
.divider {
  margin: 0px;
  border-bottom: 1px solid #f3f3f5;
}
.txtPageNo {
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 24px;
  align-content: center;
}

.source-document-modal {
  display: inline-flex;
  height: 120%;
  min-width: 90%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;

  border-radius: 8px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  background: var(--Neutrals-Light-theme-50, #f3f3f5);

  /* Shadows/Light theme/Elevation 05 */
  box-shadow: 0px 4px 8px 0px rgba(35, 35, 47, 0.06),
    0px 16px 32px 0px rgba(35, 35, 47, 0.08);

  .modal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
    background: var(--dev-only-transparent, rgba(0, 0, 0, 0));
  }

  .motif-modal-header .motif-modal-headline {
    padding: unset;
  }

  .motif-modal-header .motif-modal--header-icon-button {
    background-color: unset;

    &:hover {
      background-color: var(--Neutrals-Light-theme-100, #e6e6e9);
    }
  }



  .source-document-body {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    height: 100%;

    .source-document-container {
      display: flex;
      width: 67%;
      height: 100%;
      align-items: flex-start;
      border-radius: 8px;
      border: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
      background: #fff;
      flex-direction: column;
    }

    .source-document-title {
      display: flex;
      width: 100%;
      padding: 11px 16px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
      background: var(--dev-only-transparent, rgba(0, 0, 0, 0));

      /* flex-direction: column;
      justify-content: center; */
      flex: 1 0 0;
      align-self: stretch;

      color: var(--Neutrals-Light-theme-900, #2e2e38);

      /* Body 1/Regular */
      font-family: EYInterstate;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .source-document-body {
      display: flex;
      /* height: 631px; */
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex-shrink: 0;
      align-self: stretch;
    }

    .source-document-content {
      width: 100%;
      overflow: auto;
      background-color: #fff;
      font-family: EYInterstate;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }

    .source-document-footer {
      display: flex;
      width: 100%;
      height: 50px;
      align-items: center;
      flex-direction: row-reverse;
      border-top: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
      background: var(--Neutrals-Light-theme-00-White, #fff);
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;

      .paginatorWrapper {
        display: flex;
        .motif-select-wrapper-options {
          top: auto !important;
          bottom: 0;
        }
      }
    }

    .source-document-reference-container {
      display: flex;
      width: 33%;
      height: 100%;
      padding: 1px 0px 16px 1px;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;

      border-radius: 8px;
      border: 1px solid #cfcfcf;
      background: #fff;

      .reference-container-layout {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 0px 12px;

        .reference-header-container {
          display: flex;
          padding: 9px 4px 11px 4px;
          gap: 8px;
          display: flex;
          width: 100%;
          align-items: center;
          background: var(--dev-only-transparent, rgba(0, 0, 0, 0));

          color: var(--Neutrals-Light-theme-700, #656579);

          /* Body 1/Regular */
          font-family: EYInterstate;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .accordionTitle {
        display: -webkit-inline-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
      }
    }
  }
}

#refAccordion0 {
  border-radius: 8px 8px 0px 0px;
  padding: 1px 0px;
}

#sourceDocumentModal > .motif-modal-body {
  padding: unset;
  width: 100%;
  overflow: unset;
  overflow-y: unset;
}

.pageInput {
  width: 50px !important;
}

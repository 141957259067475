#viewPromptModal .motif-modal-headline {
    width: 100%;
}

.prompt-modal-footer {
    display: block;
}

.prompt-modal-confirm-button {
    float: right;
    margin-left: 0.7rem;
}

.prompt-modal-cancel-button {
    float: right;
    margin-top: 0 !important;
}

.prompt-modal-textarea {
    padding: 0.5rem !important;
}

.prompt-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.prompt-card-header {
    display: block;
    padding-bottom: 0;
}

.prompt-project-detail-div {
    font-size: 12px;
    font-weight: 700;
    display: flex;
}

.prompt-project-name {
    margin-right: 0.5rem;
}

.prompt-create-date {
    font-size: 12px;
    font-weight: 300;
    margin-left: auto;
}

.prompt-project-status-open .motif-chip-button {
    font-size: 12px;
    color: #125E29;
    --chip--bg-color: #73FA90;
}

.prompt-project-status-closed {
    font-size: 12px;
    color: #A40011;
    --chip--bg-color: #FCD7CF;
}

.prompt-library {
    align-items: center;
    padding: 0.5rem;
    margin-top: 0.8rem;
    padding-bottom: 0;
}

.prompt-library-badge {
    margin-left: auto;
    float: right;
}

.prompt-row {
    display: flex;
    width: 100%;
}

.prompt-box {
    width: 33%;
    margin: 0.5rem;
}

.prompt-view-modal-use-prompt {
    margin: inherit;
    margin-left: auto;
}

.prompt-card-body {
    height: 90px;
    margin: unset !important;
}

.prompt-card>.motif-card-body {
    padding-top: unset !important;
    padding-bottom: unset !important;
}

#promptText {

    p,
    ol,
    ul,
    li {
        margin: unset;
        color: var(--card-body--color);
    }
}

.prompt-card-text {
    max-width: inherit;
    --max-lines: 4;
    word-break: break-word;
    height: 100%;
}

.prompt-card-footer {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 1rem !important;
    margin-bottom: 0.7rem !important;
    align-items: center;
}

.prompt-card-use-prompt-button {
    margin: inherit !important;
}

.prompt-card-delete-button {
    float: right;
}

.prompt-card-edit-button {
    float: right;
    margin-right: 0.5rem;
}

.prompt-delete-modal-body-text {
    padding-top: 24px;
}

.prompt-search-icon {
    width: 20px;
    height: 20px;
    z-index: 99;
    position: absolute;
    left: 14px;
    top: 13px;
}

.prompt-search-textbox input {
    padding-left: 40px !important;
}

.prompt-search-textbox input::placeholder {
    color: #656579;
    font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
}

.display-linebreak {
    white-space: pre-line;
}

#editPromptModal {
    min-height: 88vh;
    width: 55vw;

    .ql-editor {
        height: 27vh;
        font-size: 1rem;
    }

    @media screen and (min-height: 1000px) {
        .ql-editor {
            height: 50vh;
            font-size: 1rem;
        }

        .motif-rich-text-editor .ql-editor {
            max-height: 100% !important;
        }
    }
}

.edit-prompt-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.edit-prompt-modal-label {
    color: var(--Neutrals-Light-theme-700, #656579);

    /* Body 2/Regular */
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.edit-prompt-disclaimer {
    display: flex;
    align-items: center;
    gap: 8px;

    .edit-prompt-disclaimer-icon {
        height: 20px;
        width: 20px;
    }

    .edit-prompt-disclaimer-message {
        color: var(--inline-info-color, #2E2E38);
        /* Body 2/Light */
        font-family: EYInterstate;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        /* 142.857% */
    }

    .header-title {
        color: var(--header-title-color, #2e2e38);

        /* Subtitle/Regular */
        font-family: EYInterstate;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        /* 155.556% */
    }

    .prompt-title {
        color: var(--Neutrals-Light-theme-700, #656579);
        /* Body 2/Regular */
        font-family: EYInterstate;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 5px;
        /* 142.857% */
    }

    .input-title {
        color: var(--Neutrals-Light-theme-700, #656579);
        font-family: EYInterstate;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
    }

    .error_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* margin-top: 5px; */
    }


}

.projectdashboardnavdiv .prompt-box .motif-card-footer {
    min-height: 90px;
}

.projectdashboardnavdiv .prompt-box .prompt-card-footer {
    align-items: flex-end;
}
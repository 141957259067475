.heading {
  font-family: EYInterstate;
}

.message-settings-container {
  display: flex;
  flex-direction: column;
  height: 150vh;
}

.button-container {
  display: flex;
  justify-content: space-between;
  display: flex;
  color: var(--btn-primary-color, #FFF);

  /* Button/md */
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.button-group {
  display: flex;
  justify-content: center;
  margin: 10px;
  gap: 8px;
}

.form-container {
  max-width: 640px;
}

.select {
  width: 320px;
  /* margin-bottom: 24px; */
}

.input {
  width: 640px;
  margin-bottom: 24px !important;
}

.InputWithLabel {
  align-self: stretch;
  height: 70px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.Label-input {
  color: var(--Neutrals-Light-theme-700, #656579) !important;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.Label-big {
  color: #2E2E38;
  font-size: 18px;
  font-weight: 400;
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif;
  line-height: 28px;
}

.button {
  font-family: EYInterstate, 'Arial Unicode MS', Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px;
}

.divider {

  height: 1px;
  background: #E6E6E9;
  margin-bottom: 20px;
}

.timewrapper {
  margin-bottom: 1px;
}

.text-editor {
  margin-top: 24px;
}

.timegroup {
  display: flex;
  margin-top: 24px;
  margin-bottom: 30px;
  gap: 24px;
}

.errMsg {
  font-family: EYInterstate;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
  color: #C70117;
}

.error-box {
  width: 100%;
}

.motif-error-icon {
  display: none;
}

.date-picker .react-date-picker__inputGroup {
  padding: 10px !important;
}

.motif-select-notification {
  margin-bottom: 20px;
}

.Date-input .react-date-picker__wrapper .react-date-picker__inputGroup {
  padding: 10px;
}

.Time-box.motif-time-wrapper .motif-small-input-item {
  border-radius: 4px;
  border: 1px solid var(--Neutrals-Light-theme-300, #C3C3CB);
  background: var(--Neutrals-Light-theme-00-White, #FFF);
}
#text {
  text-align: justify;
}

.deletedoc {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.buttondesign {
  margin-top: 10px !important;
}

.deletemesg {
  font-family: EYInterstate;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.deleteContent {
  font-family: EYInterstate;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.deleteButtonBackground{
  background-color: #EA011D;
}
#motifModalAcknowledgeRefresh {
    font-family: EYInterstate;
}
.msgAcknowledge {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
}
.acknowledgeTitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}
.icon-refresh {
    height: 25px;
}
.icon-info {
    max-width: 18px;
    margin-bottom: -3px;
}

.info-div {
    display: flex;
    gap: 12px;
}

.tree-div {
    margin-top: 12px;
    padding-bottom: 24px;
}

.btns-div {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.refresh-info-message{
    display: flex;
    gap: 12px;
    margin-top: 0.5rem;
}
#secrionAlertmodal {
  padding: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.share-alert-btns-div {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.shareAlertContentContainer {
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  border-top: 1px solid var(--modal-header--border-color, rgba(0, 0, 0, 0));
}

.shareAlertContent {

  color: var(--Neutrals-Light-theme-700, #656579);

  /* Body 2/Regular */
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

#secrionAlertmodal > .motif-modal-header{
  border-style:unset;
}

#secrionAlertmodal > .motif-modal-body, 
#secrionAlertmodal > .motif-modal-footer,
#secrionAlertmodal > .motif-modal-header .motif-modal-headline {
  padding: unset;
}

.share-alert-header-title {
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Subtitle/Regular */
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.share-alert-go-to-tw-button {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  /* background: var(--Neutrals-Light-theme-900, #2e2e38); */

  color: var(--Neutrals-Light-theme-00-White, #fff);
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.share-alert-cancel-button {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--Neutrals-Light-theme-300, #c3c3cb);
  background: var(--dev-only-transparent, rgba(0, 0, 0, 0));
  color: var(--Neutrals-Light-theme-900, #2e2e38);

  /* Button/lg,xl,2xl */
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}
